import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { CreateLearningPathLevelCommand, LearningPathService, LearningPathViewModel } from 'api-gen';
import React from 'react';
import TreeLearningPathLevelsSelect from './TreeLearningPathLevelsSelect';
import { useNotification } from '../../../services/NotificationContext';
import {AddLearningPathLevel}  from "../Commom/FindLearningPathLevel";

interface CreateLearningPathLevelProps {
    open: boolean;
    onClose: () => void;
    learningPath: LearningPathViewModel;
    allLearningPaths: LearningPathViewModel[];
    setLearningPath: (learningPath: LearningPathViewModel[]) => void;
}

const CreateLearningPathLevelModal: React.FC<CreateLearningPathLevelProps> = ({ open, onClose, learningPath, allLearningPaths, setLearningPath }: CreateLearningPathLevelProps) => {
    const { addNotification } = useNotification();
    const [learningPathName, setLearningPathName] = React.useState<string>("");
    const [isSequential, setIsSequential] = React.useState<boolean>(false);
    const [parentId, setParentId] = React.useState<number>(undefined);

    const handleChangeIsSequential = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            setParentId(undefined);
        }
        setIsSequential(event.target.checked);
    }

    const handleSaveLearningPathLevel = async () => {
        try {
            const body: CreateLearningPathLevelCommand = {
                learningPathId: learningPath.id,
                name: learningPathName,
                companyId: learningPath.companyId,
                parentId: parentId

            };

            const result = await LearningPathService.postApiLearningPathAddLearningPathLevel(learningPath?.companyId, body);

            let allLearningPathsUpdated = allLearningPaths;

            const updatedLearningPath = allLearningPaths.find(lp => lp.id === learningPath.id);

            AddLearningPathLevel(updatedLearningPath, result.data, parentId);

            const index = allLearningPathsUpdated.findIndex(lp => lp.id === learningPath.id);
            allLearningPathsUpdated[index] = updatedLearningPath;

            setLearningPath(allLearningPathsUpdated);
            onClose();
        } catch (error) {
            addNotification("Erro ao adicionar nível na trilha de conhecimento", "red");
        };
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Adicionar nível na trilha de conhecimento</DialogTitle>
            <DialogContent
            >
                <FormControl sx={{ marginTop: '1rem' }} fullWidth>
                    <TextField
                        label="Nome"
                        type="text"
                        placeholder="Digite um nome para o nível da trilha de conhecimento"
                        value={learningPathName}
                        onChange={(e) => setLearningPathName(e.target.value)}
                    />
                </FormControl>

                <FormGroup sx={{ marginTop: '1rem' }}>
                    <FormControlLabel control={<Checkbox checked={isSequential} onChange={(e) => handleChangeIsSequential(e)} />} label="Utilizar em sequência de algum nível" />
                </FormGroup>
                {
                    isSequential && (
                        <TreeLearningPathLevelsSelect treeData={learningPath.learningPathLevels} idSelected={parentId} setIdSelected={setParentId}  label='Nível pai'/>
                    )
                }


            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSaveLearningPathLevel} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateLearningPathLevelModal;