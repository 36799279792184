import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { TopicViewModel } from "api-gen";
import React from "react";

interface ConfirmRestartQuestionsLearningPathLevelModalProps {
    open: boolean;
    handleClose: () => void;
    topicsNotMasterized: TopicViewModel[];

    handleConfirm: (topicsIds : number[]) => void;

    isLoading: boolean;

};
const ConfirmRestartQuestionsLearningPathLevelModal = (props: ConfirmRestartQuestionsLearningPathLevelModalProps) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogContent sx={{ padding: '2rem' }}>
                <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
                    Revisão de Tópicos Necessária
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                    O seu desempenho nos seguintes tópicos não foi suficiente para avançar para o próximo nível:
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.1rem', marginBottom: '1rem', fontStyle: 'italic' }}>
                    {props.topicsNotMasterized.map(topic => topic.description).join(", ")}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
                    Deseja revisar esses tópicos respondendo às questões novamente?
                </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '1rem' }}>
                <Button onClick={props.handleClose}  sx={{ marginRight: '1rem' }}>
                    Cancelar
                </Button>
                <LoadingButton 
                    onClick={() => props.handleConfirm(props.topicsNotMasterized?.map(topic => topic.id))}
                    loading={props.isLoading}
                >
                    Sim
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmRestartQuestionsLearningPathLevelModal;