import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useNotification } from '../../../services/NotificationContext';
import useAuth from '../../../hooks/useAuth.js';
import { CompaniesService, CreateTopicCommand, TopicsService, UpdateTopicCommand } from 'api-gen';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useDesignParams } from 'contexts/DesignParamsContext';

Modal.setAppElement('#root');

const AddTopicModal = ({ isOpen, onClose, id }) => {
  const designParams = useDesignParams();
  const { addNotification } = useNotification();
  const { user } = useAuth();

  const [description, setDescription] = useState("");
  const [subjectId, setSubjectd] = useState(null);
  const [error, setError] = useState("");
  const [subjectsList, setSubjectsList] = useState([]);
  const [recurrencePercentage, setRecurrencePercentage] = useState<number>(undefined);


  const preencherDados = (dados) => { 
    setDescription(dados.topic.description);
    setSubjectd(dados.topic.subject.id);
    setRecurrencePercentage(dados.topic.recurrencePercentage);
  }

  const handleCloseModal = () => {
    setDescription("");
    setSubjectd(null);
    setError(""); 
    setRecurrencePercentage(undefined);

    onClose();
  }



  const getSubjects = async () => {
    if (user?.companyId) {
      const result = await CompaniesService.getApiCompaniesSubjects(user?.companyId);

      if (result?.subjects?.length > 0) {
        setSubjectsList(result.subjects); 
      }
    }
  }

  const getTopics = async () => {
    const result = await TopicsService.getApiTopics(id, user?.companyId);
    if (result) {
      preencherDados(result);
      getSubjects();
    }
  }
 

  useEffect(() => {
    getSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (id) {
      getTopics();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const optionsSubjectsList = useMemo(() => {
    if (subjectsList) {
      return subjectsList.map(item => ({
        value: item.id.toString(),
        label: item.description
      }));
    }
    return [];

  }, [subjectsList]);


  const handleAddSubject = async () => {

    setError("");

    const body: UpdateTopicCommand = {
      id,
      description,
      recurrencePercentage,
      subjectId
    }
    if (id) {
      try {
        await TopicsService.putApiTopics(id,user?.companyId, body); 
        
        addNotification('Conteúdo alterado com sucesso!', 'green');
        handleCloseModal();
      } catch (e) {
        addNotification('Erro ao alterar Conteúdo!', 'red');
      }
     
    } else {
      const body: CreateTopicCommand = {
        companyId: user.companyId,
        description,
        subjectId,
        recurrencePercentage
      }

      try {
        await TopicsService.postApiTopics(user?.companyId,body); 

        addNotification('Conteúdo inserido com sucesso!', 'green');
        handleCloseModal();
      } catch (e) { 
        addNotification('Erro ao inserir Conteúdo!', 'red');
      }
    }
  }

  const handleChangeRecurrencePercentage = (value) => {  
    value = value.replace(',', '.');
    
    if (/^-?\d*\.?\d*$/.test(value) || value === '') {
      value = value > 100 ? 100 : value;
      setRecurrencePercentage(value as number);
    }
   
  }
 
  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>{id ? "Editar Conteúdo" : "Adicionar Conteúdo"}</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <FormControl>
          <Typography>Descrição</Typography>
          <TextField
            id="description"
            variant="standard"
            value={description}
            sx={{ marginTop: '0.25rem' }}
            onChange={(e) => [setDescription(e.target.value), setError("")]}
          />
        </FormControl>
        <FormControl variant="standard">
          <Typography>Disciplina</Typography>
          <Select
            id="subject-select"
            value={subjectId}
            onChange={(event) => setSubjectd(event.target.value)}
            sx={{ marginTop: '0.25rem' }}
          >
            <MenuItem value="">
              <em>-</em>
            </MenuItem>
            {
              optionsSubjectsList.map((subject: any) => {
                return <MenuItem value={subject.value}>{subject.label}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <FormControl>
          <Typography>Recorrência</Typography>
          <TextField
            id="description"
            variant="standard"
            type="text"
            value={recurrencePercentage}
            InputProps={{
              endAdornment: '%'
            }}
            inputProps={{
              max: 100,
              min: 0,
              step: 0.1
            }}
            sx={{ marginTop: '0.25rem' }}
            onChange={(e) => [handleChangeRecurrencePercentage(e.target.value), setError("")]}
          />
        </FormControl>

        {
          error &&
          <label color='red'>{error}</label>
        }
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', margin: '1rem' }}>
        <Button
          variant='contained'
          sx={{ backgroundColor: designParams?.secondaryColorHex || '#ea0000' }}
          color='error'
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          variant='contained'
          sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
          onClick={handleAddSubject}
        >
          {id ? "Alterar" : "Adicionar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddTopicModal;