/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateExamBoardCommand } from '../models/ActivateExamBoardCommand';
import type { CreateExamBoardCommand } from '../models/CreateExamBoardCommand';
import type { DeactivateExamBoardCommand } from '../models/DeactivateExamBoardCommand';
import type { UpdateExamBoardCommand } from '../models/UpdateExamBoardCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExamBoardsService {
    /**
     * Obtém uma Aplicadora pelo Id.
     * @param id Id da Aplicadora.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiExamBoards(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/examBoards/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Altera as informações de uma Aplicadora.
     * Exemplo:
     * { "name": "INEP" }
     * @param id Id da Aplicadora
     * @param tenantId Id da Empresa
     * @param requestBody Command que contém as informações para atualizar a Aplicadora.
     * @returns void
     * @throws ApiError
     */
    public static putApiExamBoards(
        id: number,
        tenantId: number,
        requestBody?: UpdateExamBoardCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/examBoards/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cria uma nova Aplicadora.
     * Exemplo:
     * { "name": "INEP", "companyId": 1 }
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo as informações da Aplicadora para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiExamBoards(
        tenantId: number,
        requestBody?: CreateExamBoardCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/examBoards',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ativa uma Aplicadora a partir de seu Id.
     * @param id Id da Aplicadora a ser ativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para ativar a Aplicadora.
     * @returns void
     * @throws ApiError
     */
    public static putApiExamBoardsActivate(
        id: number,
        tenantId: number,
        requestBody?: ActivateExamBoardCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/examBoards/{id}/activate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa uma Aplicadora a partir de seu Id.
     * @param id Id da Aplicadora a ser desativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para desativar a Aplicadora.
     * @returns void
     * @throws ApiError
     */
    public static putApiExamBoardsDeactivate(
        id: number,
        tenantId: number,
        requestBody?: DeactivateExamBoardCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/examBoards/{id}/deactivate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
