import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { Label } from '../../Signin/styles.js';
import Input from '../../../components/Input/index.js';
import { FaTimes } from 'react-icons/fa';
import { Container, Content } from './AddModalStyles.js'; 
import SelectList from '../../../components/SelectList/index.js';
import { useNotification } from '../../../services/NotificationContext';
import useAuth from '../../../hooks/useAuth.js';
import {  CompaniesService, SubjectsService } from 'api-gen';
import { useDesignParams } from 'contexts/DesignParamsContext';
import { Button } from '@mui/material';

Modal.setAppElement('#root');

const AddSubjectModal = ({ isOpen, onClose, id }) => {
  const designParams = useDesignParams();
  const { addNotification } = useNotification();
  const { user } = useAuth();

  const [description, setDescription] = useState("");
  const [knowledgeAreaId, setKnowledgeAreaId] = useState(null);
  const [error, setError] = useState("");
  const [areasList, setAresList] = useState([]);


  const preencherDados = (dados) => {
    setDescription(dados.subject.description);
    setKnowledgeAreaId(dados.subject.knowledgeArea.id);
  }



  const getKnowledgeAreas = async () => {
    if(user?.companyId){
      const result = await CompaniesService.getApiCompaniesKnowledgeAreas(user?.companyId);
      if (result && result?.knowledgeAreas?.length > 0) {
        setAresList(result?.knowledgeAreas);
      }
    }
  }

  const getSubjects = async () => {
    if (user?.companyId) {
      const result = await SubjectsService.getApiSubjects(id, user?.companyId);
      if (result) {
        preencherDados(result);
        getKnowledgeAreas();
      }
    }
  }

  const handleCloseModal = () => {
    setDescription('');
    setError('');
    setKnowledgeAreaId(null);

    onClose();
  }

  useEffect(() => {
    getKnowledgeAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (id) {
      getSubjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const optionsAreasList = useMemo(() => {
    return areasList?.map(item => ({
      value: item.id.toString(),
      label: item.description
    }));
  }, [areasList]);


  const handleAddSubject = async () => {

    setError("");

    const body = {
      id,
      description,
      knowledgeAreaId
    }
    if (id) {
      try {
        await SubjectsService.putApiSubjects(id, user?.companyId, body);

        addNotification('Disciplina alterada com sucesso!', 'green');
        handleCloseModal();
      } catch (e) {
        addNotification('Erro ao alterar Disciplina!', 'red');
      }

    } else {

      const body = {
        companyId: user.companyId,
        description,
        knowledgeAreaId
      } 

      try {
        await SubjectsService.postApiSubjects(user?.companyId,body); 
          addNotification('Disciplina inserida com sucesso!', 'green');
          handleCloseModal();  
      } catch (e) {
        addNotification('Erro ao inserir Disciplina!', 'red');
      }

      
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Adicionar Disciplina"
      appElement={document.getElementById('root')}
      style={{
        content: {
          width: '50%', // Defina o tamanho desejado
          height: '35%', // Defina a altura desejada
          margin: 'auto', // Centralize horizontalmente
          background: '#fff', // Cor de fundo do modal
          border: '2px solid #ccc', // Borda do modal
          borderRadius: '8px', // Bordas arredondadas
          padding: '20px' // Espaçamento interno
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)' // Cor do fundo ao redor do modal
        }
      }}
    >
      <Container>
        <Label>{id ? "Editar Disciplina" : "Adicionar Disciplina"}</Label>
        <FaTimes onClick={handleCloseModal} style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }} />
        <Content>
          <Input
            label={"Descrição"}
            type="text"
            placeholder="Digite a descrição"
            value={description}
            onChange={(e) => [setDescription(e.target.value), setError("")]}
          />

          <SelectList options={optionsAreasList} onSelect={setKnowledgeAreaId} defaultValue={knowledgeAreaId} label={"Área do Conhecimento"} />

          {error &&
            <label color='red'>{error}</label>
          }

          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            <Button
              variant='contained'
              sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
              onClick={handleAddSubject}
            >
              {id ? "Alterar" : "Adicionar"}
            </Button>
            <Button 
             variant='contained'
             sx={{ backgroundColor: designParams?.secondaryColorHex || 'red' }}
             onClick={handleCloseModal}
            >
              Cancelar
            </Button>
          </div>

        </Content>
      </Container>
    </Modal>
  );
}

export default AddSubjectModal;