import React from "react";
import RoutesApp from "./routes";
import { AuthProvider } from "./contexts/auth";
import { NotificationProvider } from "./services/NotificationContext";
import NotificationConsumer from "./components/NotificationConsumer";
import GlobalStyle from "./styles/global";
import { DesignParamsProvider } from './contexts/DesignParamsContext';
import { BrowserRouter } from "react-router-dom";

const App = () => (
  <DesignParamsProvider>
    <BrowserRouter>
    <AuthProvider>
    <NotificationProvider>
      <div>
        <RoutesApp />
        <NotificationConsumer />
      </div>
    </NotificationProvider>
    <GlobalStyle />
  </AuthProvider>
  </BrowserRouter>
  </DesignParamsProvider>
);

export default App;
