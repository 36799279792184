import { ExamViewModel } from "customTypes/ExamViewModel";
import React, { useEffect, useState } from "react";
import { Container, Content, Label } from "./ViewExamResultModalStyles.js";
import { FaTimes } from "react-icons/fa";
import PaginationQuestionsExam from "./PaginationQuestionsExam";
import Modal from "react-modal/lib/components/Modal";
import { ExamsService } from "api-gen";
import { Alert, Box, Button, FormControlLabel, Radio } from "@mui/material";
import { Chart } from "react-google-charts";
import CircularProgress from '@mui/material/CircularProgress';
import useAuth from "../../../hooks/useAuth.js";
import { useDesignParams } from "contexts/DesignParamsContext";

type ViewExamsResultModalProps = {
    exam: ExamViewModel;
    currentQuestionIndex: number;
    onClose: () => void;
    isOpen: boolean;
};


export const ViewExamResultModal = (props: ViewExamsResultModalProps) => {
    const [exam, setExam] = useState<ExamViewModel>(props.exam);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(props.currentQuestionIndex);
    const [isReviewingQuestions, setIsReviewingQuestions] = useState<boolean>(false);
    const [examResult, setExamResult] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [currentQuestion, setCurrentQuestion] = useState<any>({});
    const designParams = useDesignParams();

    const { user } : any = useAuth(); 

    const handleCloseModal = () => {
        setExam(undefined);
        props.onClose();
    };

    const getExamResult = async () => {
        setIsLoading(true);
        if (props?.exam?.id && user?.companyId) {
            var response = await ExamsService.getApiExamsResult(props.exam.id, user?.companyId);

            if (response?.examResult) {
                setExamResult(response?.examResult);
                getQuestionAnswer(exam?.questions[0].questionId, response?.examResult);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getExamResult();
        //eslint-disable-next-line
    }, [props.exam,user]);


    const handleReviewQuestions = () => {
        setIsReviewingQuestions(true);
    }

    const getQuestionAnswer = (questionId, exam?) => {
        let question = null;
        if (exam) {
            question = exam?.questionsAnsweredCorrectly?.find(q => q.questionId === questionId);

            if (!question)
                question = exam?.questionsAnsweredIncorrectly?.find(q => q.questionId === questionId);
        }
        else {
            question = examResult?.questionsAnsweredCorrectly?.find(q => q.questionId === questionId);

            if (!question)
                question = examResult?.questionsAnsweredIncorrectly?.find(q => q.questionId === questionId);
        }

        setCurrentQuestion(question);
    }

    const handleChangeQuestion = (index) => {
        const questionId = exam?.questions[index].questionId;
        getQuestionAnswer(questionId);
        setCurrentQuestionIndex(index);
    };

    const getDataCharts = (knowledgeAreaId) => {
        const knowledgeArea: any = examResult?.resultByKnowledgeArea?.find(x => x.knowledgeAreaId === knowledgeAreaId);
        const data = [
            ["Language", "Speakers (in millions)"],
            ["Acertos", knowledgeArea?.totalCorrectAnswers ?? 0],
            ["Erros", knowledgeArea?.totalIncorrectAnswers ?? 0],
        ];
        return data;
    };

    const getDataOptions = (knowledgeAreaId) => {
        const knowledgeArea: any = examResult?.resultByKnowledgeArea?.find(x => x.knowledgeAreaId === knowledgeAreaId);
        const options = {
            legend: "none",
            pieSliceText: "label",
            title: "Área de conhecimento " + knowledgeArea?.knowledgeArea ?? "",
            pieStartAngle: 100,
            titleTextStyle: {
                fontSize: 18,
                italic: false,
                alignment: 'center'
            }
        };
        return options;
    };

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Adicionar Aplicadora"
            appElement={document.getElementById('root')}
            style={{
                content: {
                    width: '80%',
                    height: '95%',
                    margin: 'auto',
                    background: '#fff',
                    border: '2px solid #ccc',
                    borderRadius: '8px',
                    padding: '20px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }
            }}
        >
            <Container>
                <Label>Estatísticas da Prova</Label>
                <FaTimes onClick={handleCloseModal} style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }} />
                {
                    !isReviewingQuestions ?
                        isLoading ?
                            <div style={{width: "100%", height:"100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <CircularProgress />
                            </div>
                            :
                            <>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
                                    {
                                        examResult?.resultByKnowledgeArea?.map((knowledgeArea, index) => (
                                            <Chart
                                                chartType="PieChart"
                                                data={getDataCharts(knowledgeArea.knowledgeAreaId)}
                                                options={getDataOptions(knowledgeArea.knowledgeAreaId)}
                                                width={"30rem"}
                                                height={"40rem"}
                                            />

                                        ))
                                    }
                                </Box>
                                <Box>
                                    <Button
                                        sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
                                        variant="contained"
                                        onClick={handleReviewQuestions}
                                    >
                                        Revisar Questões
                                    </Button>
                                </Box>
                            </>


                        :
                        <>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px", width: "100%" }}>
                                <Label>Questão {currentQuestionIndex + 1}</Label>
                                <FaTimes onClick={handleCloseModal} style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }} />
                                {
                                    exam?.questions[currentQuestionIndex] &&
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "90%", gap: "2rem" }}>
                                        <Label
                                            style={{
                                                marginTop: "1rem"
                                            }}
                                        >Enunciado</Label>
                                        {
                                            <div style={{
                                                overflow: "auto",
                                                padding: "10px"
                                            }}
                                                dangerouslySetInnerHTML={{ __html: exam?.questions[currentQuestionIndex].statement }} />
                                        }
                                        <Label>Alternativas</Label>
                                        <div style={{
                                            overflow: "auto",
                                            padding: "10px"
                                        }}>
                                            {
                                                exam?.questions[currentQuestionIndex].alternatives.map((alternative, index) => {
                                                    const isCorrect = alternative.id === currentQuestion?.correctAlternativeId;
                                                    const isWrong = alternative.id === exam?.questions[currentQuestionIndex]?.selectedAlternativeId && alternative.id !== currentQuestion?.correctAlternativeId;
                                                    return (
                                                        <Content key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            {
                                                                <FormControlLabel
                                                                    value={alternative.id}
                                                                    control={
                                                                        <Radio
                                                                            sx={{
                                                                                color: alternative.id === exam?.questions[currentQuestionIndex]?.selectedAlternativeId &&
                                                                                    alternative.id === currentQuestion?.correctAlternativeId ? "green" : 'red',
                                                                                '&.Mui-checked': {
                                                                                    color: alternative.id === exam?.questions[currentQuestionIndex]?.selectedAlternativeId &&
                                                                                        alternative.id === currentQuestion?.correctAlternativeId ? "green" : 'red',
                                                                                }
                                                                            }}
                                                                            checked={alternative.id === exam?.questions[currentQuestionIndex]?.selectedAlternativeId}
                                                                            disabled={true}
                                                                        />
                                                                    }
                                                                    label={
                                                                        isCorrect ?
                                                                            <Alert severity="success" sx={{ width: '25rem', display: 'flex', alignItems: 'center' }}>
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <span dangerouslySetInnerHTML={{ __html: alternative.text }} style={{ marginRight: '8px' }} />
                                                                                    <span> - Alternativa correta!</span>
                                                                                </div>
                                                                            </Alert>
                                                                            :
                                                                            isWrong ?
                                                                                <Alert severity="error" sx={{ width: '25rem', display: 'flex', alignItems: 'center' }}>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <span dangerouslySetInnerHTML={{ __html: alternative.text }} style={{ marginRight: '8px' }} />
                                                                                        <span> - Alternativa incorreta!</span>
                                                                                    </div>
                                                                                </Alert>
                                                                                :
                                                                                <label
                                                                                    dangerouslySetInnerHTML={{ __html: alternative.text }}
                                                                                />
                                                                    }
                                                                />

                                                            }
                                                        </Content>
                                                    )
                                                })


                                            }
                                        </div>

                                        <Label>Justificativa</Label>
                                        {
                                            <div style={{
                                                maxHeight: "14rem",
                                                overflow: "auto",
                                                padding: "10px"
                                            }}
                                                dangerouslySetInnerHTML={{ __html: currentQuestion?.justification ?? "" }} />
                                        }

                                        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>

                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
                                                disabled={currentQuestionIndex === exam?.questions.length - 1}
                                                onClick={() => handleChangeQuestion(currentQuestionIndex + 1)}
                                            >
                                                Próxima questão
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ margin: '2rem' }}>
                                <PaginationQuestionsExam totalItems={exam?.questions?.length | 0} itemsPerPage={1} currentPage={currentQuestionIndex} setCurrentPage={handleChangeQuestion} otherQuestionsIsDisabled={false} />
                            </div>

                        </>
                }

            </Container>
        </Modal>
    )
};
