import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { DeactiveLearningPathLevelCommand, LearningPathService, LearningPathViewModel } from "api-gen";
import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateLearningPathLevelModal from "./CreateLearningPathLevelModal";
import EditLearningPathLevelTopicSequenceModal from "./EditLearningPathLevelTopicSequenceModal";
import { useNotification } from "services/NotificationContext";
import { DeleteLearningPathLevel } from "../Commom/FindLearningPathLevel";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

interface EditLearningPathModalProps {
    isOpen: boolean;
    onClose: () => void;
    learningPath: LearningPathViewModel;
    allLearningPaths: LearningPathViewModel[];
    setLearningPaths: (learningPaths: LearningPathViewModel[]) => void;
};

const EditLearningPathModal = ({ isOpen, onClose, learningPath, setLearningPaths, allLearningPaths }: EditLearningPathModalProps) => {
    const levels = learningPath?.learningPathLevels;
    const [isAddingLevel, setIsAddingLevel] = useState<boolean>(false);

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={onClose}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Editar trilha de conhecimento</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => setIsAddingLevel(true)}>
                            Adicionar <AddIcon />
                        </Button>
                    </Box>
                    <Box sx={{ width: '100%', height: '40rem' }}>
                        {
                            levels.length > 0 ?
                                <RenderChildren levels={levels} learningPath={learningPath} allLearningPaths={allLearningPaths} setLearningPaths={setLearningPaths} />
                                :
                                <Box sx={{width :'100%', height: '100%', display: 'flex', alignItems : 'center', justifyContent: 'center'}}>
                                    <Typography variant="h4" color='primary'>Nenhum nível cadastrado</Typography>
                                </Box>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
            {
                isAddingLevel && (
                    <CreateLearningPathLevelModal
                        open={isAddingLevel}
                        onClose={() => setIsAddingLevel(false)}
                        learningPath={learningPath}
                        allLearningPaths={allLearningPaths}
                        setLearningPath={setLearningPaths}
                    />
                )
            }
        </>
    );
};

const RenderChildren = ({ levels, learningPath, allLearningPaths, setLearningPaths }) => {
    const { addNotification } = useNotification();

    const [isEditingTopicSequence, setIsEditingTopicSequence] = useState<boolean>(false);
    const [isConfirmedDelete, setIsConfirmedDelete] = useState<boolean>(false);
    const [level, setLevel] = useState<LearningPathViewModel>(undefined);

    const handleEditTopicSequence = (level) => {
        setLevel(level);
        setIsEditingTopicSequence(true);
    };

    const handleCloseEditTopicSequence = () => {
        setIsEditingTopicSequence(false);
        setLevel(undefined);
    };

    const handleOpenIsConfirmedDelete = (level) => {
        setIsConfirmedDelete(true);
        setLevel(level);
    };

    const handleCloseIsConfirmedDelete = () => {
        setIsConfirmedDelete(false);
        setLevel(undefined);
    };

    function findLevelById(levels, parentId) {
        for (let i = 0; i < levels.length; i++) {
            const level = levels[i];

            if (level.id === parentId) {
                return level;
            }

            if (level.children && level.children.length > 0) {
                const foundInChildren = findLevelById(level.children, parentId);
                if (foundInChildren) {
                    return foundInChildren;
                }
            }
        }

        return null;
    }

    const getCurrentLevel = (level) => {
        var value = [1];
        var count = 2;

        while (level?.parentId != null) {
            value.push(count);
            count++;
            level = findLevelById(learningPath.learningPathLevels, level.parentId);
        }

        return value.join('.');
    };

    const handleDeleteLearningPathLevel = async (learningPathLevelId: number) => {
        try {
            const body: DeactiveLearningPathLevelCommand = {
                learningPathLevelId: learningPathLevelId,
                companyId: learningPath.companyId
            };

            const result = await LearningPathService.deleteApiLearningPathRemoveLearningPathLevel(learningPath.companyId, body);
            if (result.statusCode.toString().startsWith("2")) {

                const allLearningPathsUpdated = allLearningPaths;
                const updatedLearningPath = allLearningPathsUpdated.find(lp => lp.id === learningPath.id);
                DeleteLearningPathLevel(learningPath, learningPathLevelId);

                const index = allLearningPathsUpdated.findIndex(lp => lp.id === learningPath.id);
                allLearningPathsUpdated[index] = updatedLearningPath;

                setLearningPaths(allLearningPathsUpdated);
                addNotification("Nível removido com sucesso!", "#4BB543");
                handleCloseIsConfirmedDelete();

            } else {
                addNotification(result.message, "#bb2124");
            }

        } catch (error) {
            addNotification("Erro ao remover nível", "#bb2124");
        }
    };

    return (
        <>
            {levels.map((level, index) => (
                <>
                    <Box key={index}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
                            <Box>
                                <Typography variant="h6">{getCurrentLevel(level)} - {level.name}</Typography>
                                <Typography variant="body2">{level.learningPathLevelTopicSequences.length} conteúdos</Typography>
                            </Box>
                            <Box>

                                <IconButton color="primary" onClick={() => { handleEditTopicSequence(level); }}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton color="primary" onClick={() => handleOpenIsConfirmedDelete(level)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        {level.children && level.children.length > 0 && (
                            <RenderChildren levels={level.children} learningPath={learningPath} allLearningPaths={allLearningPaths} setLearningPaths={setLearningPaths} />
                        )}
                    </Box>



                </>
            ))}

            {
                isEditingTopicSequence && level &&
                <EditLearningPathLevelTopicSequenceModal
                    open={isEditingTopicSequence}
                    onClose={handleCloseEditTopicSequence}
                    learningPath={learningPath}
                    learningPathLevel={level}
                    allLearningPaths={allLearningPaths}
                    setLearningPaths={setLearningPaths}
                />
            }
            {
                isConfirmedDelete && level
                && <ConfirmDeleteModal
                    open={isConfirmedDelete}
                    onClose={handleCloseIsConfirmedDelete}
                    onDelete={handleDeleteLearningPathLevel}
                    id={level.id}
                    principalLabel="Tem certeza de que deseja excluir este nível do caminho de aprendizado?"
                    secondLabel="Também serão excluídos todos os níveis sequenciais a esse nível."
                />
            }

        </>
    );
};
export default EditLearningPathModal;