import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, IconButton, Tabs, Tab, FormControl, TextField } from '@mui/material';
import { EditLearningPathLevelCommand, LearningPathLevelTopicSequenceViewModel, LearningPathLevelsViewModel, LearningPathService, LearningPathViewModel } from 'api-gen';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import TreeLearningPathLevelsSelect from './TreeLearningPathLevelsSelect';
import AddLearningPathContentToLevelModal from './AddLearningPathContentToLevelModal';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { ReplaceLearningPathLevel } from "../Commom/FindLearningPathLevel";
import { useNotification } from "services/NotificationContext";


interface EditLearningPathLevelTopicSequenceModalProps {
    open: boolean;
    onClose: () => void;
    learningPathLevel: LearningPathLevelsViewModel;
    learningPath: LearningPathViewModel;
    allLearningPaths: LearningPathViewModel[];
    setLearningPaths: (learningPath: LearningPathViewModel[]) => void;
}


const EditLearningPathLevelTopicSequenceModal = (props: EditLearningPathLevelTopicSequenceModalProps) => {
    const { addNotification } = useNotification();
    const filterSequence = () => {
        const newItems = (props.learningPathLevel?.learningPathLevelTopicSequences || []).map(sequence => ({ ...sequence }));
        const sortedSequences = newItems.sort((a, b) => a.level - b.level);
        return sortedSequences;
    };
    const [selectedTab, setSelectedTab] = useState('0');
    const [parentId, setParentId] = React.useState<number>(props.learningPathLevel?.parentId);
    const [learningPathLevelName, setLearningPathLevelName] = React.useState<string>(props.learningPathLevel?.name);
    const [sequences, setSequences] = React.useState<LearningPathLevelTopicSequenceViewModel[]>(filterSequence());
    const [isAddingContent, setIsAddingContent] = useState<boolean>(false);
    const [isLoadingSaving, setIsLoadingSaving] = useState<boolean>(false);

    const handleSaveTopicSequence = async () => {
        setIsLoadingSaving(true);
        try {
            const body: EditLearningPathLevelCommand = {
                learningPathLevelId: props.learningPathLevel.id,
                companyId: props.learningPath.companyId,
                name: learningPathLevelName,
                parentId: parentId,
                topicSequences: sequences
            };

            const result = await LearningPathService.putApiLearningPathEditLearningPathLevel(props.learningPath.companyId, body);

            if (result.statusCode.toString().startsWith("2")) {

                const allLearningPathsUpdated = props.allLearningPaths;
                const updatedLearningPath = allLearningPathsUpdated.find(lp => lp.id === props.learningPath.id);
                ReplaceLearningPathLevel(updatedLearningPath, result.data);

                const index = allLearningPathsUpdated.findIndex(lp => lp.id === props.learningPath.id);
                allLearningPathsUpdated[index] = updatedLearningPath;

                props.setLearningPaths(allLearningPathsUpdated);
                setIsLoadingSaving(false);
                props.onClose();
            } else {
                addNotification(result.message, "#bb2124");
            }

        } catch (error) {
            setIsLoadingSaving(false);
            addNotification("Erro ao editar nível na trilha de conhecimento", "#bb2124");
        };

        props.onClose();
    };


    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const newSequences = Array.from(sequences);
        const [movedItem] = newSequences.splice(result.source.index, 1);
        newSequences.splice(result.destination.index, 0, movedItem);

        const updatedSequences = newSequences.map((sequence, index) => ({
            ...sequence,
            level: index + 1
        }));

        setSequences(updatedSequences);
    };

    const handleChangeSelectedTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleDeleteTopic = (sequenceId: number) => {
        const currentSequence = sequences.find(sequence => sequence.id === sequenceId);
        const currentSequenceLevel = currentSequence.level;
        const updatedSequences = sequences.filter(sequence => sequence.id !== sequenceId);

        for (let i = currentSequenceLevel - 1; i < updatedSequences.length; i++) {
            updatedSequences[i].level = i + 1;
        }

        setSequences(updatedSequences);
    };


    return (
        <>
            <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
                <DialogTitle>Editar Sequência de Conteúdos</DialogTitle>
                <DialogContent sx={{ height: '40rem' }}>
                    <TabContext value={selectedTab}>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '1rem' }}>
                            <Typography variant="h4">Conteúdos</Typography>
                            <Button onClick={() => setIsAddingContent(true)}> Adicionar conteúdo</Button>
                        </Box>
                        <Tabs value={selectedTab} onChange={handleChangeSelectedTab} aria-label="abas simples">
                            <Tab label="Geral" value="0" />
                            <Tab label="Conteúdos" value="1" />
                        </Tabs>
                        <TabPanel value="0" sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Typography variant="h6">Informações Gerais</Typography>
                            <FormControl fullWidth>
                                <TextField
                                    value={learningPathLevelName}
                                    onChange={(e) => setLearningPathLevelName(e.target.value)}
                                    label="Nome do nível"
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TreeLearningPathLevelsSelect
                                    treeData={props.learningPath.learningPathLevels}
                                    idSelected={parentId}
                                    setIdSelected={setParentId}
                                    label='Nível pai'
                                />
                            </FormControl>
                        </TabPanel>
                        <TabPanel value="1">
                            <Box sx={{width: '100%', height: '20rem'}}>
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="sequences" type="SEQUENCE">
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {
                                                    sequences?.length > 0 ?
                                                        sequences.map((sequence, index) => (
                                                            <Draggable key={sequence?.id?.toString()} draggableId={sequence?.id?.toString()} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Box
                                                                            p={2}
                                                                            display="flex"
                                                                            flexDirection="row"
                                                                            justifyContent="space-between"
                                                                            alignItems="flex-start"
                                                                            width="100%"
                                                                            bgcolor="#F6F9FC"
                                                                            boxShadow={2}
                                                                            borderRadius={4}
                                                                            margin="0 1rem 1rem 0"
                                                                        >
                                                                            <Box>
                                                                                <Typography variant="h6" {...provided.dragHandleProps}>
                                                                                    Conteúdo: {sequence?.topic?.description}
                                                                                </Typography>
                                                                                <Typography variant="body2">Ordenação: {sequence?.level}</Typography>
                                                                            </Box>
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <IconButton onClick={() => handleDeleteTopic(sequence?.id)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                                <DragHandleIcon />
                                                                            </Box>
                                                                        </Box>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))
                                                        : <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Typography variant="h4" color='primary'>Nenhum conteúdo cadastrado</Typography>
                                                        </Box>
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Box>
                        </TabPanel>
                    </TabContext>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Fechar</Button>
                    <LoadingButton
                        loading={isLoadingSaving}
                        onClick={handleSaveTopicSequence}
                    >
                        Salvar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {
                isAddingContent &&
                <AddLearningPathContentToLevelModal
                    open={isAddingContent}
                    onClose={() => setIsAddingContent(false)}
                    learningPath={props.learningPath}
                    learningPathLevel={props.learningPathLevel}
                    sequences={sequences}
                    setSequences={setSequences}
                    allLearningPaths={props.allLearningPaths}
                    setLearningPaths={props.setLearningPaths} />
            }
        </>
    );
};

export default EditLearningPathLevelTopicSequenceModal;