import React, { useEffect, useState } from "react";
import Menu from "../../components/Header";
import { Box, Button, Container, Divider, IconButton, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { DeactiveLearningPathCommand, LearningPathService, LearningPathViewModel } from "api-gen";
import useAuth from '../../hooks/useAuth.js';
import EditLearningPathModal from "./Components/EditLearningPathModal";
import AddLearningPathModal from "./Components/AddLearningPathModal";
import ConfirmDeleteModal from "./Components/ConfirmDeleteModal";
import { useNotification } from "services/NotificationContext";
import { GetAllLevelsCountByLearningPath, GetAllTopicsCountByLearningPath } from "./Commom/FindLearningPathLevel";
const LearningPath = () => {

    return (
        <>
            <Menu />
            <LearningPathsComponents />
        </>
    );

};


interface TrilhaItemProps {
    allLearningPaths: LearningPathViewModel[];
    setLearningPath: (learningPaths: LearningPathViewModel[]) => void;
    learningPath: LearningPathViewModel;
    handleDelete: (id: number) => void;
}

const TrilhaItem = ({ learningPath, allLearningPaths, setLearningPath, handleDelete }: TrilhaItemProps) => {

    const [isEditLearningPath, setIsEditLearningPath] = useState<boolean>(false);
    const levels = GetAllLevelsCountByLearningPath(learningPath);

    const topics = GetAllTopicsCountByLearningPath(learningPath);

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
                <Box>
                    <Typography variant="h6">Trilha de conhecimento - Disciplina: {learningPath?.subject?.description}</Typography>
                    <Typography variant="body2">{levels} níveis</Typography>
                    <Typography variant="body2">{topics} conteúdos</Typography>
                </Box>
                <Box>
                    <IconButton color="primary" onClick={() => handleDelete(learningPath.id)}>
                        <DeleteIcon />
                   </IconButton>
                    <Button onClick={() => { setIsEditLearningPath(!isEditLearningPath); }}>
                        Editar
                    </Button>
                </Box>
            </Box>
            {
                isEditLearningPath && (
                    <EditLearningPathModal
                        isOpen={isEditLearningPath}
                        learningPath={learningPath}
                        onClose={() => setIsEditLearningPath(false)}
                        allLearningPaths={allLearningPaths}
                        setLearningPaths={setLearningPath}

                    />
                )
            }
        </>
    );
};

const LearningPathsComponents = () => {
    const { user } = useAuth();
    const [learningPaths, setLearningPaths] = useState<LearningPathViewModel[]>();
    const [isAddLearningPath, setIsAddLearningPath] = useState<boolean>(false);
    const [isConfirmedDelete, setIsConfirmedDelete] = useState<boolean>(false);
    const [idLearningPath, setIdLearningPath] = useState<number>(undefined);
    const {addNotification} = useNotification();


    const getAllLearningPaths = async () => {
        if (!user?.companyId)
            return;
        try {

            var response = await LearningPathService.getApiLearningPath(user?.companyId);
            if (response.data) {
                setLearningPaths(response.data);
            }

        }
        catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getAllLearningPaths();
        // eslint-disable-next-line
    }, [user?.companyId]);

    const handleDeleteConfirm = async (id: number) => {
        setIsConfirmedDelete(true);
        setIdLearningPath(id);
    };

    const handleCancelDeleteLearningPath = async () => {
        setIsConfirmedDelete(false);
        setIdLearningPath(undefined);
    };

    const handleDeleteConfirmed = async (id: number) => {
        if(!user?.companyId)
            return;
        try {
            const body : DeactiveLearningPathCommand = {
                learningPathId: id,
                companyId: user?.companyId as number
            }; 

            const result = await LearningPathService.deleteApiLearningPathRemoveLearningPath(user?.companyId as number,body);
            if (result.statusCode.toString().startsWith("2")) {
                addNotification("Trilha de conhecimento excluída com sucesso","green");
                const updatesLearningPaths = learningPaths.filter(learningPath => learningPath.id !== id);
                setLearningPaths(updatesLearningPaths); 
                handleCancelDeleteLearningPath();
            }else {
                addNotification(result.message,"red");
            }
        }
        catch (e) {
            addNotification("Erro ao excluir trilha de conhecimento","red");
        }
    };



    return (
        <Container sx={{ width: '70%' }}>
            <Box textAlign="center" my={4}>
                <Typography variant="h4">Trilhas de conhecimento</Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" margin="2rem 0 2rem 0">
                <Button onClick={() => setIsAddLearningPath(true)}>
                    Adicionar trilha
                </Button>
            </Box>

            {
                learningPaths?.map((learningPath, index) => (
                    <>
                        <TrilhaItem
                            key={index}
                            learningPath={learningPath}
                            allLearningPaths={learningPaths}
                            setLearningPath={setLearningPaths}
                            handleDelete={handleDeleteConfirm}
                        />
                        <Divider />
                    </>
                ))
            }
            {
                isAddLearningPath &&
                <AddLearningPathModal
                    allLearningPaths={learningPaths}
                    setLearningPaths={setLearningPaths}
                    open={isAddLearningPath}
                    onClose={() => setIsAddLearningPath(false)}
                    companyId={user?.companyId as number} 
                />
            }
            {
                isConfirmedDelete &&
                <ConfirmDeleteModal
                    id={idLearningPath}
                    open={isConfirmedDelete}
                    onClose={handleCancelDeleteLearningPath}
                    onDelete={handleDeleteConfirmed}
                    principalLabel="Deseja realmente excluir a trilha de conhecimento?"
                    secondLabel="Irá excluir todos os níveis e conteúdos associados a ela."
                    />
            }
        </Container>
    );
};

export default LearningPath;