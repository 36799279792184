import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Home from "../pages/Home";
import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import Empresas from "../pages/Empresas"
import Alunos from "../pages/Alunos"
import Professores from "../pages/Professores"
import AreasConhecimento from "../pages/KnowledgeAreas"
import Subjects from "../pages/Subjects"
import ExamBoards from "../pages/ExamBoards"
import Taxonomies from "../pages/Taxonomies";
import Topics from "../pages/Topics";
import Questions from "../pages/Questions";
import Exams from "../pages/Exams/index.tsx";
//import QuestionsAnswered from "../pages/QuestionsAnswered/index.tsx";
import CompanyParams from "../pages/CompanyParams/index.tsx";
import StudySession from "../pages/StudySession/Index.tsx";
import LearningPath from "../pages/LearningPath/index.tsx";
import RecoveryPassword from "../pages/RecoveryPassword";
import LearningPathStudent from "../pages/LearningPathStudent/index.tsx";
const Private = ({ Item }) => {
  const { signed } = useAuth();

  return signed > 0 ? <Item /> : <Signin />;
};

const RoutesApp = () => {
  return (
      <Fragment>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route exact path="/home" element={<Private Item={Home} />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/recuperarsenha" element={<RecoveryPassword />} />
          <Route exact path="/empresas" element={<Empresas />} />
          <Route exact path="/professores" element={<Professores />} />
          <Route exact path="/areas" element={<AreasConhecimento />} />
          <Route exact path="/subjects" element={<Subjects />} />
          <Route exact path="/alunos" element={<Alunos />} />
          <Route exact path="/aplicadoras" element={<ExamBoards />} />
          <Route exact path="/taxonomias" element={<Taxonomies />} />
          <Route exact path="/conteudos" element={<Topics />} />
          <Route exact path="/provas" element={<Exams />} />
          {/* <Route exact path="/questoesalunos" element={<QuestionsAnswered />} /> */}
          <Route exact path="/sessaodeestudo" element={<StudySession />} />
          <Route exact path="/questoes" element={<Questions />} />
          <Route exact path="/configuracoes" element={<CompanyParams />} /> 
          <Route exact path="/trilhasestudo" element={<LearningPath />} /> 
          <Route exact path="/meusestudos" element={<LearningPathStudent />} /> 
          <Route path="*" element={<Signin />} />
        </Routes>
      </Fragment>
  );
};

export default RoutesApp;
