import React, { useState } from "react";
import Modal from "react-modal/lib/components/Modal";
import { Container, Content, Label } from "./StartExamModalStyles";
import { FaTimes } from "react-icons/fa";
import PaginationQuestionsExam from "./PaginationQuestionsExam"; 
import { useNotification } from "../../../services/NotificationContext";
import { ExamViewModel } from "../../../customTypes/ExamViewModel";
import { AnswerExamQuestionCommand } from "../../../customTypes/AnswerExamQuestionCommand";
import useAuth from "../../../hooks/useAuth.js";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ExamsService, StudentsService } from "api-gen";
import { useDesignParams } from "contexts/DesignParamsContext";
type StartExamModalProps = {
    isOpen: boolean;
    exam: ExamViewModel;
    onClose: () => void;
    currentQuestionIndex: number;
    examIsConcluded: boolean;
    setExams: (exam : ExamViewModel[]) => void;
    setExamToViewResult: (exam: ExamViewModel) => void;
    setExamToViewResultModalIsOpen: (isOpen: boolean) => void;

};

const StartExamModal = (props: StartExamModalProps) => {
    const { user }: any = useAuth(); 
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(props.currentQuestionIndex);
    const [exam, setExam] = useState<ExamViewModel>(props.exam);
    const [error, setError] = useState<string>("");
    const [examIsConcluded, setExamIsConcluded] = useState<boolean>(props.examIsConcluded); 
    const [isLoading, setIsLoading] = useState<boolean>(false); 
    const designParams = useDesignParams();

    const { addNotification } = useNotification();

    const handleCloseModal = async () => {
        setExam(props.exam);
        const result = await StudentsService.getApiStudentsExams(user?.studentId, user?.companyId);
        if (result?.exams?.length > 0) {
            props.setExams(result?.exams);
        };
        setError(""); 
        props.onClose();
    }

    const handleSelecteAlternative = (questionIndex: number, alternativeIndex: number) => {
        const newExam = { ...exam };
        newExam.questions[questionIndex].selectedAlternativeId = newExam.questions[questionIndex].alternatives[alternativeIndex].id;
        setExam(newExam);
    }

    const handleAnswerQuestion = async () => {
        if (examIsConcluded)
            return;

        if (!exam.questions[currentQuestionIndex].selectedAlternativeId) {
            setError("Selecione uma alternativa para responder a questão.");
            return;
        }

        setError("");
        setIsLoading(true);

        var isSuccessfulAnswerQuestion = await answerQuestion();

        if (!isSuccessfulAnswerQuestion){
            setIsLoading(false);
            return;
        }

        if (currentQuestionIndex === exam.questions.length - 1) {

            const isSuccessfulFinishExam = await finishExam();

            if (isSuccessfulFinishExam)
                setExamIsConcluded(true);

            setIsLoading(false);
            return;
        }
        if (!exam.questions[currentQuestionIndex + 1].startTime) {
            await StartSolvingQuestion();
        }
        setIsLoading(false);
    }

    const answerQuestion = async () => {
        let isSuccessful = false;
        try {
            const command: AnswerExamQuestionCommand = {
                examId: exam.id,
                examQuestionId: exam.questions[currentQuestionIndex].id,
                alternativeId: exam.questions[currentQuestionIndex].selectedAlternativeId || 0
            };

            await ExamsService.postApiExamsQuestionsAnswerQuestion(exam.id, exam.questions[currentQuestionIndex].id,user?.companyId, command);
            isSuccessful = true;
        } catch (error) {
            addNotification("Erro ao responder questão.", "red");
        }
        return isSuccessful;
    };

    const finishExam = async () => {
        var isSuccessfulFinishExam = false;
        try {
            await ExamsService.postApiExamsFinishExam(exam.id, user?.companyId);
            isSuccessfulFinishExam = true;
        } catch (error) {
            addNotification("Erro ao finalizar prova.", "red");
        }
        return isSuccessfulFinishExam;
    };

    const StartSolvingQuestion = async () => {
        try {
            await ExamsService.postApiExamsQuestionsStartSolvingQuestion(exam.id, exam.questions[currentQuestionIndex + 1].id, user?.companyId);

            if (currentQuestionIndex < exam.questions.length)
                setCurrentQuestionIndex(currentQuestionIndex + 1);
        } catch (error) {
            addNotification("Erro ao iniciar resolução da questão.", "red");
        }
    };

    const handleViewResults = () => { 
        handleCloseModal();
        props.onClose();
        props.setExamToViewResult(exam);
        props.setExamToViewResultModalIsOpen(true);
    };




    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Adicionar Aplicadora"
            appElement={document.getElementById('root')}
            style={{
                content: {
                    width: '80%',
                    height: '90%',
                    margin: 'auto',
                    background: '#fff',
                    border: '2px solid #ccc',
                    borderRadius: '8px',
                    padding: '20px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }
            }}
        >
            <Container>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "100%" }}>
                    <Label>Realizar Prova</Label>
                    <Label>Questão {currentQuestionIndex + 1}</Label>
                    <FaTimes onClick={handleCloseModal} style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }} />
                    {
                        exam?.questions[currentQuestionIndex] &&
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "90%", gap: "2rem" }}>
                            <Label
                                style={{
                                    marginTop: "1rem"
                                }}
                            >Enunciado</Label>
                            {
                                <div style={{
                                    maxHeight: "14rem",
                                    overflow: "auto",
                                    padding: "10px"
                                }}
                                    dangerouslySetInnerHTML={{ __html: exam?.questions[currentQuestionIndex].statement }} />
                            }
                            <Label>Alternativas</Label>
                            <div style={{
                                maxHeight: "14rem",
                                overflow: "auto",
                                padding: "10px"
                            }}>
                                {
                                    exam?.questions[currentQuestionIndex].alternatives.map((alternative, index) => (
                                        <Content key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                            <input type="radio" id={alternative.guid} checked={alternative.id === exam?.questions[currentQuestionIndex]?.selectedAlternativeId} onChange={() => handleSelecteAlternative(currentQuestionIndex, index)} />
                                            <label dangerouslySetInnerHTML={{ __html: alternative.text }} />
                                        </Content>
                                    ))
                                }

                            </div>


                            <div style={{ display: "flex", width: "100%", justifyContent: error ? "space-between" : "flex-end" }}>
                                {
                                    error && <label style={{ color: "red" }}>{error}</label>
                                }
                                <LoadingButton
                                    variant="contained"
                                    onClick={handleAnswerQuestion}
                                    disabled={examIsConcluded}
                                    sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
                                    loading={isLoading}>
                                        Responder
                                    </LoadingButton>
                                 
                            </div>
                            <div>
                                {
                                    examIsConcluded &&
                                    <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center", gap: "2rem" }}>
                                        <label style={{ color: "green", fontSize: "2rem" }}>Prova finalizada</label>
                                        <LoadingButton
                                            sx={{ backgroundColor: designParams?.primaryColorHex || '' }} 
                                            variant="contained" onClick={handleViewResults}
                                        >
                                            Visualizar Resultados
                                        </LoadingButton>
                                    </Box>
                                }
                            </div>
                        </div>
                    }
                </div>

                <PaginationQuestionsExam totalItems={exam?.questions?.length | 0} itemsPerPage={1} currentPage={currentQuestionIndex} setCurrentPage={setCurrentQuestionIndex} otherQuestionsIsDisabled={true} />
            </Container>
        </Modal>
    );
};

export default StartExamModal;