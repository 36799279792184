import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { CompaniesService, CreateLearningPathCommand, LearningPathService, LearningPathViewModel } from "api-gen";
import { LoadingButton } from "@mui/lab";
import { useNotification } from "services/NotificationContext";

interface AddLearningPathModalProps {
    open: boolean;
    onClose: () => void;
    companyId: number;
    allLearningPaths: LearningPathViewModel[];
    setLearningPaths: (learningPaths: LearningPathViewModel[]) => void;
}

const AddLearningPathModal: React.FC<AddLearningPathModalProps> = ({ open, onClose, companyId, allLearningPaths, setLearningPaths }) => {
    const [selectedSubject, setSelectedSubject] = useState<number>(undefined);
    const [subjects, setSubjects] = useState<any[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const { addNotification} = useNotification();

    const loadAllSubjects = async () => {
        try {
            var result = await CompaniesService.getApiCompaniesSubjects(companyId);

            if (result?.subjects?.length > 0) {
                const subjects = result?.subjects.map(item => ({
                    value: item.id,
                    label: item.description
                }));

                setSubjects(subjects);
            }


        } catch (e) {

        };
    };

    const handleDisciplineChange = (event) => {
        setSelectedSubject(event.target.value as number);
    };

    const handleSave = async () => {
        setIsSaving(true);
        try{
            const body : CreateLearningPathCommand = {
                companyId: companyId,
                subjectId: selectedSubject
            } 
            const result = await LearningPathService.postApiLearningPath(companyId, body);
            if(result.statusCode.toString().startsWith('2')){
                addNotification('Trilha de conhecimento criada com sucesso', '#28a745');
                const learningPaths = [...allLearningPaths];
                learningPaths.push(result.data);
                setLearningPaths(learningPaths);

                onClose();
            }else{
                addNotification(result.message, '#bb2124');	
            }

            setIsSaving(false);

        }catch(e){
            addNotification("Erro ao adicionar trilha de conhecimento.", '#bb2124');	
            setIsSaving(false);
        }
    };

    useEffect(() => {
        loadAllSubjects();
        //eslint-disable-next-line
    }, []);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Criar trilha de conhecimento</DialogTitle>
            <DialogContent>
                <FormControl variant='standard' fullWidth >
                    <InputLabel>Selecione uma disciplina</InputLabel>
                    <Select
                        value={selectedSubject || 0}
                        onChange={handleDisciplineChange}
                    >
                        {
                            subjects?.map((subject: any) => {
                                return <MenuItem value={subject.value}>{subject.label}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <LoadingButton
                 loading={isSaving}
                 onClick={handleSave}
                 disabled={!selectedSubject || selectedSubject === 0}
                 >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddLearningPathModal;