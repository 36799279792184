import styled from "styled-components";

export const Label = styled.label`
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const StyledSelect = styled.select`
  outline: none;
  padding: 16px 20px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: ${({ disabled }) => (disabled ? "#f0f2f5;" : "#E0EBF1")};
  border: none;
`;

export const SearchInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }
`;
