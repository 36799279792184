import React from 'react';
import { TreeView, TreeItem } from '@mui/lab';
import { Select, MenuItem, FormControl, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LearningPathLevelTopicSequenceViewModel } from 'api-gen';
import { SelectChangeEvent } from '@mui/material';

export type LearningPathLevelsViewModel = {
  readonly id?: number;
  readonly learningPathId?: number;
  readonly name?: string | null;
  readonly parentId?: number | null;
  readonly children?: Array<LearningPathLevelsViewModel> | null;
  readonly learningPathLevelTopicSequences?: Array<LearningPathLevelTopicSequenceViewModel> | null;
};

const renderTreeItems = (nodes: LearningPathLevelsViewModel[]) => {
  return nodes.map((node) => (
    <TreeItem key={node.id} nodeId={node.id?.toString() || ''} label={node.name}>
      {node.children ? renderTreeItems(node.children) : null}
    </TreeItem>
  ));
};

interface TreeLearningPathLevelsSelectProps {
    treeData: LearningPathLevelsViewModel[];
    idSelected: number;
    setIdSelected: (id: number) => void;
    label: string;

};
const TreeLearningPathLevelsSelect = (props: TreeLearningPathLevelsSelectProps) => { 


  
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    props.setIdSelected(Number(event.target.value));
  };

  const getSelectItems = (nodes: LearningPathLevelsViewModel[]) => {
    let items: JSX.Element[] = [];
    nodes.forEach((node) => {
      items.push(<MenuItem key={node.id} value={node.id?.toString() || ''}>{node.name}</MenuItem>);
      if (node.children) {
        items = items.concat(getSelectItems(node.children));
      }
    });
    return items;
  };

  return (
    <FormControl fullWidth>
      <Typography variant='h6'>{props.label}</Typography>
      <Select
      labelId="select-label"
       value={props?.idSelected?.toString()} onChange={handleSelectChange} displayEmpty>
        <MenuItem value="">-</MenuItem>
        {getSelectItems(props.treeData)}
      </Select>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        selected={props?.idSelected?.toString()}
        onNodeSelect={(event, nodeIds) => props.setIdSelected(nodeIds)}
      >
        {renderTreeItems(props.treeData)}
      </TreeView>
    </FormControl>
  );
};

export default TreeLearningPathLevelsSelect;