import styled from 'styled-components';

export const EditButton = styled.button`
    padding: 8px 12px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #1A202C;
    color: #fff;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

export const DisableButton = styled.button`
    padding: 8px 12px;
    cursor: ${({ disabled }) => (disabled ? "" : "pointer")};;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#b81414')};
    color: #fff;
    transition: background-color 0.3s ease;
    disabled: ${({ disabled }) => (disabled ? true : false)};

    &:not(:disabled):hover {  
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#c82333')};
    } 
`;

export const AddButton = styled.button`
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #32CD32;
    color: #fff;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

export const LabelError = styled.label`
  font-size: 14px;
  color: red;
`;