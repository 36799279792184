/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateCompanyCommand } from '../models/ActivateCompanyCommand';
import type { CreateCompanyCommand } from '../models/CreateCompanyCommand';
import type { DeactivateCompanyCommand } from '../models/DeactivateCompanyCommand';
import type { UpdateCompanyCommand } from '../models/UpdateCompanyCommand';
import type { UpdateCompanyParametersCommand } from '../models/UpdateCompanyParametersCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CompaniesService {
    /**
     * Obtém uma Empresa pelo seu Id.
     * @param id Id da Empresa.
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompanies(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Altera as informações da Empresa.
     * Exemplo:
     * { "id": 1, "email": "empresa@email.com", "password": "EmpresaP@ss123",
     * "tradingName": "Emp",
     * "street": "Rua 1", "number": "101", "complement": "Quadra 1", "district": "Bairro X", "zipCode": "12345678",
     * "city": "City Y",
     * "state": "State Z", "stateAcronym": "SZ",
     * "country": "Country A", "countryAcronym": "CA" }
     * @param id Id da Empresa
     * @param requestBody Command que contém as informações para atualizar a Empresa.
     * @returns void
     * @throws ApiError
     */
    public static putApiCompanies(
        id: number,
        requestBody?: UpdateCompanyCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Obtém todas as Empresas.
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompanies1(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies',
        });
    }
    /**
     * Cria uma nova Empresa.
     * Exemplo:
     * { "email": "empresa@email.com", "password": "EmpresaP@ss123",
     * "corporateNumber": "12345678000110", "corporateName": "Empresa LTDA", "tradingName": "Emp",
     * "street": "Rua 1", "number": "101", "complement": "Quadra 1", "district": "Bairro X", "zipCode": "12345678",
     * "city": "City Y",
     * "state": "State Z", "stateAcronym": "SZ",
     * "country": "Country A", "countryAcronym": "CA" }
     * @param requestBody O command contendo as informações da Empresa para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiCompanies(
        requestBody?: CreateCompanyCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Obtém todos os Usuários de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesUsers(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/users',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém todos os Alunos de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesStudents(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/students',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém todos os Professores de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesProfessors(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/professors',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém todas as Áreas do Conhecimento de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesKnowledgeAreas(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/knowledgeAreas',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém todas as Disciplinas de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesSubjects(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/subjects',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém todas as Aplicadoras de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesExamBoards(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/examBoards',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém todas as Taxonomias de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesTaxonomies(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/taxonomies',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém todos os Conteúdos de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesTopics(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/topics',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém todos as Questões de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesQuestions(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/questions',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém os parâmetros de uma Empresa.
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesParameters(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/parameters',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Atualiza os parâmetros da Empresa.
     * Exemplo:
     * { questionRepeatInterval: 120, allowQuestionCatalog: false, companyId: 1 }
     * @param id Id da Empresa.
     * @param requestBody Command com as informações para atualizar os parâmetros da Empresa.
     * @returns void
     * @throws ApiError
     */
    public static putApiCompaniesParameters(
        id: number,
        requestBody?: UpdateCompanyParametersCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{id}/parameters',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Obtém os parâmetros de design de um tenant.
     * @param tenantBaseDomain Domínio base do tenant.
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesDesignParams(
        tenantBaseDomain?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/designParams',
            query: {
                'tenantBaseDomain': tenantBaseDomain,
            },
        });
    }
    /**
     * Obtém as métricas (Analytics) de uma empresa.
     * @param id Id da Empresa.
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesAnalytics(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/analytics',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Obtém as métricas (Analytics) das questões de exercícios de uma empresa.
     * @param id Id da Empresa.
     * @returns any Success
     * @throws ApiError
     */
    public static getApiCompaniesExerciseQuestionsAnalytics(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/exerciseQuestionsAnalytics',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Ativa uma Empresa a partir de seu Id.
     * @param id Id da Empresa a ser ativada.
     * @param requestBody O command contendo os detalhes para ativar a Empresa.
     * @returns void
     * @throws ApiError
     */
    public static putApiCompaniesActivate(
        id: number,
        requestBody?: ActivateCompanyCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{id}/activate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa uma Empresa a partir de seu Id.
     * @param id Id da Empresa a ser ativada.
     * @param requestBody O command contendo os detalhes para desativar a Empresa.
     * @returns void
     * @throws ApiError
     */
    public static putApiCompaniesDeactivate(
        id: number,
        requestBody?: DeactivateCompanyCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{id}/deactivate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
