import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Label } from '../../Signin/styles.js';
import Input from '../../../components/Input/index.js';
import { FaTimes } from 'react-icons/fa';
import { Container, Content } from './AddModalStyles.js';
import { useNotification } from '../../../services/NotificationContext';
import useAuth from '../../../hooks/useAuth.js';
import { TaxonomiesService } from 'api-gen';
import { Button } from '@mui/material';
import { useDesignParams } from 'contexts/DesignParamsContext';

Modal.setAppElement('#root');

const AddTaxonomyModal = ({ isOpen, onClose, id }) => {
  const designParams = useDesignParams();
  const { addNotification } = useNotification();

  const [level, setLevel] = useState("");
  const [percentage, setPercentage] = useState("");

  const [error, setError] = useState("");
  const { user } = useAuth();


  const preencherDados = (dados) => {
    setLevel(dados.taxonomy.level);
    setPercentage(dados.taxonomy.percentage)
  }


  const getTaxonomy = async () => {
    if(user?.companyId) {
      const result = await TaxonomiesService.getApiTaxonomies(id, user?.companyId);
      if (result?.taxonomy) {
        preencherDados(result);
      }
    }
  }

  const resetValues = () => {
    setLevel('');
    setPercentage('');
    setError('');
    onClose();
  }


  useEffect(() => {
    if (id) {
      getTaxonomy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);



  const handleAddSubject = async () => {

    setError("");


    if (id) {
      const body = {
        id ,
        level,
        percentage: Number(percentage)
      }

      try {
        await TaxonomiesService.putApiTaxonomies(id,user?.companyId, body); 
          addNotification('Taxonomia alterada com sucesso!', 'green');
          resetValues(); 
      } catch (e) {
        addNotification('Erro ao alterar Taxonomia!', 'red');
      }

    } else {

      const body = {
        companyId: user.companyId,
        level,
        percentage: Number(percentage)
      };

      try {
        await TaxonomiesService.postApiTaxonomies(user?.companyId,body); 
          addNotification('Taxonomia inserida com sucesso!', 'green');
          resetValues();
      } catch (e) {
        addNotification('Erro ao inserir Taxonomia!', 'red');
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetValues}
      contentLabel="Adicionar Taxonomia"
      appElement={document.getElementById('root')}
      style={{
        content: {
          width: '50%', // Defina o tamanho desejado
          height: '35%', // Defina a altura desejada
          margin: 'auto', // Centralize horizontalmente
          background: '#fff', // Cor de fundo do modal
          border: '2px solid #ccc', // Borda do modal
          borderRadius: '8px', // Bordas arredondadas
          padding: '20px' // Espaçamento interno
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)' // Cor do fundo ao redor do modal
        }
      }}
    >
      <Container>
        <Label>{id ? "Editar Taxonomia" : "Adicionar Taxonomia"}</Label>
        <FaTimes onClick={resetValues} style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }} />
        <Content>
          <Input
            label={"Descrição"}
            type="text"
            placeholder="Digite a descrição"
            value={level}
            onChange={(e) => [setLevel(e.target.value), setError("")]}
          />
          <Input
            label={"Porcentagem"}
            type="number"
            placeholder="Digite a descrição"
            value={percentage}
            onChange={(e) => [setPercentage(e.target.value), setError("")]}
          />

          {error &&
            <label color='red'>{error}</label>
          }

          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            <Button
             variant='contained'
             sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
             onClick={handleAddSubject} 
            >
            {id ? "Alterar" : "Adicionar"}
            </Button>
            <Button
             variant='contained'
             sx={{ backgroundColor: designParams?.secondaryColorHex || 'red' }}
             onClick={resetValues}>
              Cancelar
            </Button>
          </div>

        </Content>
      </Container>
    </Modal>
  );
}

export default AddTaxonomyModal;