import styled from 'styled-components';

export const Container = styled.div`
  display: flex;  
  align-items: center;
  height : 100%;
  justify-content: space-between;
  flex-direction: column; 
`;

export const Content = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
  background-color: white; 
  border-radius: 5px;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
  color: #676767;
`;

export const LabelSignin = styled.label`
  font-size: 16px;
  color: #676767;
`;

export const labelError = styled.label`
  font-size: 14px;
  color: red;
`;

export const Strong = styled.strong`
  cursor: pointer;

  a {
    text-decoration: none;
    color: #676767;
  }
`;
