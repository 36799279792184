/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiRequestOptions } from './ApiRequestOptions';

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

export type OpenAPIConfig = {
    BASE: string;
    VERSION: string;
    WITH_CREDENTIALS: boolean;
    CREDENTIALS: 'include' | 'omit' | 'same-origin';
    TOKEN?: string | Resolver<string> | undefined;
    USERNAME?: string | Resolver<string> | undefined;
    PASSWORD?: string | Resolver<string> | undefined;
    HEADERS?: Headers | Resolver<Headers> | undefined;
    ENCODE_PATH?: ((path: string) => string) | undefined;
};

export const OpenAPI: OpenAPIConfig = {
    //BASE: 'https://localhost:44360',
    BASE: 'https://8m3czjmpti.us-east-1.awsapprunner.com',
    VERSION: '1',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'same-origin',
    TOKEN: getToken
};

function getToken(options: ApiRequestOptions): Promise<string> {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token) {
            resolve(token);
        } else {
            resolve("");
        }
    });
}