import React from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDesignParams } from "contexts/DesignParamsContext";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { ChangePasswordCommand, ForgotPasswordCommand, UsersService } from "api-gen";
import { useNotification } from "services/NotificationContext";

const RecoveryPassword = () => {
    const designParams = useDesignParams();
    const [email, setEmail] = React.useState("");
    const [emailIsEmpty, setEmailIsEmpty] = React.useState(false);
    const [codeIsEmpty, setCodeIsEmpty] = React.useState(false);
    const [emailIsSend, setEmailIsSend] = React.useState(false);
    const [code, setCode] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordConfirm, setPasswordConfirm] = React.useState("");
    const [passwordIsEmpty, setPasswordIsEmpty] = React.useState(false);
    const [passwordConfirmIsEmpty, setPasswordConfirmIsEmpty] = React.useState(false);
    const { addNotification } = useNotification();
    const navigate = useNavigate();

    const handleSendCodeVerification = async () => {


        if (email.trim() === "") {
            setEmailIsEmpty(true);
            return;
        }

        try {
            const body: ForgotPasswordCommand = {
                email: email
            };
            const response = await UsersService.postApiUsersForgotPassword(body);

            if (response.statusCode.toString().startsWith("2")) {
                setEmailIsSend(true);
                addNotification("código de recuperar senha enviado para o email", "green");
            }
            else {
                addNotification("Falha ao enviar e-mail", "red");
            }



        } catch (err) {
            console.error(err);
        }
    };

    const handleRecoveryPassword = async () => {
        if (code.trim() === "") {
            setCodeIsEmpty(true);
            return;
        }
        if (password.trim() === "" ) {
            setPasswordIsEmpty(true);
            return;
        }
        if (passwordConfirm.trim() === "" ) {
            setPasswordConfirmIsEmpty(true);
            return;
        }
        if (password !== passwordConfirm) {
            addNotification("As senhas não são iguais", "red");
            return;
        }

        try {
            const body : ChangePasswordCommand = {
                email: email,
                codeVerification: code.trim(),
                newPassword: password, 
            };

            const response = await UsersService.putApiUsersChangePassword(body);

            if (response.statusCode.toString().startsWith("2")) {
                addNotification("Senha recuperada com sucesso", "green");
                await new Promise((resolve) => setTimeout(resolve, 2000));
                navigate("/");
            }
            else {
                addNotification(response.message, "red");
            }
        }catch(err){
            addNotification("Falha ao recuperar senha", "red");
        }
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: "2rem",
                height: "100vh",
                backgroundColor: designParams?.primaryColorHex || '',
            }}>
            {
                designParams && designParams?.logo && <img style={{ maxWidth: "12rem" }} alt="Logo" src={designParams.logo} />
            }
            <Box
                sx={{
                    backgroundColor: "#fff",
                    padding: "2rem",
                    borderRadius: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "25rem",

                }}
            >
                {
                    emailIsSend ?
                        <>
                            <Alert severity="info">Caso não tenha recebido o código, verifique a caixa de spam</Alert>
                            <Typography variant="h6">Digite o código enviado por email</Typography>
                            <TextField
                                sx={{ width: "90%" }} 
                                placeholder="Digite o código"
                                type="text"
                                value={code || ''}
                                error={codeIsEmpty}
                                onChange={(e) => setCode(e.target.value)}
                            />

                            <Typography variant="body1">Digite a nova senha</Typography>
                            <TextField
                                sx={{ width: "90%" }} 
                                placeholder="Digite a nova senha"
                                value={password}
                                type="password"
                                error={passwordIsEmpty}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <TextField
                                sx={{ width: "90%" }} 
                                placeholder="Confirme a sua senha"
                                value={passwordConfirm}
                                type="password"
                                error={passwordConfirmIsEmpty}
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                            />



                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: designParams?.primaryColorHex || '',
                                    width: "90%",
                                }}
                                onClick={handleRecoveryPassword}
                            >
                                Recuperar
                            </Button>
                            <Button component={RouterLink} to="/" sx={{ width: '90%' }}>
                                Voltar
                            </Button>
                        </>
                        :
                        <>
                            <Typography variant="h6">Recuperar senha</Typography>
                            <TextField
                                sx={{ width: "90%" }}
                                type="email"
                                placeholder="Digite seu E-mail"
                                value={email}
                                error={emailIsEmpty}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: designParams?.primaryColorHex || '',
                                    width: "90%",
                                }}
                                onClick={handleSendCodeVerification}
                            >
                                Recuperar
                            </Button>
                            <Button component={RouterLink} to="/" sx={{ width: '90%' }}>
                                Voltar
                            </Button>
                        </>

                }

            </Box>
        </div>
    );
};

export default RecoveryPassword;