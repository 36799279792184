/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateProfessorCommand } from '../models/ActivateProfessorCommand';
import type { CreateProfessorCommand } from '../models/CreateProfessorCommand';
import type { DeactivateProfessorCommand } from '../models/DeactivateProfessorCommand';
import type { UpdateProfessorCommand } from '../models/UpdateProfessorCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProfessorsService {
    /**
     * Obtém um Professor pelo seu Id.
     * @param id Id do Professor.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiProfessors(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/professors/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Altera as informações de um Professor.
     * Exemplo:
     * { "fullName": "Professor ExamPlatform", "birthDate": "2024-01-16T03:34:14.962Z", "phoneNumber": "11922223333" }
     * @param id Id do Professor
     * @param tenantId Id da Empresa
     * @param requestBody Command que contém as informações para atualizar o Professor.
     * @returns void
     * @throws ApiError
     */
    public static putApiProfessors(
        id: number,
        tenantId: number,
        requestBody?: UpdateProfessorCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/professors/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cria um novo Professor.
     * Exemplo:
     * { "email": "professor@email.com", "password": "ProfessorP@ss123", "fullName": "Professor ExamPlatform", "socialSecurityNumber": "12345678900", "birthDate": "2024-01-16T03:34:14.962Z", "phoneNumber": "11922223333", "companyId": "1" }
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo as informações do Professor para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiProfessors(
        tenantId: number,
        requestBody?: CreateProfessorCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/professors',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ativa um Professor a partir de seu Id.
     * @param id Id do Professor a ser ativado.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para ativar o Professor.
     * @returns void
     * @throws ApiError
     */
    public static putApiProfessorsActivate(
        id: number,
        tenantId: number,
        requestBody?: ActivateProfessorCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/professors/{id}/activate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa uma Professor a partir de seu Id.
     * @param id Id do Professor a ser desativado.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para desativar o Professor.
     * @returns void
     * @throws ApiError
     */
    public static putApiProfessorsDeactivate(
        id: number,
        tenantId: number,
        requestBody?: DeactivateProfessorCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/professors/{id}/deactivate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
