/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnswerExamQuestionCommand } from '../models/AnswerExamQuestionCommand';
import type { CreateExamCommand } from '../models/CreateExamCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExamsService {
    /**
     * Obtém uma prova a partir do Id.
     * @param id Id da Prova.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiExams(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/exams/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Obtém o resultado de uma prova já finalizada.
     * @param id Id da prova.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiExamsResult(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/exams/{id}/result',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Cria uma prova contendo 90 questões, sendo 45 de cada Área do Conhecimento.
     * @param tenantId Id da Empresa
     * @param requestBody { studentId = 1, knowledgeAreasIds = [1, 2] }
     * @returns any Created
     * @throws ApiError
     */
    public static postApiExams(
        tenantId: number,
        requestBody?: CreateExamCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/exams',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Inicia a questão a partir de seu Id, definindo a data e hora em que foi iniciada como a atual.
     * @param id Id da prova.
     * @param tenantId Id da Empresa
     * @returns void
     * @throws ApiError
     */
    public static postApiExamsStartExam(
        id: number,
        tenantId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/exams/{id}/startExam',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Finaliza a questão a partir de seu Id, definindo a data e hora em que foi finalizada como a atual.
     * @param id Id da prova.
     * @param tenantId Id da Empresa
     * @returns void
     * @throws ApiError
     */
    public static postApiExamsFinishExam(
        id: number,
        tenantId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/exams/{id}/finishExam',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Inicia a resolução da questão da prova, definindo a data e hora de início.
     * @param id Id da prova.
     * @param questionId Id da questão da prova (ExamQuestion).
     * @param tenantId Id da Empresa
     * @returns void
     * @throws ApiError
     */
    public static postApiExamsQuestionsStartSolvingQuestion(
        id: number,
        questionId: number,
        tenantId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/exams/{id}/questions/{questionId}/startSolvingQuestion',
            path: {
                'id': id,
                'questionId': questionId,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Define a resposta do aluno para a questão da prova e a data e hora em que foi respondida.
     * @param id Id da prova.
     * @param questionId Id da questão da prova (ExamQuestion).
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo as informações de resposta da questão.
     * @returns void
     * @throws ApiError
     */
    public static postApiExamsQuestionsAnswerQuestion(
        id: number,
        questionId: number,
        tenantId: number,
        requestBody?: AnswerExamQuestionCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/exams/{id}/questions/{questionId}/answerQuestion',
            path: {
                'id': id,
                'questionId': questionId,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
