/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateStudentCommand } from '../models/ActivateStudentCommand';
import type { CreateStudentCommand } from '../models/CreateStudentCommand';
import type { DeactivateStudentCommand } from '../models/DeactivateStudentCommand';
import type { GetExerciseQuestionsByStudentQuery } from '../models/GetExerciseQuestionsByStudentQuery';
import type { UpdateStudentCommand } from '../models/UpdateStudentCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StudentsService {
    /**
     * Obtém um Aluno pelo seu Id.
     * @param id Id do Aluno.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiStudents(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/students/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Altera as informações de um Aluno.
     * Exemplo:
     * { "fullName": "Aluno ExamPlatform", "birthDate": "2024-01-16T03:34:14.962Z", "phoneNumber": "11922223333",
     * "street": "Rua 1", "number": "101", "complement": "Quadra 1", "district": "Bairro X", "zipCode": "12345678",
     * "city": "City Y",
     * "state": "State Z", "stateAcronym": "SZ",
     * "country": "Country A", "countryAcronym": "CA" }
     * @param id Id do Aluno
     * @param tenantId Id da Empresa
     * @param requestBody Command que contém as informações para atualizar o Aluno.
     * @returns void
     * @throws ApiError
     */
    public static putApiStudents(
        id: number,
        tenantId: number,
        requestBody?: UpdateStudentCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/students/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Obtém todas as provas de um aluno a partir de seu Id.
     * @param id Id do aluno.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiStudentsExams(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/students/{id}/exams',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Obtém as métricas (Analytics) de um aluno.
     * @param id Id do Aluno.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiStudentsAnalytics(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/students/{id}/analytics',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Obtém as métricas (Analytics) de questões de exercícios de um aluno.
     * @param id Id do Aluno.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiStudentsExerciseQuestionsAnalytics(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/students/{id}/exerciseQuestionsAnalytics',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Obtém as Questões respondidas pelo aluno ou o Catalogo de Questões com base nos filtros.
     * @param id Id do aluno.
     * @param tenantId Id da Empresa
     * @param requestBody Filtros para a busca das questões.
     * @returns any Success
     * @throws ApiError
     */
    public static postApiStudentsQuestions(
        id: number,
        tenantId: number,
        requestBody?: GetExerciseQuestionsByStudentQuery,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/students/{id}/questions',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cria um novo Aluno.
     * Exemplo:
     * { "email": "aluno@email.com", "password": "AlunoP@ss123",
     * "fullName": "Aluno ExamPlatform", "socialSecurityNumber": "12345678900", "birthDate": "2024-01-16T03:34:14.962Z", "phoneNumber": "11922223333",
     * "street": "Rua 1", "number": "101", "complement": "Quadra 1", "district": "Bairro X", "zipCode": "12345678",
     * "city": "City Y",
     * "state": "State Z", "stateAcronym": "SZ",
     * "country": "Country A", "countryAcronym": "CA",
     * "companyId": "1" }
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo as informações do Aluno para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiStudents(
        tenantId: number,
        requestBody?: CreateStudentCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/students',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ativa um Aluno a partir de seu Id.
     * @param id Id do Aluno a ser ativado.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para ativar o Aluno.
     * @returns void
     * @throws ApiError
     */
    public static putApiStudentsActivate(
        id: number,
        tenantId: number,
        requestBody?: ActivateStudentCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/students/{id}/activate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa uma Aluno a partir de seu Id.
     * @param id Id do Aluno a ser desativado.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para desativar o Aluno.
     * @returns void
     * @throws ApiError
     */
    public static putApiStudentsDeactivate(
        id: number,
        tenantId: number,
        requestBody?: DeactivateStudentCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/students/{id}/deactivate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
