import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNotification } from '../../../services/NotificationContext';
import useAuth from '../../../hooks/useAuth.js';
import { ProfessorsService, UpdateProfessorCommand } from 'api-gen';
import { useDesignParams } from 'contexts/DesignParamsContext';
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

Modal.setAppElement('#root');

const AddProfessorModal = ({ isOpen, onClose, id }) => {
  const designParams = useDesignParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [socialSecurityNumber, setSocialSecurityNumber] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState(""); 
  const [birthDate, setBirthDate] = useState(new Date());
  const [errorList, setErrorList] = useState<any>(undefined);
  const { user } = useAuth();

  const { addNotification } = useNotification();

  const preencherDados = (dados) => {
    setName(dados.fullName);
    setSocialSecurityNumber(dados.socialSecurityNumber);
    setPhone(dados.phoneNumber);
    setBirthDate(new Date(dados.birthDate));
  }

  const handleCloseModal = () => {
    setEmail("");
    setPassword("");
    setSocialSecurityNumber("");
    setName("");
    setPhone(""); 
    setErrorList(undefined);

    onClose();
  }

  const getProfessor = async () => {
    if (user?.companyId) {
      const result = await ProfessorsService.getApiProfessors(id, user?.companyId);
      if (result && result?.professor) {
        preencherDados(result?.professor);
      }
    }
  }

  useEffect(() => {
    if (id) {
      getProfessor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  const handleAddProfessor = async () => {
 
    if (id) {

      const body : UpdateProfessorCommand = {
        id,
        fullName: name,
        phoneNumber: phone,
        birthDate: birthDate.toISOString(),

      }

      const errors = verifyErrorsToUpdate();
      
      if (Object.keys(errors).length > 0) {
        setErrorList(errors);
        return;
      }
      try {

        await ProfessorsService.putApiProfessors(id, user?.companyId, body);

        addNotification('Professor alterado com sucesso!', 'green');
        handleCloseModal();
      } catch (e) {
        if (e && e?.status === 400) {
          if (e?.body?.errors) {
            setErrorList(e?.body?.errors);
          } else if (e?.body?.message) {
            const generalError = e?.body?.message;
            addNotification(generalError, "red");
          }
        } else {
          addNotification('Erro ao alterar Professor!', 'red');
        }
      }
    } else {

      const body = {
        email: email,
        password: password,
        fullName: name,
        socialSecurityNumber: handleCleanCpf(socialSecurityNumber),
        birthDate: birthDate.toISOString(),
        phoneNumber: phone,
        companyId: user.companyId
      };

      var errors = verifyErrorsToAdd();

      if (Object.keys(errors).length > 0) {
        setErrorList(errors);
        return;
      }

      try {
        await ProfessorsService.postApiProfessors(user?.companyId, body);
        addNotification('Professor inserido com sucesso!', 'green');
        handleCloseModal();
      } catch (e) {
        if (e && e?.status === 400) {
          if (e?.body?.errors) {
            setErrorList(e?.body?.errors);
          } else if (e?.body?.message) {
            const generalError = e?.body?.message;
            addNotification(generalError, "red");
          }
        } else {
          addNotification('Erro ao cadastrar Professor!', 'red');
        }
      }
    }
  }

  const verifyErrorsToUpdate = () => {
    const errors: any = {};

    if (!name) {
      errors.FullName = ["Nome é obrigatório."];
    }
    if (!phone) {
      errors.PhoneNumber = ["Telefone é obrigatório."];
    }
    if (!birthDate) {
      errors.BirthDate = ["Data de nascimento é obrigatória."];
    }

    return errors;

  };

  const verifyErrorsToAdd = () => {
    const errors: any = {};

      if (!name) {
        errors.FullName = ["Nome é obrigatório."];
      }
      if (!email) {
        errors.Email = ["Email é obrigatório."];
      }
      if (!password) {
        errors.Password = ["Senha é obrigatória."];
      }
      if (!phone) {
        errors.PhoneNumber = ["Telefone é obrigatório."];
      }
      if (!socialSecurityNumber) {
        errors.SocialSecurityNumber = ["CPF é obrigatório."];
      }
      if (!birthDate) {
        errors.BirthDate = ["Data de nascimento é obrigatória."];
      }

      return errors;
  }

  const handleCleanCpf = (value) => {
    return value.replace(/\D/g, '');
  };

  const groupErrorStrings = (object) => {
    let errors = [];
    for (let key in object) {
      if (Array.isArray(object[key]) && object[key].every(item => typeof item === 'string')) {
        errors.push(object[key].join(""));
      }
    }
    return errors.join(" ");
  }
  console.log("errorList", errorList)

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{id ? "Editar Professor" : "Adicionar Professor"}</DialogTitle>
      <DialogContent >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem',marginTop: '1rem' }}>
        <TextField
          label={"Nome"}
          type="text"
          placeholder="Digite o nome"
          value={name}
          onChange={(e) => setName(e.target.value)} 
        />
        {
          !id &&
          <TextField
          label={"Email do professor"}
          type="email"
          placeholder="Digite o email"
          value={email}
          onChange={(e) => setEmail(e.target.value)} 
        />
        }
        {
          !id &&
           <TextField
            label={"Senha"}
            type="password"
            placeholder="Digite a senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)} 
          />
        }
       

        <TextField
          label={"Telefone"}
          type="text"
          placeholder="Telefone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)} 
        />

        <TextField
          label={"CPF"}
          placeholder="Digite o CPF"
          value={socialSecurityNumber}
          onChange={(e) => setSocialSecurityNumber(e.target.value)}
          disabled={id ? true : false}
          InputProps={{
            inputComponent: InputMask,
            inputProps: {
              mask: "999.999.999-99",
            },
          }}

        />

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DatePicker
            label="Data de nascimento"
            value={dayjs(birthDate)}
            onChange={(date) => setBirthDate(date ? date.toDate() : undefined)}
          />
        </LocalizationProvider>
        </Box>
      </DialogContent>

      {errorList  &&
        <Alert severity="error">
          {groupErrorStrings(errorList)}
        </Alert>
      }

      <DialogContent style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button
          variant='contained'
          sx={{ backgroundColor: designParams?.secondaryColorHex || 'red' }}
          onClick={handleCloseModal}>
          Cancelar
        </Button>
        <Button
          variant='contained'
          sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
          onClick={handleAddProfessor}
        >
          {id ? "Alterar" : "Adicionar"}
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default AddProfessorModal;