import React, { useEffect, useState } from 'react';
import { Container, Content, Header, Table, TableContainer, Td, Th, Title } from './styles.js'
import AddAreaModal from './Components/AddModal';
import Menu from "../../components/Header/index.jsx"
import { useNotification } from '../../services/NotificationContext';
import useAuth from '../../hooks/useAuth.js';
import { CompaniesService } from 'api-gen';
import { useDesignParams } from 'contexts/DesignParamsContext';
import { Button } from '@mui/material';


const Questions = () => {

    const designParams = useDesignParams();
    const { addNotification } = useNotification();
    const { user } = useAuth();

    const [questions, setQuestions] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [questionId, setQuestionId] = useState(null);


    const handleOpenModal = () => {
        setModalIsOpen(true);
    }

    const handleCloseModal = () => {
        setQuestionId(null);
        setModalIsOpen(false);
        getQuestions();
    }

    const getQuestions = async () => {

        try {
            if (user?.companyId) {
                const result = await CompaniesService.getApiCompaniesQuestions(user.companyId);
                if (result && result?.questions) { 
                    setQuestions(result.questions); 
                }
            }
        }
        catch (e) {
            addNotification('Erro ao recuperar questões!', 'red'); 
        }

    };

    useEffect(() => {
        if (user && user.companyId) {
            getQuestions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleEdit = (id) => {
        // Lógica para editar a empresa com o ID fornecido
        setQuestionId(id);
    };

    useEffect(() => {
        if (questionId) {
            handleOpenModal();
        }
    }, [questionId])

    const handleDisable = (item) => {
        // Lógica para desativar a empresa com o ID fornecido
        console.log("Desativar empresa com ID:", item);
    };

    return (
        <>
            <Menu />
            <Container>
                <Content>
                    <Header>
                        <Title>Questões</Title>
                        <Button
                          variant='contained'
                          sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
                          onClick={handleOpenModal}
                        >
                            Adicionar
                        </Button>
                    </Header>
                    <TableContainer>
                        <Table>
                            <thead>
                                <tr>
                                    <Th>Questão</Th>
                                    <Th>Área</Th>
                                    <Th>Editar</Th>
                                    <Th>Desativar</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {questions && questions.length > 0 && questions.map((question) => (
                                    <tr key={question.id}>
                                        <Td> <div dangerouslySetInnerHTML={{ __html: question.content }} /></Td>
                                        <Td>{question.knowledgeArea.acronym}</Td>
                                        <Td>
                                            <Button
                                             variant='contained'
                                             sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
                                             onClick={() => handleEdit(question.id)}
                                            >
                                                Editar
                                            </Button>
                                        </Td>
                                        <Td>
                                            <Button
                                            variant='contained'
                                            sx={{ backgroundColor: designParams?.secondaryColorHex || 'red' }}
                                            onClick={() => handleDisable(question)}
                                            >
                                                Desativar
                                            </Button>
                                        </Td>
                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </TableContainer>
                </Content>



            </Container>
            <AddAreaModal
                isOpen={modalIsOpen}
                onClose={handleCloseModal}
                id={questionId}
            // Passar os setters como propriedades para o modal
            />
        </>

    );
}

export default Questions;
