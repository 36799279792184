import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Label } from '../../Signin/styles.js';
import Input from '../../../components/Input/index.js';
import { FaTimes } from 'react-icons/fa';
import { Container, Content } from './AddModalStyles.js';
import { useNotification } from '../../../services/NotificationContext';
import useAuth from '../../../hooks/useAuth.js';
import { KnowledgeAreasService } from 'api-gen';
import { useDesignParams } from 'contexts/DesignParamsContext';
import { Button } from '@mui/material';

Modal.setAppElement('#root');

const AddKnowledgeAreasModal = ({ isOpen, onClose, id }) => {

  const designParams = useDesignParams();
  
  const [description, setDescription] = useState("");
  const [acronym, setAcronym] = useState("");
  const [error, setError] = useState("");
  const { user } = useAuth();

  const { addNotification } = useNotification();


  const preencherDados = (dados) => {
    setDescription(dados.description);
    setAcronym(dados.acronym);
  }

  const getKnowledgeAreas = async () => {
    if(user?.companyId){
      const result = await KnowledgeAreasService.getApiKnowledgeAreas(id, user?.companyId);
      if (result) {
        preencherDados(result.knowledgeArea);
      }
    }
  }

  const handleCloseModal = () => {
    setDescription("");
    setAcronym("");
    setError("");
    onClose();  
  }

  useEffect(() => {
    if (id) {
      getKnowledgeAreas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  const handleAddArea = async () => {

    setError("");
    if (id) {

      const body = {
        id,
        description,
        acronym
      }

      try{
        await KnowledgeAreasService.putApiKnowledgeAreas(id,user?.companyId,body);
        addNotification('Área do Conhecimento alterada com sucesso!', 'green');
        handleCloseModal();
      } catch (e) {
        addNotification('Erro ao alterar Área do Conhecimento!', 'red');
      }
 
    } else {

      const body = {
        companyId: user.companyId,
        description,
        acronym
      }

      try {
        await KnowledgeAreasService.postApiKnowledgeAreas(user?.companyId,body); 
        addNotification('Área do Conhecimento inserida com sucesso!', 'green');
        handleCloseModal();
      } catch (e) {
        addNotification('Erro ao cadastrar Área do Conhecimento!', 'red');
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Adicionar Área do Conhecimento"
      appElement={document.getElementById('root')}
      style={{
        content: {
          width: '50%', // Defina o tamanho desejado
          height: '35%', // Defina a altura desejada
          margin: 'auto', // Centralize horizontalmente
          background: '#fff', // Cor de fundo do modal
          border: '2px solid #ccc', // Borda do modal
          borderRadius: '8px', // Bordas arredondadas
          padding: '20px' // Espaçamento interno
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)' // Cor do fundo ao redor do modal
        }
      }}
    >
      <Container>
        <Label>{id ? "Editar Área do Conhecimento" : "Adicionar Área do Conhecimento"}</Label>
        <FaTimes onClick={handleCloseModal} style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }} />
        <Content>
          <Input
            label={"Descrição"}
            type="text"
            placeholder="Digite a descrição"
            value={description}
            onChange={(e) => [setDescription(e.target.value), setError("")]}
          />

          <Input
            label={"Sigla"}
            type="text"
            placeholder="Digite a sigla"
            value={acronym}
            onChange={(e) => [setAcronym(e.target.value), setError("")]}
          />

          {error &&
            <label color='red'>{error}</label>
          }

          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            <Button
              variant='contained'
              sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
              onClick={handleAddArea} 
            >
              {id ? "Alterar" : "Adicionar"}
            </Button>

            <Button
              variant='contained'
              sx={{ backgroundColor: designParams?.secondaryColorHex || 'red' }}
              onClick={handleCloseModal}
            > 
              Cancelar
            </Button>

          </div>

        </Content>
      </Container>
    </Modal>
  );
}

export default AddKnowledgeAreasModal;