export function generateRandomCNPJ() {
    const randomiza = (n) => Math.floor(Math.random() * n);
    const randomNumber = (length) => {
        let result = '';
        for (let i = 0; i < length; i++) {
            result += randomiza(10);
        }
        return result;
    };

    const generateCNPJ = () => {
        const numbers = randomNumber(12);
        const baseCnpj = numbers.split('');
        let sum = 0;
        let multiply = 5;
        for (let i = 0; i < 12; i++) {
            sum += Number(baseCnpj[i]) * multiply--;
            if (multiply < 2) {
                multiply = 9;
            }
        }
        let checkDigit = 11 - (sum % 11);
        checkDigit = checkDigit > 9 ? 0 : checkDigit;
        baseCnpj.push(checkDigit);
        sum = 0;
        multiply = 6;
        for (let i = 0; i < 13; i++) {
            sum += Number(baseCnpj[i]) * multiply--;
            if (multiply < 2) {
                multiply = 9;
            }
        }
        checkDigit = 11 - (sum % 11);
        checkDigit = checkDigit > 9 ? 0 : checkDigit;
        baseCnpj.push(checkDigit);
        return baseCnpj.join('');
    };

    return generateCNPJ();
}


export function verificarParametrosVazios(...params) {
    const emptyParams = [];
    params.forEach((param) => {
        for (const key in param) {
            if (Object.prototype.hasOwnProperty.call(param, key)) {
                if (Array.isArray(param[key])) {
                    const hasEmptyValue = param[key].some(item => item === null || item === '');
                    if (hasEmptyValue) {
                        emptyParams.push(`${param['name']}`);
                    }
                } else if (param[key] === null || param[key] === '') {
                    emptyParams.push(`${param['name']}`);
                }
            }
        }
    });


    return emptyParams.length > 0 ? `Os seguintes dados são obrigatórios: ${emptyParams.join(', ')}` : null;
}