/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateTopicCommand } from '../models/ActivateTopicCommand';
import type { CreateTopicCommand } from '../models/CreateTopicCommand';
import type { DeactivateTopicCommand } from '../models/DeactivateTopicCommand';
import type { UpdateTopicCommand } from '../models/UpdateTopicCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TopicsService {
    /**
     * Obtém um Conteúdo pelo Id.
     * @param id Id do Conteúdo.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiTopics(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/topics/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Altera as informações de um Conteúdo.
     * Exemplo:
     * { "description": "Equação do 2º grau", "recurrencePercentage": 12.5 "subjectId": 1 }
     * @param id Id do Conteúdo.
     * @param tenantId Id da Empresa
     * @param requestBody Command que contém as informações para atualizar o Conteúdo.
     * @returns void
     * @throws ApiError
     */
    public static putApiTopics(
        id: number,
        tenantId: number,
        requestBody?: UpdateTopicCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/topics/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cria um novo Conteúdo.
     * Exemplo:
     * { "description": "Equação do 1º grau", "recurrencePercentage": 12.5, "subjectId": 1, "companyId": 1 }
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo as informações do Conteúdo para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiTopics(
        tenantId: number,
        requestBody?: CreateTopicCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/topics',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ativa um Conteúdo a partir de seu Id.
     * @param id Id do Conteúdo a ser ativado.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para ativar o Conteúdo.
     * @returns void
     * @throws ApiError
     */
    public static putApiTopicsActivate(
        id: number,
        tenantId: number,
        requestBody?: ActivateTopicCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/topics/{id}/activate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa um Conteúdo a partir de seu Id.
     * @param id Id do Conteúdo a ser desativado.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para desativar o Conteúdo.
     * @returns void
     * @throws ApiError
     */
    public static putApiTopicsDeactivate(
        id: number,
        tenantId: number,
        requestBody?: DeactivateTopicCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/topics/{id}/deactivate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
