import React, { useEffect, useState } from 'react';
import { Container, Content, Header, Table, TableContainer, Td, Th, Title } from './styles.js' 
import AddAlunoModal from './Components/AddModal';
import Menu from "../../components/Header/index.jsx"
import useAuth from '../../hooks/useAuth.js';
import { CompaniesService } from 'api-gen';
import { Button } from '@mui/material';
import { useDesignParams } from 'contexts/DesignParamsContext';


const Alunos = () => {
    const { user } = useAuth();
    const designParams = useDesignParams();

    const [alunos, setAlunos] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [alunoId, setAlunoId] = useState(null)
    const handleOpenModal = () => {
        setModalIsOpen(true);
    }

    const handleCloseModal = () => {
        setAlunoId(null);
        setModalIsOpen(false);
        getStudents();
    }

    const getStudents = async () => {

        try {
            if(user?.companyId) 
            {
                const result = await CompaniesService.getApiCompaniesStudents(user.companyId);
                if (result) {
                    console.log("empresas:", result);
                    if (result.students && result.students.length > 0) {
                        setAlunos(result.students);
                    } 
                }
            }
        }
        catch (e) {
            console.log("deuerro:", e);
        }
    };

    useEffect(() => { 
            getStudents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const handleEdit = (id) => {
        // Lógica para editar a empresa com o ID fornecido
        setAlunoId(id);
    };

    useEffect(() => {
        if (alunoId) {
            handleOpenModal();
        }
    }, [alunoId])

    const handleDisable = (id) => {
        // Lógica para desativar a empresa com o ID fornecido
        console.log("Desativar empresa com ID:", id);
    };

    return (
        <>
            <Menu />
            <Container>
                <Content>
                    <Header>
                        <Title>Alunos</Title>
                        <Button
                            variant='contained'
                            sx={{ backgroundColor: designParams?.primaryColorHex || ''}} 
                            onClick={handleOpenModal}>
                                Adicionar
                        </Button>
                    </Header>
                    <TableContainer>
                        <Table>
                            <thead>
                                <tr>
                                    <Th>Nome</Th>
                                    {/* <Th>Rua</Th>
                                    <Th>Número</Th>
                                    <Th>Complemento</Th>
                                    <Th>Bairro</Th>
                                    <Th>CEP</Th>
                                    <Th>Cidade</Th>
                                    <Th>Estado</Th> */}
                                    <Th>Editar</Th>
                                    <Th>Desativar</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {alunos && alunos.length > 0 && alunos.map((aluno) => (
                                    <tr key={aluno.id}>
                                        <Td>{aluno.fullName}</Td>
                                        {/* <Td>{empresa.address.street}</Td>
                                        <Td>{empresa.address.number}</Td>
                                        <Td>{empresa.address.complement}</Td>
                                        <Td>{empresa.address.district}</Td>
                                        <Td>{empresa.address.city.state.country.name}</Td>
                                        <Td>{empresa.address.zipCode}</Td>
                                        <Td>{empresa.address.city.name}</Td> */}
                                        <Td>
                                            <Button
                                                variant='contained'
                                                sx={{ backgroundColor: designParams?.primaryColorHex || ''}} 
                                                onClick={() => handleEdit(aluno.id)}
                                            >
                                                    Editar
                                            </Button>
                                        </Td>
                                        <Td>
                                            <Button
                                                variant='contained'
                                                sx={{ backgroundColor: designParams?.secondaryColorHex || 'red'}}
                                                onClick={() => handleDisable(aluno.id)}
                                            >
                                                Desativar
                                            </Button>
                                        </Td>
                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </TableContainer>
                </Content>



            </Container>
            <AddAlunoModal
                isOpen={modalIsOpen}
                onClose={handleCloseModal}
                id={alunoId}
            // Passar os setters como propriedades para o modal
            />
        </>

    );
}

export default Alunos;
