import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface ConfirmDeleteModalProps {
    open: boolean;
    onClose: () => void;
    onDelete: (id : number) => void;
    id : number;
    principalLabel : string;
    secondLabel : string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
    open,
    onClose,
    onDelete,
    id,
    principalLabel,
    secondLabel
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirmar exclusão</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {principalLabel}<br />
                    {secondLabel}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => {onDelete(id)}} color="primary" autoFocus>
                    Excluir
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteModal;