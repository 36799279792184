import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import InputMask from 'react-input-mask';
import { useNotification } from '../../../services/NotificationContext';
import { verificarParametrosVazios } from '../../../util/utils.js';
import { CompaniesService, CreateCompanyCommand } from 'api-gen';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
Modal.setAppElement('#root');

const AddEmpresaModal = ({ isOpen, onClose, id }) => {

  const { addNotification } = useNotification();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [corporateNumber, setCorporateNumber] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [tradingName, setTradingName] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [district, setDistrict] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [stateAcronym, setStateAcronym] = useState("");
  const [country, setCountry] = useState("");
  const [countryAcronym, setCountryAcronym] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const preencherDados = (dados) => {

    setCorporateName(dados.corporateName);
    setCorporateNumber(dados.corporateNumber);

    const { address } = dados;
 
    setTradingName(dados.tradingName);
    setStreet(address.street);
    setNumber(address.number);
    setComplement(address.complement);
    setDistrict(address.district);
    setZipCode(address.zipCode);
    setCity(address.city.name);
    setState(address.city.state.name);
    setStateAcronym(address.city.state.acronym);
    setCountry(address.city.state.country.name);
    setCountryAcronym(address.city.state.country.acronym);
    setEmail(dados.email);
    setPassword(dados.password);
  }

  const getEmpresaById = async () => {

    const result = await CompaniesService.getApiCompanies(id);
    if (result) {
      preencherDados(result.company);
    }
  }

  useEffect(() => {
    if (id) {
      getEmpresaById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  const resetValues = () => {
    setCity('');
    setError('');
    setComplement('');
    setPassword('');
    setTradingName('');
    setCorporateName('');
    setCountry('');
    setCountryAcronym('');
    setEmail('');
    setDistrict('');
    setZipCode('');
    setCorporateNumber('');
    setStreet('');
    setNumber('');
    setState('');
    onClose();

  }

  const handleAddEmpresa = async () => {
    setIsLoading(true);
    const verify = verificarParametrosVazios(
      { name: 'Razão social', value: corporateName },
      { name: 'Email da empresa', value: email },
      { name: 'Senha', value: password },
      { name: 'Nome Fantasia', value: tradingName },
      { name: 'Cnpj', value: corporateNumber },
      { name: 'Rua', value: street },
      { name: 'Número', value: number },
      { name: 'Complemento', value: complement },
      { name: 'Bairro', value: district },
      { name: 'CEP', value: zipCode },
      { name: 'Cidade', value: city },
      { name: 'Estado', value: state },
    );

    if (!verify) {
      setError('');
      if (id) {

        const body = {
          id,
          tradingName,
          street,
          number,
          complement,
          district,
          zipCode: handleCleanCep(zipCode),
          city,
          state,
          stateAcronym,
          country,
          countryAcronym,
        }
        try{
          await CompaniesService.putApiCompanies(id, body);
          addNotification('Empresa alterada com sucesso!', 'green');
          resetValues();
        }catch (e) {
          if (e && e?.status === 400) {
            if (e?.body?.errors) {
              setError(groupErrorStrings(e?.body?.errors));
            } else if (e?.body?.message) {
              const generalError = e?.body?.message;
              setError(generalError);
            }
          } else
            addNotification(`Erro ao editar empresa! `, 'red');
        } 
      } else {

        const body: CreateCompanyCommand = {
          corporateName,
          corporateNumber: handleCleanCnpj(corporateNumber),
          email,
          password,
          tradingName,
          street,
          number,
          complement,
          district,
          zipCode: handleCleanCep(zipCode),
          city,
          state,
          stateAcronym,
          country,
          countryAcronym,

        }
        try {
          await CompaniesService.postApiCompanies(body);
          addNotification('Empresa criada com sucesso!', 'green');
          resetValues();
        } catch (e) {
          if (e && e?.status === 400) {
            if (e?.body?.errors) {
              setError(groupErrorStrings(e?.body?.errors));
            } else if (e?.body?.message) {
              const generalError = e?.body?.message;
              setError(generalError);
            }
          } else
            addNotification(`Erro ao criar empresa! `, 'red');
        }
      }
    }
    else {
      setError(verify);
    }
    setIsLoading(false);
  }
  const handleCleanCnpj = (cnpj: string): string => {
    return cnpj.replace(/[^\d]/g, '');
  }

  const handleCleanCep = (cep: string): string => {
    return cep.replace(/[^\d]/g, '');
  }

  const groupErrorStrings = (object) => {
    let errors = [];
    for (let key in object) {
      if (Array.isArray(object[key]) && object[key].every(item => typeof item === 'string')) {
        errors.push(object[key].join(","));
      }
    }
    return errors.join(",");
  }

  return (
    <Dialog
      open={isOpen}
      onClose={resetValues}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>{id ? "Editar Empresa" : "Adicionar Empresa"}</DialogTitle>
      {
        error &&
        <Alert severity='error'>{error}</Alert>
      }
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1rem', marginTop: '1rem' }}>
          <TextField
            label={"Razão Social"}
            type="text"
            placeholder="Digite a razão social"
            value={corporateName}
            onChange={(e) => [setCorporateName(e.target.value), setError("")]}
            disabled={id ? true : false}
          />
          {
            !id &&
            <>
              <TextField
                label={"Email da Empresa"}
                type="email"
                placeholder="Digite o email"
                value={email}
                onChange={(e) => [setEmail(e.target.value), setError("")]}
                disabled={id ? true : false}
              />

              <TextField
                label={"Senha"}
                type="password"
                placeholder="Digite a senha"
                value={password}
                onChange={(e) => [setPassword(e.target.value), setError("")]}
                disabled={id ? true : false}
              />
            </>
          }
          <TextField
            label={"Nome Fantasia"}
            type="text"
            placeholder="Digite o nome fantasia"
            value={tradingName}
            onChange={(e) => [setTradingName(e.target.value), setError("")]}
          />

          <TextField
            label={"CNPJ"}
            placeholder="Digite o CNPJ"
            value={corporateNumber}
            onChange={(e) => setCorporateNumber(e.target.value)}
            disabled={id ? true : false}
            InputProps={{
              inputComponent: InputMask,
              inputProps: {
                mask: "99.999.999/9999-99",
              },
            }}
          />

          <TextField
            label={"Rua"}
            placeholder="Digite a rua"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />

          <TextField
            label={"Número"}
            placeholder="Digite o número"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />

          <TextField
            label={"Complemento"}
            placeholder="Digite o complemento"
            value={complement}
            onChange={(e) => setComplement(e.target.value)}
          />

          <TextField
            label={"Bairro"}
            placeholder="Digite o bairro"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
          />

          <TextField
            label={"Cep"}
            placeholder="Digite o CEP"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            InputProps={{
              inputComponent: InputMask,
              inputProps: {
                mask: "99999-999",
              },
            }}
          />

          <TextField
            label={"Cidade"}
            placeholder="Digite a cidade"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />

          <TextField
            label={"Estado"}
            placeholder="Digite o estado"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '1rem' }}>
        <Button onClick={resetValues} variant='contained' color='error'>Cancelar</Button>
        <LoadingButton
          onClick={handleAddEmpresa}
          variant='contained'
          loading={isLoading}
        >
          {id ? "Alterar" : "Adicionar"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddEmpresaModal;