import styled from "styled-components";
import DatePicker from 'react-datepicker';



export const Label = styled.label`
  font-size: 14px;
  color: ${({ color }) => (color ? color : "#333")} ;
  margin-bottom: 5px;
  display: ${({ show }) => (show ? "block" : "none")};
`;
export const ErrorParagraph = styled.p`
  font-size: 14px;
  color: #f00;
  margin: 5px;
  display: ${({ show }) => (show ? "block" : "none")};
`;


export const Input = styled.input`
  outline: none;
  padding: 16px 20px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  background-color: ${({ disabled }) => (disabled ? "#f0f2f5;" : "#E0EBF1")};
  border: none;
`;

export const CustomDatePickerInput = styled(DatePicker)`
  width: 193%;
  outline: none;
  padding: 16px 20px;
  border-radius: 5px;
  font-size: 16px;
  background-color: ${({ disabled }) => (disabled ? "#f0f2f5;" : "#E0EBF1")}; 
  border: none;
`;
