/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateUserCommand } from '../models/ActivateUserCommand';
import type { ChangePasswordCommand } from '../models/ChangePasswordCommand';
import type { CreateUserCommand } from '../models/CreateUserCommand';
import type { DeactivateUserCommand } from '../models/DeactivateUserCommand';
import type { ForgotPasswordCommand } from '../models/ForgotPasswordCommand';
import type { LoginUserCommand } from '../models/LoginUserCommand';
import type { UnitResultApiResponse } from '../models/UnitResultApiResponse';
import type { UpdateUserPasswordCommand } from '../models/UpdateUserPasswordCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
    /**
     * Obtém um usuário pelo seu Id.
     * @param id Id do usuário.
     * @returns any Success
     * @throws ApiError
     */
    public static getApiUsers(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Altera a senha do usuário.
     * Exemplo:
     * { "Id": 1, "password": "NewP@ss123" }
     * @param id Id do usuário
     * @param requestBody Command que contém as informações para alterar a senha.
     * @returns void
     * @throws ApiError
     */
    public static putApiUsers(
        id: number,
        requestBody?: UpdateUserPasswordCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cria um novo admin.
     * Exemplo:
     * { "name": "ExamPlatform Admin", "email": "admin@email.com", "password": "AdminP@ss123" }
     * @param requestBody O command contendo as informações do usuário para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiUsers(
        requestBody?: CreateUserCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Realiza o login de um usuário através do e-mail e senha.
     * Exemplo:
     * { "email": "examplatform@email.com", "password": "MyP@ss123" }
     * @param requestBody Command que contém as credenciais de login.
     * @returns any Success
     * @throws ApiError
     */
    public static putApiUsersLogin(
        requestBody?: LoginUserCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns UnitResultApiResponse Success
     * @throws ApiError
     */
    public static putApiUsersChangePassword(
        requestBody?: ChangePasswordCommand,
    ): CancelablePromise<UnitResultApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/change-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns UnitResultApiResponse Success
     * @throws ApiError
     */
    public static postApiUsersForgotPassword(
        requestBody?: ForgotPasswordCommand,
    ): CancelablePromise<UnitResultApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Ativa um usuário a partir de seu Id.
     * @param id Id do usuário a ser ativado.
     * @param requestBody O command contendo os detalhes para ativar o usuário.
     * @returns void
     * @throws ApiError
     */
    public static putApiUsersActivate(
        id: number,
        requestBody?: ActivateUserCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/{id}/activate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa um usuário a partir de seu Id.
     * @param id Id do usuário a ser desativado.
     * @param requestBody O command contendo os detalhes para desativar o usuário.
     * @returns void
     * @throws ApiError
     */
    public static putApiUsersDeactivate(
        id: number,
        requestBody?: DeactivateUserCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/{id}/deactivate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
