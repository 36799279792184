// colors.js

export const primaryColor = '#3498db';
export const secondaryColor = '#2ecc71';
export const borderColor = '#333';
export const textColor = '#555';

export const defaultGolden = "#FFD700";
export const golden2 = "#FFD710";
export const golden3 = "#cca508";

export const defaultGray = "#676767";
export const darkestGray = "#383838";