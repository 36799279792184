import { Api } from "./api";


const getAllCompanies = async () => {
    try {
        const { data } = await Api.get('/companies');

        if (data) {
            return data;
        }

        return new Error('Erro ao recuperar empresas.');
    } catch (error) {
        console.error(error);
        return error.response.data.message || "Erro recuperar empresas";
    }
};

export const getCompany = async (id) => {
    try {
        const { data } = await Api.get(`/companies/${id}`);

        if (data) {
            return data;
        }

        return new Error('Erro recuperar empresa por id.');
    } catch (error) {
        console.error(error);
        return error.response.data.message || "Erro recuperar empresa por id";
    }
};

const createCompany = async (body) => {
    try {
        console.log('body:', body);
        const data = await Api.post('/companies', body);
        console.log('data:', data);
        if (data) {
            return data;
        }

        return new Error('Erro ao criar empresa.');
    } catch (error) {
        console.error(error);
        return error.response.data.message || "Erro ao criar empresa";
    }
};

const updateCompany = async (id, body) => {
    try {
        const result = await Api.put(`/companies/${id}`, body);

        if (result) {
            return result;
        }

        return new Error('Erro ao atualizar empresa.');
    } catch (error) {
        console.error(error);
        return error.response.data.message || "Erro ao atualizar empresa";
    }
};

export const CompanyService = {
    getAllCompanies,
    createCompany,
    getCompany,
    updateCompany
};
