/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateKnowledgeAreaCommand } from '../models/ActivateKnowledgeAreaCommand';
import type { CreateKnowledgeAreaCommand } from '../models/CreateKnowledgeAreaCommand';
import type { DeactivateKnowledgeAreaCommand } from '../models/DeactivateKnowledgeAreaCommand';
import type { UpdateKnowledgeAreaCommand } from '../models/UpdateKnowledgeAreaCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class KnowledgeAreasService {
    /**
     * Obtém uma Área do Conhecimento pelo Id.
     * @param id Id da Área do Conhecimento.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiKnowledgeAreas(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/knowledgeAreas/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Altera as informações de uma Área do Conhecimento.
     * Exemplo:
     * { "description": "Matemática e suas Tecnologias 2", "acronym": "MTM2" }
     * @param id Id da Área do Conhecimento
     * @param tenantId Id da Empresa
     * @param requestBody Command que contém as informações para atualizar a Área do Conhecimento.
     * @returns void
     * @throws ApiError
     */
    public static putApiKnowledgeAreas(
        id: number,
        tenantId: number,
        requestBody?: UpdateKnowledgeAreaCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/knowledgeAreas/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Obtém todas as Disciplinas de uma Área do Conhecimento.
     * @param id
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiKnowledgeAreasSubjects(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/knowledgeAreas/{id}/subjects',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Obtém todos os Conteúdos de uma Área do Conhecimento.
     * @param id
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiKnowledgeAreasTopics(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/knowledgeAreas/{id}/topics',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Cria uma nova Área do Conhecimento.
     * Exemplo:
     * { "description": "Matemática e suas Tecnologias", "acronym": "MTM", "companyId": 1 }
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo as informações da Área do Conhecimento para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiKnowledgeAreas(
        tenantId: number,
        requestBody?: CreateKnowledgeAreaCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/knowledgeAreas',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ativa uma Área do Conhecimento a partir de seu Id.
     * @param id Id da Área do Conhecimento a ser ativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para ativar a Área do Conhecimento.
     * @returns void
     * @throws ApiError
     */
    public static putApiKnowledgeAreasActivate(
        id: number,
        tenantId: number,
        requestBody?: ActivateKnowledgeAreaCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/knowledgeAreas/{id}/activate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa uma Área do Conhecimento a partir de seu Id.
     * @param id Id da Área do Conhecimento a ser desativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para desativar a Área do Conhecimento.
     * @returns void
     * @throws ApiError
     */
    public static putApiKnowledgeAreasDeactivate(
        id: number,
        tenantId: number,
        requestBody?: DeactivateKnowledgeAreaCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/knowledgeAreas/{id}/deactivate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
