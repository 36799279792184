import React, { createContext, useState, useContext} from 'react';

const DesignParamsContext = createContext(null);

export const useDesignParams = () => useContext(DesignParamsContext);

export interface DesignParams  {
    primaryColorHex : string;
    secondaryColorHex : string;
    logo : any;
}

export const DesignParamsProvider: React.FC = ({ children }: any) => {
    const logobizu = require('../assets/bizu/logobizu.png');
    const logosinapse = require('../assets/sinapse/logosinapse.png'); 
 
    const getInitialDesignParams =  (url: string) => {
        if (url.includes("bizudoengenheiro")) { 
            return {
                primaryColorHex: "#122333",
                secondaryColorHex: "#df9b28",
                logo: logobizu
            };
        } else if (url.includes("sinapse") || url.includes("gabariteiro")) { 
            return {
                primaryColorHex: "#081238",
                secondaryColorHex: "#006da7",
                logo: logosinapse
            };
        } else { 
            return {
                primaryColorHex: "#081238",
                secondaryColorHex: "#006da7",
                logo: logosinapse
            };
        }
    };
    //eslint-disable-next-line
    const [designParams, setDesignParams] = useState<Partial<DesignParams>>(getInitialDesignParams(window.location.href));

    return (
        <DesignParamsContext.Provider value={designParams}>
            {children}
        </DesignParamsContext.Provider>
    );
};