import React, { useEffect, useState } from 'react';
import { Label, StyledSelect, SearchInput } from './styles';

const SelectList = ({ label, options, onSelect, defaultValue, multiple = false, enableFilter }) => {
    const [selectedOptions, setSelectedOptions] = useState(defaultValue || []);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);

    useEffect(() => {
        setSelectedOptions(defaultValue || []);
    }, [defaultValue]);

    useEffect(() => {
        const filtered = options.filter(option =>
            option.label && option.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOptions(filtered);
    }, [searchTerm, options]);

    useEffect(() => {
        // Add "Selecione" option if defaultValue is null
        if (defaultValue === null) {
            setSelectedOptions([]);
        }
    }, [defaultValue]);

    const handleSelectChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, option => parseInt(option.value));
        setSelectedOptions(selectedValues);
        onSelect(multiple ? selectedValues : parseInt(selectedValues));
    };

    return (
        <div>
            <Label show={label ? true : false}>{label}:</Label>
            {enableFilter && (
                <SearchInput
                    type="text"
                    placeholder="Buscar..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            )}
            <StyledSelect
                multiple={multiple}
                value={selectedOptions}
                onChange={handleSelectChange}
            >
                {defaultValue === null && (
                    <option key="default" value="">
                        ---- Selecione uma opção ----
                    </option>
                )}
                {filteredOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </StyledSelect>
        </div>
    );
}

export default SelectList;
