import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { AnswerExerciseQuestionCommand, AnswerQuestionFromSomeTopicsAgainCommand, ExerciseQuestionsService, GetQuestionsFromNextTopicCommand, LearningPathLevelsViewModel, LearningPathService, LearningPathViewModel, QuestionsFromLearningPathLevelViewModel, QuestionViewModel, TopicViewModel } from "api-gen";
import React from "react";
import { useNotification } from "services/NotificationContext";
import useAuth from '../../../hooks/useAuth.js';
import ConfirmRestartQuestionsLearningPathLevelModal from "./ConfirmRestartQuestionsLearningPathLevelModal";

interface AnswerQuestionsComponentProps {
    learningPathLevel: LearningPathLevelsViewModel;
    learningPath: LearningPathViewModel;
    questions: QuestionsFromLearningPathLevelViewModel;
    notMasterizedTopics: TopicViewModel[];
    oncClose: () => void;
};

type QuestionExercise = QuestionViewModel & {
    selectedAlternative: number;
    startTime: string;
    endTime: string;
};
const AnswerQuestionsComponent = (props: AnswerQuestionsComponentProps) => {
    const [questions, setQuestions] = React.useState(props.questions.questions);
    const inicialFailedInTopic = props.notMasterizedTopics.length > 0 && props.learningPathLevel?.learningPathLevelTopicSequences.map(t => t.topicId).some(t => props.notMasterizedTopics.map(x => x.id).includes(t));
    const [failedInTopic, setFailedInTopic] = React.useState<boolean>(inicialFailedInTopic);
    const inicialCurrentQuestion = props.questions.questions?.length > 0 ? questions[0] as QuestionExercise : undefined;
    const [currentQuestion, setCurrentQuestion] = React.useState<QuestionExercise>(inicialCurrentQuestion);
    const [isLoadingAnswerQuestion, setIsLoadingAnswerQuestion] = React.useState(false);
    const [questionsAnsweredNow, setQuestionsAnsweredNow] = React.useState<any>();
    const [isAnsweredQuestion, setIsAnsweredQuestion] = React.useState(false);
    const [isLoadingDeletePendingQuestions, setIsLoadingDeletePendingQuestions] = React.useState(false);
    const inicialTopicsNotMasterized = props.notMasterizedTopics?.filter(t => props.learningPathLevel?.learningPathLevelTopicSequences.map(t => t.topicId).includes(t.id));
    const [topicsNotMasterized, setTopicsNotMasterized] = React.useState<TopicViewModel[]>(inicialTopicsNotMasterized);
    const [noMoreQuestions, setNoMoreQuestions] = React.useState<boolean>(false);
    const [isLoadingNextQuestion, setIsLoadingNextQuestion] = React.useState<boolean>(false);
    const { addNotification } = useNotification();
    const { user } = useAuth();

    const handleSelectAlternativeCurrentQuestion = (alternativeId) => {
        setCurrentQuestion({
            ...currentQuestion,
            selectedAlternative: alternativeId
        });
    };

    const handleAnswerQuestion = async () => {
        setIsLoadingAnswerQuestion(true);
        try {
            const body: AnswerExerciseQuestionCommand = {
                questionId: currentQuestion?.id,
                selectedAlternativeId: currentQuestion?.selectedAlternative,
                startTime: currentQuestion?.startTime,
                endTime: localISOTime(new Date()),
                studentId: user?.studentId,
                isAnsweredByLearningPath: true
            };
            const response = await ExerciseQuestionsService.postApiExerciseQuestions(user?.companyId, body);
            setIsAnsweredQuestion(true);
            setQuestionsAnsweredNow(response?.answerExerciseQuestionViewModel);

        } catch (e) {
            addNotification('Erro ao responder questão', 'red');
        }
        setIsLoadingAnswerQuestion(false);
    };

    const localISOTime = (date) => {
        const options: any = {
            timeZone: 'America/Sao_Paulo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            fractionalSecondDigits: 3,
            hourCycle: 'h23'
        };

        const formatter = new Intl.DateTimeFormat('sv-SE', options);
        const parts = formatter.formatToParts(date);
        const year = parts.find(p => p.type === 'year').value;
        const month = parts.find(p => p.type === 'month').value;
        const day = parts.find(p => p.type === 'day').value;
        const hour = parts.find(p => p.type === 'hour').value;
        const minute = parts.find(p => p.type === 'minute').value;
        const second = parts.find(p => p.type === 'second').value;
        const millisecond = '000';

        const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;

        return formattedDate;
    };

    const getValueInPercentage = (value) => {
        return (value * 100).toFixed(2);
    };

    const handleGoToNextQuestion = async () => {
        setIsLoadingNextQuestion(true);
        const currentQuestionIndex = questions.findIndex(q => q.id === currentQuestion?.id);

        if (currentQuestionIndex < questions.length - 1) {
            let newQuestion: QuestionExercise = questions[currentQuestionIndex + 1] as QuestionExercise;
            newQuestion.selectedAlternative = 0;
            newQuestion.startTime = localISOTime(new Date());
            setIsAnsweredQuestion(false);
            setQuestionsAnsweredNow(undefined);
            setCurrentQuestion(
                newQuestion
            );
        } else {

            try {
                const body: GetQuestionsFromNextTopicCommand = {
                    learningPathLevelId: props.learningPathLevel.id,
                    companyId: user?.companyId,
                    studentId: user?.studentId,
                    subjectId: props.learningPath.subjectId

                };

                const newQuestions = await LearningPathService.postApiLearningPathGetQuestionsFromNextTopic(user?.companyId, body);

                if (newQuestions.statusCode.toString().startsWith('2')) {
                    if (newQuestions.data?.hasNextTopic) {
                        setQuestions(newQuestions?.data?.questions);
                        let inicialCurrentQuestion = newQuestions?.data?.questions?.length > 0 ? newQuestions.data.questions[0] as QuestionExercise : undefined;
                        inicialCurrentQuestion.startTime = localISOTime(new Date());
                        setCurrentQuestion(inicialCurrentQuestion);
                        setIsAnsweredQuestion(false);
                        setQuestionsAnsweredNow(undefined);

                    } else if (newQuestions.data?.hasTopicsNotMasterized && newQuestions.data.topicsNotMasterized?.length > 0) {
                        setFailedInTopic(true);

                        const topicsNotMasterizedInCurrentLearningPathLevel = newQuestions.data.topicsNotMasterized
                            ?.filter(t => props.learningPathLevel?.learningPathLevelTopicSequences.map(t => t.topicId).includes(t.id));

                        setTopicsNotMasterized(topicsNotMasterizedInCurrentLearningPathLevel);
                        setIsAnsweredQuestion(false);
                        setQuestionsAnsweredNow(undefined);
                    } else {
                        setNoMoreQuestions(true);
                        addNotification("Nível concluido com sucesso! Clique em fechar para ver os próximos níveis", 'green');
                    }
                }
            }
            catch (e) {
                addNotification('Ocorreu um erro. tente responder as questões daqui alguns minutos', 'red');
            };
        };
        setIsLoadingNextQuestion(false);
    };

    const handleClose = () => {
        props.oncClose();
    };

    const remakeQuestionsForSomeTopicsOfLearningPathLevel = async (topicsIds: number[]) => {
        if (!user?.studentId || !user?.companyId) return;
        setIsLoadingDeletePendingQuestions(true);
        try {
            const body: AnswerQuestionFromSomeTopicsAgainCommand = {
                studentId: user?.studentId,
                companyId: user?.companyId,
                learningPathLevelId: props.learningPathLevel.id,
                topicsIds: topicsIds,
            };

            const response = await LearningPathService.deleteApiLearningPathRemovePendingQuestionsFromLearningPathLevelTopic(user?.companyId, body);

            if (response.statusCode.toString().startsWith('2')) {
                setFailedInTopic(false);
                setQuestions(response?.data);

                let inicialCurrentQuestion = response?.data?.length > 0 ? response?.data[0] as QuestionExercise : undefined;
                inicialCurrentQuestion.startTime = localISOTime(new Date());

                setCurrentQuestion(inicialCurrentQuestion);
            }

        } catch (e) {
            addNotification('Erro ao responder questão', 'red');
        };
        setIsLoadingDeletePendingQuestions(false);
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: 'calc(100vh - 80px)' }}>
                {
                    failedInTopic ?
                        <ConfirmRestartQuestionsLearningPathLevelModal
                            open={failedInTopic}
                            handleClose={handleClose}
                            topicsNotMasterized={topicsNotMasterized}
                            handleConfirm={remakeQuestionsForSomeTopicsOfLearningPathLevel}
                            isLoading={isLoadingDeletePendingQuestions}
                        />
                        : currentQuestion &&
                        <Box sx={{ width: '70%', marginTop: '2rem', height: '70vh' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>

                                {
                                    <>

                                        {
                                            <>
                                                <Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                                        <h1>{props.learningPathLevel.name}</h1>
                                                        <Box>
                                                            <Typography color='primary' sx={{ fontWeight: 'bold' }}>Conteúdo(s): {currentQuestion?.topics.map(t => t.description)?.join(',')}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ marginTop: '1.5rem' }}>
                                                        <div dangerouslySetInnerHTML={{ __html: currentQuestion?.statement }} />
                                                        <Box>

                                                            {!isAnsweredQuestion ?
                                                                currentQuestion?.alternatives.map((alternative, index) => (
                                                                    <Box key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                                                        <FormControlLabel control={<Checkbox checked={currentQuestion?.selectedAlternative === alternative.id} onChange={() => { handleSelectAlternativeCurrentQuestion(alternative.id); }} />} label={<label dangerouslySetInnerHTML={{ __html: alternative.text }} />} />
                                                                    </Box>
                                                                ))
                                                                :
                                                                questionsAnsweredNow &&
                                                                currentQuestion?.alternatives.map((alternative, index) => {
                                                                    const isCorrect = alternative.id === questionsAnsweredNow?.correctAlternativeId;
                                                                    const isWrong = alternative.id === currentQuestion?.selectedAlternative;

                                                                    return (
                                                                        <Box key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                                                            <FormControlLabel control={<Checkbox
                                                                                checked={currentQuestion?.selectedAlternative === alternative.id}
                                                                                disabled={true}
                                                                            />} label={
                                                                                isCorrect ?
                                                                                    <Alert severity="success" sx={{ width: '25rem', display: 'flex', alignItems: 'center' }}>
                                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                            <span dangerouslySetInnerHTML={{ __html: alternative.text }} style={{ marginRight: '8px' }} />
                                                                                            <span> - Alternativa correta!</span>
                                                                                        </div>
                                                                                    </Alert>
                                                                                    :
                                                                                    isWrong ?
                                                                                        <Alert severity="error" sx={{ width: '25rem', display: 'flex', alignItems: 'center' }}>
                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                <span dangerouslySetInnerHTML={{ __html: alternative.text }} style={{ marginRight: '8px' }} />
                                                                                                <span> - Alternativa incorreta!</span>
                                                                                            </div>
                                                                                        </Alert>
                                                                                        :
                                                                                        <label
                                                                                            dangerouslySetInnerHTML={{ __html: alternative.text }}
                                                                                        />
                                                                            } />
                                                                        </Box>
                                                                    );
                                                                })

                                                            }
                                                            {
                                                                questionsAnsweredNow &&
                                                                <>
                                                                    <h3 style={{ marginTop: '1rem' }}>Justificativa</h3>
                                                                    <div dangerouslySetInnerHTML={{ __html: questionsAnsweredNow?.justification ?? "-" }} />
                                                                    <h3 style={{ marginTop: '1rem' }}>Porcentagem de acerto</h3>
                                                                    <Typography>{getValueInPercentage(questionsAnsweredNow?.correctAnswersAverage)} %</Typography>
                                                                </>
                                                            }

                                                        </Box>

                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                                                    {
                                                        questionsAnsweredNow ?
                                                            <LoadingButton onClick={() => { handleGoToNextQuestion(); }}
                                                                disabled={noMoreQuestions}
                                                                loading={isLoadingNextQuestion}
                                                            >Proxima questão</LoadingButton>
                                                            :
                                                            <LoadingButton
                                                                loading={isLoadingAnswerQuestion}
                                                                onClick={handleAnswerQuestion}
                                                            >Responder</LoadingButton>
                                                    }

                                                    <Tooltip title={""}>
                                                        <Box>
                                                            <Button  onClick={() => handleClose()}
                                                            >Fechar</Button>
                                                        </Box>
                                                    </Tooltip>
                                                </Box>
                                            </>
                                        }
                                    </>
                                }
                            </Box>
                        </Box>

                }
            </Box >
        </>
    );

};

export default AnswerQuestionsComponent;