/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateSubjectCommand } from '../models/ActivateSubjectCommand';
import type { CreateSubjectCommand } from '../models/CreateSubjectCommand';
import type { DeactivateSubjectCommand } from '../models/DeactivateSubjectCommand';
import type { UpdateSubjectCommand } from '../models/UpdateSubjectCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SubjectsService {
    /**
     * Obtém uma Disciplina pelo Id.
     * @param id Id da Disciplina.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiSubjects(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/subjects/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Altera as informações de uma Disciplina.
     * Exemplo:
     * { "description": "Matemática 2", "knowledgeAreaId": 1 }
     * @param id Id da Disciplina
     * @param tenantId Id da Empresa
     * @param requestBody Command que contém as informações para atualizar a Disciplina.
     * @returns void
     * @throws ApiError
     */
    public static putApiSubjects(
        id: number,
        tenantId: number,
        requestBody?: UpdateSubjectCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/subjects/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Obtém todos os Conteúdos de uma Disciplina.
     * @param id
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiSubjectsTopics(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/subjects/{id}/topics',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Cria uma nova Disciplina.
     * Exemplo:
     * { "description": "Matemática", "knowledgeAreaId": 1, "companyId": 1 }
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo as informações da Disciplina para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiSubjects(
        tenantId: number,
        requestBody?: CreateSubjectCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/subjects',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ativa uma Disciplina a partir de seu Id.
     * @param id Id da Disciplina a ser ativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para ativar a Disciplina.
     * @returns void
     * @throws ApiError
     */
    public static putApiSubjectsActivate(
        id: number,
        tenantId: number,
        requestBody?: ActivateSubjectCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/subjects/{id}/activate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa uma Disciplina a partir de seu Id.
     * @param id Id da Disciplina a ser desativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para desativar a Disciplina.
     * @returns void
     * @throws ApiError
     */
    public static putApiSubjectsDeactivate(
        id: number,
        tenantId: number,
        requestBody?: DeactivateSubjectCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/subjects/{id}/deactivate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
