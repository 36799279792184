import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { useNotification } from '../../../services/NotificationContext';
import { CompaniesService, KnowledgeAreasService, SubjectsService } from "api-gen";
import React, { useEffect } from "react"; 

interface StartStudySessionModalProps {
    modalIsOpen: boolean;
    handleCloseModal: () => void;
    user: any;
    designParams?: any;
    isAnswerQuestionOpen: boolean;
    setIsAnswerQuestionOpen: (value: boolean) => void;
    selectedTopic: number;
    setSelectedTopic: (value: any) => void;
};


const StartStudySessionModal = (props: StartStudySessionModalProps) => {
    const { addNotification } = useNotification();
    const [knowledgeAreas, setKnowledgeAreas] = React.useState([]);
    const [selectedKnowledgeArea, setSelectedKnowledgeArea] = React.useState();
    const [subjects, setSubjects] = React.useState([]);
    const [selectedSubject, setSelectedSubject] = React.useState();
    const [topics, setTopics] = React.useState([]);

    const [isLoadingKnowLedgeAreas, setIsLoadingKnowLedgeAreas] = React.useState(false);
    const [isLoadingSubjects, setIsLoadingSubjects] = React.useState(false);
    const [isLoadingTopics, setIsLoadingTopics] = React.useState(false);

    const getAllKnowledgeAreas = async () => {
        setIsLoadingKnowLedgeAreas(true);
        try {
            var response = await CompaniesService.getApiCompaniesKnowledgeAreas(props.user?.companyId);
            if (response && response?.knowledgeAreas) {
                setKnowledgeAreas(response.knowledgeAreas);
            }

        } catch (e) {
            addNotification("Erro ao carregar áreas de conhecimento", "#bb2124");
        }
        setIsLoadingKnowLedgeAreas(false);
    };

    useEffect(() => {
        getAllKnowledgeAreas();
        // eslint-disable-next-line
    }, [props.user]);

    const handleChangeKnowledgeArea = async (event) => {
        setIsLoadingSubjects(true);
        try {
            var response = await KnowledgeAreasService.getApiKnowledgeAreasSubjects(event.target.value, props.user?.companyId);
            if (response && response?.subjects) {
                setSubjects(response.subjects);
            }

            setSelectedKnowledgeArea(event.target.value);
            setSelectedSubject(undefined);
            props.setSelectedTopic(0);
        }
        catch (e) {
            addNotification("Erro ao carregar áreas de conhecimento", "#bb2124");
        }
        setIsLoadingSubjects(false);
    };

    const handleChangeSubject = async (event) => {
        setIsLoadingTopics(true);
        try {
            var response = await SubjectsService.getApiSubjectsTopics(event.target.value, props.user?.companyId);
            if (response && response?.topics) {
                setTopics(response.topics);
            }
            setSelectedSubject(event.target.value);
        }
        catch (e) {
            addNotification("Erro ao carregar áreas de conhecimento", "#bb2124");
        }
        setIsLoadingTopics(false);
    };

    const handleChangeTopic = async (event) => {
        props.setSelectedTopic(event.target.value);
    }

    const handleStartStudySession = async () => { 
        props.setIsAnswerQuestionOpen(true);
        props.handleCloseModal(); 
    };


    return (
        <Dialog
            open={props.modalIsOpen}
            onClose={props.handleCloseModal}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>Iniciar nova sessão de estudos</DialogTitle>
            <DialogContent
                sx={{ height: '50vh', display: 'flex', flexDirection: 'column' }}>
                {
                    isLoadingKnowLedgeAreas ?
                        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                        :
                        <FormGroup
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <Box sx={{ display: "flex", flexDirection: 'column', gap: '1rem' }}>
                                <InputLabel id="knowledge-area-label">Selecione uma área de conhecimento</InputLabel>
                                <Select
                                    sx={{ width: '100%' }}
                                    labelId="knowledge-area-label"
                                    id="knowledge-area-select"
                                    value={selectedKnowledgeArea}
                                    label="Área de conhecimento"
                                    onChange={handleChangeKnowledgeArea}
                                >
                                    {knowledgeAreas?.map((knowledgeArea: any) => {
                                        return <MenuItem value={knowledgeArea.id}>{knowledgeArea.description}</MenuItem>
                                    })}
                                </Select>
                            </Box>



                            {
                                isLoadingSubjects ?
                                    <Box sx={{ display: "flex", justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <Box sx={{ display: "flex", flexDirection: 'column', gap: '1rem' }}>

                                        <InputLabel id="subjects-label">Selecione uma Disciplina</InputLabel>
                                        <Select
                                            sx={{ width: '100%' }}
                                            labelId="subjects-label"
                                            id="subjects-select"
                                            value={selectedSubject}
                                            label="Disciplina"
                                            onChange={handleChangeSubject}
                                        >
                                            {subjects?.map((subject: any) => {
                                                return <MenuItem value={subject.id}>{subject.description}</MenuItem>
                                            })}
                                        </Select>
                                    </Box>
                            }

                            {
                                isLoadingTopics ?
                                    <Box sx={{ display: "flex", justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <Box sx={{ display: "flex", flexDirection: 'column', gap: '1rem' }}>
                                        <InputLabel id="topic-label">Selecione um conteúdo</InputLabel>
                                        <Select
                                            sx={{ width: '100%' }}
                                            labelId="topic-label"
                                            id="topic-select"
                                            value={props.selectedTopic}
                                            label="Conteúdo"
                                            onChange={handleChangeTopic}
                                        >
                                            {topics?.map((subject: any) => {
                                                return <MenuItem value={subject.id}>{subject.description}</MenuItem>
                                            })}
                                        </Select>
                                    </Box>
                            }

                        </FormGroup>

                }

            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', margin: '1rem' }}>
                <Button
                    onClick={props.handleCloseModal}
                    variant="contained"
                    sx={{ backgroundColor: props?.designParams?.secondaryColorHex || "#bb2124" }}
                    color="error" 
                >
                    Cancelar
                </Button>
                <Tooltip title={props.selectedTopic === 0 || !props.selectedTopic ? "Esolha um conteúdo para iniciar a sessão de estudos" : ""}>
                    <Box>
                        <Button 
                            variant="contained"
                            disabled={isLoadingKnowLedgeAreas || props.selectedTopic === 0 || !props.selectedTopic}
                            onClick={handleStartStudySession}
                        >
                            Iniciar sessão de estudos
                        </Button>

                    </Box>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

export default StartStudySessionModal;
