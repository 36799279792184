import React, { useEffect } from "react";
import Menu from "../../components/Header";
import { Box, Checkbox, FormControl, FormControlLabel, TextField, Typography } from "@mui/material";
import useAuth from '../../hooks/useAuth.js';
import { CompaniesService, UpdateCompanyParametersCommand } from "api-gen";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNotification } from '../../services/NotificationContext';

export interface ICompanyParams {
    questionRepeatInterval: number;
    allowQuestionCatalog: boolean;
};

const CompanyParams = () => {
    const { addNotification } = useNotification();
    const { user } = useAuth();
    const [isLoadingSave, setIsLoadingSave] = React.useState<boolean>(false);
    const [companyParams, setCompanyParams] = React.useState<ICompanyParams>(undefined); 

    console.log(companyParams);
    console.log(companyParams?.allowQuestionCatalog)

    const getCompanyParameters = async () => {

        try {
            if (user?.companyId) {
                var response = await CompaniesService.getApiCompaniesParameters(user?.companyId);

                setCompanyParams(response?.companyParameters); 
            }

        } catch (e) {
            addNotification('Erro ao buscar configurações', 'red');
        }
    };



    const handleChangeQuestionRepeatInterval = (value: number) => {
        setCompanyParams({ ...companyParams, questionRepeatInterval: value });
    };

    const handleSaveChanges = async () => {
        setIsLoadingSave(true);
        try {
            const body: UpdateCompanyParametersCommand = {
                questionRepeatInterval: companyParams?.questionRepeatInterval,
                allowQuestionCatalog: companyParams?.allowQuestionCatalog,
                companyId: user?.companyId
            };

            await CompaniesService.putApiCompaniesParameters(user?.companyId, body);
            addNotification('Configurações salvas com sucesso', 'green');
        } catch (e) {
            addNotification('Erro ao salvar configurações', 'red');
        }
        setIsLoadingSave(false);
    };

    useEffect(() => {
        getCompanyParameters();
        // eslint-disable-next-line
    }, [user]);
    return (
        <>
            <Menu />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: 'calc(100vh - 80px)' }}>
                <Box sx={{ width: '70%', marginTop: '3rem', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    <h1 style={{ fontSize: '2rem' }}>
                        Configurações
                    </h1>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
                            Questões
                        </Typography>
                        <FormControl variant='standard' sx={{ width: '100%', display: "flex", flexDirection: 'column', gap: '0.5rem' }}>
                            <Typography>Quantidade de dias para reaparecer questões para o aluno </Typography>
                            <TextField
                                id="inep"
                                label="Dias para reaparecer questões"
                                variant="outlined"
                                type='number'
                                value={companyParams?.questionRepeatInterval || 0}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/[^\d]/g, '');
                                    handleChangeQuestionRepeatInterval(value === '' ? 0 : Number(value));
                                }}
                            />
                        </FormControl>
                        <FormControl variant='standard' sx={{ width: '100%', display: "flex", flexDirection: 'column', gap: '0.5rem' }}>
                            <FormControlLabel control={<Checkbox checked={companyParams?.allowQuestionCatalog === true ? true : false}
                                onChange={(e) => {
                                    setCompanyParams({ ...companyParams, allowQuestionCatalog: e.target.checked });
                                }} />} label="Permitir o aluno visualizar questões não respondidas" />

                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", width: '100%', justifyContent: 'flex-end' }}>
                        <span>
                            <LoadingButton
                                loading={isLoadingSave}
                                disabled={isLoadingSave}
                                variant='contained'
                                onClick={handleSaveChanges}
                            >
                                Salvar alterações
                            </LoadingButton>
                        </span>
                    </Box>
                </Box>
            </Box>
        </>
    );

};
export default CompanyParams;