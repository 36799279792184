/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateQuestionCommand } from '../models/ActivateQuestionCommand';
import type { CreateQuestionCommand } from '../models/CreateQuestionCommand';
import type { DeactivateQuestionCommand } from '../models/DeactivateQuestionCommand';
import type { UpdateQuestionCommand } from '../models/UpdateQuestionCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class QuestionsService {
    /**
     * Obtém uma Questão pelo Id.
     * @param id Id da Questão.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiQuestions(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/questions/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Altera as informações de uma Questão.
     * Exemplo:
     * { "code": "123", "statement": "Enunciado da Questão", "justification": "Justificativa", "year": 2024, "knowledgeAreaId": 1, "examBoardId": 1, "taxonomyId": 1, "examinationId": 1, "jobPositionId": 1, "degreeAreaId": 1, "expertiseAreaId": 1,, "topicIds": [1, 2],
     * "alternatives": [{ "text": "Alternativa 1", "isCorrect": true }, { "text": "Alternativa 2", "isCorrect": false }, { "text": "Alternativa 3", "isCorrect": false }, { "text": "Alternativa 4", "isCorrect": false }, { "text": "Alternativa 5", "isCorrect": false }] }
     * @param id Id da Questão
     * @param tenantId Id da Empresa
     * @param requestBody Command que contém as informações para atualizar a Questão.
     * @returns void
     * @throws ApiError
     */
    public static putApiQuestions(
        id: number,
        tenantId: number,
        requestBody?: UpdateQuestionCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/questions/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cria uma nova Questão.
     * Exemplo:
     * { "code": "123", "statement": "Enunciado da Questão", "justification": "Justificativa", "year": 2024,
     * "knowledgeAreaId": 1, "examBoardId": 1, "taxonomyId": 1,
     * "examinationId": 1, "jobPositionId": 1, "degreeAreaId": 1, "expertiseAreaId": 1,
     * "topicIds": [1, 2], "companyId": 1,
     * "alternatives": [{ "text": "Alternativa 1", "isCorrect": true }, { "text": "Alternativa 2", "isCorrect": false }, { "text": "Alternativa 3", "isCorrect": false },
     * { "text": "Alternativa 4", "isCorrect": false }, { "text": "Alternativa 5", "isCorrect": false }] }
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo as informações da Questão para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiQuestions(
        tenantId: number,
        requestBody?: CreateQuestionCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/questions',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ativa uma Questão a partir de seu Id.
     * @param id Id da Questão a ser ativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para ativar a Questão.
     * @returns void
     * @throws ApiError
     */
    public static putApiQuestionsActivate(
        id: number,
        tenantId: number,
        requestBody?: ActivateQuestionCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/questions/{id}/activate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa uma Questão a partir de seu Id.
     * @param id Id da Questão a ser desativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para desativar a Questão.
     * @returns void
     * @throws ApiError
     */
    public static putApiQuestionsDeactivate(
        id: number,
        tenantId: number,
        requestBody?: DeactivateQuestionCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/questions/{id}/deactivate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
