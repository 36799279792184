import React, { useEffect } from "react";
import Header from "../../components/Header"
import { Box, CircularProgress } from "@mui/material";
import Chart from "react-google-charts";
import useAuth from '../../hooks/useAuth'; 
import { CompaniesService, StudentsService } from "api-gen";

enum ChartsEnum {
  Avarage,
  CorrectAnswersByExamBoard,
  CorrectAnswersBySubject,
  CorrectAnswersByKnowledgeArea,
  CorrectAnswersByContent,
  CorrectAnswersByTopic,
  CorrectAnswersByTaxonomy,

};

type chartType =  {
  type: ChartsEnum,  
  displayType: "BarChart" | "PieChart",

}

const Home = () => {
  const { user }: any = useAuth();
  const [analytics, setAnalytics] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(false);  

  useEffect(() => {
    getAnalytics();
    // eslint-disable-next-line
  }, [user]);

  const getAnalytics = async () => {
    setIsLoading(true);
    try{
      if(user?.companyId){
        if (user?.role === 2) {
          var responseCompany: any = await CompaniesService.getApiCompaniesExerciseQuestionsAnalytics(user?.companyId);
          setAnalytics(responseCompany?.analytics);
        } else if (user?.role === 3) {
          var responseStudent: any = await StudentsService.getApiStudentsExerciseQuestionsAnalytics(user?.studentId, user?.companyId);
          setAnalytics(responseStudent?.analytics);
        }
      }
    }
    catch (error) {
      console.error('Failed to fetch:', error);
    }
    setIsLoading(false);
  };



  const charts: chartType[] = [
    { type: ChartsEnum.Avarage, displayType: "PieChart" },
    { type: ChartsEnum.CorrectAnswersByExamBoard, displayType: "BarChart" },
    { type: ChartsEnum.CorrectAnswersBySubject, displayType: "BarChart" },
    { type: ChartsEnum.CorrectAnswersByKnowledgeArea, displayType: "BarChart" },
    // { type: ChartsEnum.CorrectAnswersByContent, displayType: "BarChart" },
    { type: ChartsEnum.CorrectAnswersByTopic, displayType: "BarChart" },
    { type: ChartsEnum.CorrectAnswersByTaxonomy, displayType: "BarChart" },
  ];




  const getChartData = (chartType: ChartsEnum) => {
    var ret = [];
    switch (chartType) {
      case ChartsEnum.Avarage:
        ret = [
          ["Média de acertos/ Erros", "Quantidade"],
          ["Acertos", analytics?.questionsAnsweredCorrectlyAverage ?? 0],
          ["Erros", analytics?.questionsAnsweredIncorrectlyAverage ?? 0],
        ];
        break;
      case ChartsEnum.CorrectAnswersByExamBoard:
        ret = [
          ["Aplicadora", "Total de Questões", "Acertos"]
        ];
        analytics?.answersGroupedByExamBoard?.forEach((examBoard: any) => {
          ret.push([examBoard.groupDescription, examBoard?.totalCount || 0, examBoard.correctCount]);
        });

        break;
      case ChartsEnum.CorrectAnswersBySubject:
        ret = [
          ["Aplicadora", "Total de Questões", "Acertos"]
        ];
        analytics?.answersGroupedBySubject?.forEach((examBoard: any) => {
          ret.push([examBoard.groupDescription, examBoard?.totalCount || 0, examBoard.correctCount]);
        });

        break;
      case ChartsEnum.CorrectAnswersByKnowledgeArea:
        ret = [
          ["Aplicadora", "Total de Questões", "Acertos"]
        ];
        analytics?.answersGroupedByKnowledgeArea?.forEach((examBoard: any) => {
          ret.push([examBoard.groupDescription, examBoard?.totalCount || 0, examBoard.correctCount]);
        });

        break;
      case ChartsEnum.CorrectAnswersByTopic:
        ret = [
          ["Aplicadora", "Total de Questões", "Acertos"]
        ];
        analytics?.answersGroupedByTopic?.forEach((examBoard: any) => {
          ret.push([examBoard.groupDescription, examBoard?.totalCount || 0, examBoard.correctCount]);
        });

        break;
      case ChartsEnum.CorrectAnswersByTaxonomy:
        ret = [
          ["Aplicadora", "Total de Questões", "Acertos"]
        ];
        analytics?.answersGroupedByTaxonomy?.forEach((examBoard: any) => {
          ret.push([examBoard.groupDescription, examBoard?.totalCount || 0, examBoard.correctCount]);
        });

        break;
      default:
        ret = [];

    }

    return {
      isVisible: ret.length > 1,
      data: ret
    };


  };

  const getChartOptions = (chartType: ChartsEnum) => {
    const options = {
      legend: "none",
      pieSliceText: "label",
      title: "Acertos por Conteúdo",
      pieStartAngle: 100,
      textAlign: 'center',
      titleTextStyle: {
        fontSize: 20,
        italic: false,
        textAlign: "center",
        width: "100%",

      },
      backgroundColor: 'transparent',
    };

    switch (chartType) {
      case ChartsEnum.Avarage:
        options.title = "Média de acertos/Erros";
        break;
      case ChartsEnum.CorrectAnswersByExamBoard:
        options.title = "Acertos por Aplicadora";
        break;
      case ChartsEnum.CorrectAnswersBySubject:
        options.title = "Acertos por Disciplina";
        break;
      case ChartsEnum.CorrectAnswersByKnowledgeArea:
        options.title = "Acertos por Área de Conhecimento";
        break;
      case ChartsEnum.CorrectAnswersByTopic:
        options.title = "Acertos por Tópico";
        break;
      case ChartsEnum.CorrectAnswersByTaxonomy:
        options.title = "Acertos por Taxonomia";
        break;
      default:
        break;
    }
    return options;
  }

  return (
    <Box sx={{ height: '100vh' }}>
      <Header />
      {(user?.role === 2 || user?.role === 3) && analytics &&
        <Box sx={{ display: "flex", flexWrap: 'wrap', alignItems: "center", justifyContent: "space-evenly", height: "90%" }}>
          {
            isLoading ?
              <CircularProgress />
              :
              analytics ?
                charts.map((chart, index) => {
                  const data = getChartData(chart.type);
                  return (
                    data.isVisible &&
                    <Chart
                      key={index}
                      chartType={chart.displayType}
                      data={data?.data}
                      options={getChartOptions(chart.type)}
                      width={"35rem"}
                      height={"30rem"}
                      style={{ textAlign: 'center' }}
                    />
                  );
                }) :
                <h1> Não há dados para serem exibidos </h1>
          }


        </Box>
      }
    </Box>
  );
};

export default Home;

