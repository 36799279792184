import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const executeLogin = async () => {
    const userToken = await localStorage.getItem("token");
    const usersStorage = await localStorage.getItem("user"); 


    if (userToken && usersStorage) {
      var expirationTime = new Date(JSON.parse(usersStorage).expirationTime);
      const currentDate = new Date();
      if (expirationTime <= currentDate) {
        await signout();
      } else { 
        const hasUser = JSON.parse(usersStorage); 
        await setUser(hasUser);

        const currentPath = location.pathname;

        if (currentPath === '/' || currentPath === '') {
          navigate("/home");
        }

      }
    }
  }
  useEffect(() => {
    executeLogin();
    // eslint-disable-next-line
  }, []);

  const signin = (user) => {
    localStorage.setItem("token", user.token);
    var dateExpirationToken = new Date();

    dateExpirationToken.setHours(dateExpirationToken.getHours() + 8);


    user = { ...user, expirationTime: dateExpirationToken };
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  const signout = () => {
    setUser(null);
    localStorage.removeItem("user")
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{ user, signed: !!user, signin, signout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
