import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const TextContainer = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%; /* Ocupa toda a largura disponível */
`;

const RadioButton = styled.input`
  margin-right: 10px;
`;

const RenderedTexts = ({ htmlContent, isChecked, index, setRadio }) => {
  return (
    <Container onClick={() => setRadio(index)}>
      <RadioButton type="radio" checked={isChecked} />
      <TextContainer>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </TextContainer>
    </Container>
  );
};

export default RenderedTexts;
