import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

export const Content = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
  color: #676767;
`;

export const LabelSignin = styled.label`
  font-size: 16px;
  color: #676767;
`;

export const LabelError = styled.label`
  font-size: 14px;
  color: red;
`;

export const Strong = styled.strong`
  cursor: pointer;

  a {
    text-decoration: none;
    color: #676767;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: center; /* Centraliza os itens horizontalmente */
  align-items: center; /* Centraliza os itens verticalmente */
`;

export const TabButton = styled.button`
  color: ${props => props.active ? 'white' : '#1A202C'}; /* Define a cor do texto com base na propriedade active */
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px ;
  transition: background-color 0.3s ease;
  font-weight: bold; /* Define o texto em negrito */
  &:hover {
    background-color: #ddd;
  }

  ${({ active }) =>
    active &&
    `
    background-color: #1A202C; 
    ;
  `}
`;

