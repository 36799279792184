import React from 'react'
import { Container } from './styles'

const SidebarItem = ({ Icon, Text, onClick }) => {

  const handleClick = () => {
    if (onClick) {
      onClick(); // Chama a função de redirecionamento passada como propriedade
    }
  };
  return (
    <Container onClick={handleClick}>
      <Icon />
      {Text}
    </Container>
  )
}

export default SidebarItem