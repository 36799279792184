import React, { useEffect } from 'react';
import { Box, Card, CardContent, Typography, LinearProgress, Grid, IconButton, styled, Link, Button } from '@mui/material';
import Menu from "../../components/Header";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LearningPathInfoStudentViewModel, LearningPathLevelsViewModel, LearningPathService, LearningPathViewModel, QuestionsFromLearningPathLevelViewModel } from "api-gen";
import useAuth from '../../hooks/useAuth.js';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { GetAllLevelsByLearningPath, GetAllLevelsCountByLearningPath, VerifyIfLearningPathLevelIsMasterized, VerifyIfLearningPathLevelParentIsMasterized } from "pages/LearningPath/Commom/FindLearningPathLevel";
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import AnswerQuestionsComponent from "./Components/AnswerQuestionsComponent";
import { useNotification } from "services/NotificationContext";
const LearningPathStudent = () => {
    const [learningPaths, setLearningPaths] = React.useState<LearningPathViewModel[]>([]);
    const [learningPathInfoStudent, setLearningPathInfoStudent] = React.useState<LearningPathInfoStudentViewModel>(undefined);
    const [lastLearningPathStudied, setLastLearningPathStudied] = React.useState<LearningPathViewModel>(undefined);
    const [isAnsweringLearningPath, setIsAnsweringLearningPath] = React.useState<boolean>(false);
    const [selectedLearningPathLevel, setSelectedLearningPathLevel] = React.useState<LearningPathLevelsViewModel>(undefined);
    const [selectedLearningPath, setSelectedLearningPath] = React.useState<LearningPathViewModel>(undefined);
    const [questions, setQuestions] = React.useState<QuestionsFromLearningPathLevelViewModel>(undefined);
    const { user } = useAuth();
    const { addNotification } = useNotification();

    const getLearningPathInfos = async () => {
        if (!user?.companyId)
            return;
        try {

            var response = await LearningPathService.getApiLearningPathGetLearningPathsStudent(user?.companyId, user?.studentId, user?.companyId);
            if (response.data) {
                setLearningPathInfoStudent(response?.data);

                if (response?.data?.lastLearningPathStudiedId) {
                    const lastLearningPath = learningPaths.find(x => x.id === response?.data?.lastLearningPathStudiedId);
                    setLastLearningPathStudied(lastLearningPath);
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    };


    const getAllLearningPaths = async () => {
        if (!user?.companyId)
            return;
        try {

            var response = await LearningPathService.getApiLearningPath(user?.companyId);
            if (response.data) {
                setLearningPaths(response.data);
            }

            var responseInfoStudent = await LearningPathService.getApiLearningPathGetLearningPathsStudent(user?.companyId, user?.studentId, user?.companyId);
            if (responseInfoStudent.data) {
                setLearningPathInfoStudent(responseInfoStudent?.data);

                if (responseInfoStudent?.data?.lastLearningPathStudiedId) {
                    const lastLearningPath = response.data.find(x => x.id === responseInfoStudent?.data?.lastLearningPathStudiedId);
                    setLastLearningPathStudied(lastLearningPath);
                }
            }

        }
        catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getAllLearningPaths();
        // eslint-disable-next-line
    }, [user?.companyId]);

    const handleAnswerLearningPathLevel = async (learningPathLevel: LearningPathLevelsViewModel, learningPath: LearningPathViewModel) => {
        try{

            var result = await LearningPathService.getApiLearningPathGetQuestionsByLearningPathLevel(user?.companyId,user?.studentId,user?.companyId,learningPathLevel.id);
            setIsAnsweringLearningPath(true);
            setSelectedLearningPathLevel(learningPathLevel);
            setSelectedLearningPath(learningPath);
            setQuestions(result.data);
        }
        catch(e){
            addNotification("Erro ao buscar questões","red");
        }
    };

    const handleCancelAnswerLearningPath = async () => {
        setIsAnsweringLearningPath(false);
        setSelectedLearningPathLevel(undefined);
        setSelectedLearningPath(undefined);
        setQuestions(undefined);
        await getLearningPathInfos();
    };

    const getPercentageOfLastLearningPathEstudied = () => {
        if (!lastLearningPathStudied || !learningPathInfoStudent)
            return 0;
        const levels = GetAllLevelsByLearningPath(lastLearningPathStudied);
        const topicsMasterized = learningPathInfoStudent?.topicsMasterized;

        let levelsMasterizedCount = 0;
        for (let i = 0; i < levels.length; i++) {
            if(VerifyIfLearningPathLevelIsMasterized(levels[i], topicsMasterized))
                levelsMasterizedCount++;
        }

        return (levelsMasterizedCount / levels.length) * 100;

    };

    const handleContinueAnswerLearningPath = async (learningPath: LearningPathViewModel) => {
        try{

            const levels = GetAllLevelsByLearningPath(lastLearningPathStudied);
            const topicsMasterized = learningPathInfoStudent?.topicsMasterized;
            const levelToAnswer = levels.find(x => !VerifyIfLearningPathLevelIsMasterized(x, topicsMasterized)); 

            var result = await LearningPathService.getApiLearningPathGetQuestionsByLearningPathLevel(user?.companyId,user?.studentId,user?.companyId,levelToAnswer.id);
            setIsAnsweringLearningPath(true);
            setSelectedLearningPathLevel(levelToAnswer);
            setSelectedLearningPath(learningPath);
            setQuestions(result.data);
        }
        catch(e){
            addNotification("Erro ao buscar questões","red");
        }
    };



    return (
        <>
            <Menu />
            {
                !isAnsweringLearningPath &&
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ padding: 4, width: '70%' }}>
                        {  
                            lastLearningPathStudied && getPercentageOfLastLearningPathEstudied() < 100 &&
                            <>
                                <Typography variant="h6">Continue de onde parou</Typography>
                                <LinearProgress variant="determinate" value={getPercentageOfLastLearningPathEstudied()} sx={{ height: 10, my: 2, backgroundColor: '#e0e0e0' }} />
                                <Button onClick={() => handleContinueAnswerLearningPath(lastLearningPathStudied)}>Continuar trilha de {lastLearningPathStudied.subject.description}</Button>
                            </>
                        } 
                        <Typography variant="h4" sx={{ mt: 4 }}>Trilhas disponíveis</Typography>

                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            {
                                learningPaths?.map((learningPath) => {
                                    return <CardsItems key={learningPath.id} learningPath={learningPath} topicsMasterized={learningPathInfoStudent?.topicsMasterized} handleAnswerQuestions={handleAnswerLearningPathLevel} />;
                                })
                            }
                        </Grid>
                    </Box>
                </Box>
            }
            {
                isAnsweringLearningPath && selectedLearningPathLevel &&
                   <AnswerQuestionsComponent 
                        oncClose={handleCancelAnswerLearningPath}
                        learningPathLevel={selectedLearningPathLevel}
                        questions={questions}
                        notMasterizedTopics={questions?.notMasterizedTopics}
                        learningPath={selectedLearningPath}
                    />
            }
        </>
    );
};

const CardsItems: React.FC<{ learningPath: LearningPathViewModel; topicsMasterized: number[]; handleAnswerQuestions: any; }> = ({ learningPath, topicsMasterized, handleAnswerQuestions }) => {
    const [isExpand, setIsExpand] = React.useState<boolean>(false);
    const levelsCount = GetAllLevelsCountByLearningPath(learningPath);
    return (
        <Grid item xs={12} md={12}>
            <Card >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CardContent>
                            <Typography variant="h4">{learningPath?.subject?.description}</Typography>
                            <Typography variant="body1">{levelsCount} Níveis da trilha</Typography>
                        </CardContent>

                    </Box>
                    <IconButton color="primary" onClick={() => setIsExpand(!isExpand)}>
                        {
                            !isExpand ?
                                <ExpandMoreIcon />
                                :
                                <ExpandLessIcon />
                        }
                    </IconButton>
                </Box>
                {
                    isExpand &&
                    <Box sx={{ width: '100%', p: 4, backgroundColor: "#fff" }}>
                        <Typography variant="h5">Níveis da trilha</Typography>
                        <BiologyTree learningPath={learningPath} topicsMasterized={topicsMasterized} handleAnswerQuestions={handleAnswerQuestions} />
                    </Box>
                }
            </Card>
        </Grid>
    );
};


const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    '& .MuiTreeItem-content': {
        padding: theme.spacing(1),
        '& .MuiTreeItem-label': {
            fontSize: '1rem',
        },
    },
    '& .MuiTreeItem-group': {
        marginLeft: theme.spacing(2),
    },
    '& .MuiTreeItem-iconContainer': {
        display: 'none',
    },
}));

interface getLabelLearningPathLevelProps {
    nodes: LearningPathLevelsViewModel;
    topicsMasterized: number[];
    learningPath: LearningPathViewModel;
    handleAnswerQuestions: any;
}

const getLabelLearningPathLevel: React.FC<getLabelLearningPathLevelProps> = ({ nodes, topicsMasterized, learningPath, handleAnswerQuestions }) => {
    const isMasterized = VerifyIfLearningPathLevelIsMasterized(nodes, topicsMasterized);
    const parentIsMasterized = nodes.parentId ? VerifyIfLearningPathLevelParentIsMasterized(learningPath, topicsMasterized, nodes.parentId) : true;

    return (
        (isMasterized || !parentIsMasterized) ?
            <Typography variant="body1"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',

                }}

            >
                {
                    (!parentIsMasterized && !isMasterized) ?
                        <LockIcon /> :
                        <span style={{ color: 'green' }}>
                            <CheckIcon />
                        </span>
                }
                {nodes.name}
            </Typography>
            :
            <Link
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',

                }}
                color='inherit'
                onClick={() => handleAnswerQuestions(nodes, learningPath)}
            >
                <span>
                    <Brightness1Icon />
                </span>
                {nodes.name}
            </Link>
    );
};

const renderTree = (nodes: LearningPathLevelsViewModel, topicsMasterized: number[], learningPath: LearningPathViewModel, handleAnswerQuestions, handleToggle) => (
    <StyledTreeItem key={nodes.id} itemId={nodes.id.toString()}
        label={getLabelLearningPathLevel({ nodes, topicsMasterized, learningPath, handleAnswerQuestions })}
        onClick={() => handleToggle(nodes.id.toString())}
    >
        {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node, topicsMasterized, learningPath, handleAnswerQuestions,handleToggle))
            : null}
    </StyledTreeItem>
);

const BiologyTree: React.FC<{ learningPath: LearningPathViewModel; topicsMasterized: number[]; handleAnswerQuestions: any; }> = ({ learningPath, topicsMasterized, handleAnswerQuestions }) => {
    // Get all IDs to expand them by default
    const getAllIds = (node: LearningPathLevelsViewModel): string[] => {
        let ids = [node.id.toString()]; // Convert to string here
        if (node.children) {
            node.children.forEach((child) => {
                ids = ids.concat(getAllIds(child));
            });
        }
        return ids;
    };

    const allIds = learningPath.learningPathLevels.flatMap(learningPathLevel => getAllIds(learningPathLevel));
    const [expandedItems, setExpandedItems] = React.useState(allIds);
    const handleToggle = (itemId) => {
        setExpandedItems((prevExpandedItems) =>
            prevExpandedItems.includes(itemId)
                ? prevExpandedItems.filter(id => id !== itemId)
                : [...prevExpandedItems, itemId]
        );
    };
    return (
        <Box sx={{ minHeight: 352, minWidth: 250 }}>
            <SimpleTreeView expandedItems={expandedItems}
        >
                {learningPath.learningPathLevels.map((learningPathLevel) => renderTree(learningPathLevel, topicsMasterized, learningPath, handleAnswerQuestions,handleToggle))}
            </SimpleTreeView>
        </Box>
    );
};

export default LearningPathStudent;