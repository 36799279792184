


import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

const EditorComponent = ({ content, setContent, key = 1 }) => {
    const editor = useRef(null);

    const config = {
        readonly: false,
        uploader: {
            insertImageAsBase64URI: true // Insere imagens como base64
        },
        language: 'pt_br'
    };

    const handleBlur = () => {
        if (editor.current) {
            const newContent = editor.current.value;
            setContent(newContent);
        }
    };


    return (
        <JoditEditor
            ref={editor}
            value={content}
            config={config}
            tabIndex={key} // tabIndex of textarea
            onBlur={handleBlur} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => { }}
        />
    );
};

export default EditorComponent;
