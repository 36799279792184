import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Label } from '../../Signin/styles.js';
import Input from '../../../components/Input/index.js';
import { FaTimes } from 'react-icons/fa';
import { Container, Content } from './AddModalStyles.js';
import { ExamBoardsService } from "api-gen"
import { defaultGray } from '../../../styles/colors.js';
import { useNotification } from '../../../services/NotificationContext';
import useAuth from '../../../hooks/useAuth.js';
import { useDesignParams } from 'contexts/DesignParamsContext';
import { Button } from '@mui/material';

Modal.setAppElement('#root');
const AddExamBoardModal = ({ isOpen, onClose, id }) => {

  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const { user } = useAuth();
  const designParams = useDesignParams();

  const { addNotification } = useNotification();

  const preencherDados = (dados) => {
    setDescription(dados.examBoard.name);
  }


  const getSubjects = async () => {
    if (user?.companyId) {
      const result = await ExamBoardsService.getApiExamBoards(id, user?.companyId);
      if (result) {
        preencherDados(result);
      }
    }
  }

  const resetValues = () => {
    setDescription('');
    setError('');
    onClose();
  }


  useEffect(() => {
    if (id) {
      getSubjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);



  const handleAddSubject = async () => {

    setError("");


    if (id) {
      const body = {
        id,
        name: description,
      }
      try {
        await ExamBoardsService.putApiExamBoards(id, user?.companyId, body);
        addNotification('Aplicadora alterada com sucesso!', 'green');
        onClose();
      } catch (e) {
        addNotification('Erro ao alterar Aplicadora!', 'red');
      }

    } else {

      const body = {
        companyId: user.companyId,
        name: description,
      }

      const result = await ExamBoardsService.postApiExamBoards(user?.companyId, body);
      if (result) {
        addNotification('Aplicadora inserida com sucesso!', 'green');
        resetValues();
      } else {
        addNotification('Erro ao inserir Aplicadora!', 'red');
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={resetValues}
      contentLabel="Adicionar Aplicadora"
      appElement={document.getElementById('root')}
      style={{
        content: {
          width: '50%', // Defina o tamanho desejado
          height: '35%', // Defina a altura desejada
          margin: 'auto', // Centralize horizontalmente
          background: '#fff', // Cor de fundo do modal
          border: '2px solid #ccc', // Borda do modal
          borderRadius: '8px', // Bordas arredondadas
          padding: '20px' // Espaçamento interno
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)' // Cor do fundo ao redor do modal
        }
      }}
    >
      <Container>
        <Label>{id ? "Editar Aplicadora" : "Adicionar Aplicadora"}</Label>
        <FaTimes onClick={resetValues} style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }} />
        <Content>
          <Input
            label={"Descrição"}
            type="text"
            placeholder="Digite a descrição"
            value={description}
            onChange={(e) => [setDescription(e.target.value), setError("")]}
          />


          {error &&
            <label color='red'>{error}</label>
          }

          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: designParams?.primaryColorHex || defaultGray }}
              onClick={handleAddSubject}
            >
              {id ? "Alterar" : "Adicionar"}
            </Button>
            <Button 
              variant="contained"
              onClick={onClose}
              sx={{ backgroundColor: designParams?.secondaryColorHex || 'red' }}
            >
              Cancelar
            </Button>
          </div>

        </Content>
      </Container>
    </Modal>
  );
}

export default AddExamBoardModal;