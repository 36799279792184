import { LearningPathLevelsViewModel, LearningPathLevelTopicSequenceViewModel, LearningPathViewModel } from "api-gen";

export const  AddLearningPathLevel = (learningPath: LearningPathViewModel, learningPathLevel: LearningPathViewModel, parentId: number) => {
    
    if (!parentId)
        learningPath.learningPathLevels.push(learningPathLevel);
    else {
        const addChildToParent = (parentLevel, childLevel) => {
            if (parentLevel.id === parentId) {
                parentLevel.children.push(childLevel);
                return true;
            }
            if (parentLevel.children) {
                for (let i = 0; i < parentLevel.children.length; i++) {
                    if (addChildToParent(parentLevel.children[i], childLevel)) {
                        return true;
                    }
                }
            }
            return false;
        };

        for (let i = 0; i < learningPath.learningPathLevels.length; i++) {
            if (addChildToParent(learningPath.learningPathLevels[i], learningPathLevel)) {
                break;
            }
        }
    }


};

export const ReplaceLearningPathLevelTopicSequence = (learningPath: LearningPathViewModel, learningPathLevelId: number, learningPathLevelTopicSequences : LearningPathLevelTopicSequenceViewModel[]) => {
    const replaceTopicSequence = (level) => {
        if (level.id === learningPathLevelId) {
            level.learningPathLevelTopicSequences = learningPathLevelTopicSequences;
            return true;
        }
        if (level.children) {
            for (let i = 0; i < level.children.length; i++) {
                if (replaceTopicSequence(level.children[i])) {
                    return true;
                }
            }
        }
        return false;
    };

    for (let i = 0; i < learningPath.learningPathLevels.length; i++) {
        if (replaceTopicSequence(learningPath.learningPathLevels[i])) {
            break;
        }
    }
};

export const GetLearningPathLevel = (learningPath: LearningPathViewModel, learningPathLevelId: number) : LearningPathLevelsViewModel => {
    const getLevel = (level) => {
        if (level.id === learningPathLevelId) {
            return level;
        }
        if (level.children) {
            for (let i = 0; i < level.children.length; i++) {
                const found = getLevel(level.children[i]);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    for (let i = 0; i < learningPath.learningPathLevels.length; i++) {
        const found = getLevel(learningPath.learningPathLevels[i]);
        if (found) {
            return found;
        }
    }
    return null;
};

 
export const ReplaceLearningPathLevel = (learningPath: LearningPathViewModel, updatedLevel: LearningPathViewModel) => {
    const replaceLevel = (levels: LearningPathLevelsViewModel[]) => {
        for (let i = 0; i < levels.length; i++) {
            const level = levels[i];
            if (level.id === updatedLevel.id) {
                levels[i] = { ...updatedLevel }; 
                return true;
            }
            if (level.children) {
                if (replaceLevel(level.children)) {
                    return true;
                }
            }
        }
        return false;
    };

    replaceLevel(learningPath.learningPathLevels);
};

export const DeleteLearningPathLevel = (learningPath: LearningPathViewModel, learningPathLevelId: number) => {
    const deleteLevel = (levels: LearningPathLevelsViewModel[]) => {
        for (let i = 0; i < levels.length; i++) {
            const level = levels[i];
            if (level.id === learningPathLevelId) {
                levels.splice(i, 1);
                return true;
            }
            if (level.children) {
                if (deleteLevel(level.children)) {
                    return true;
                }
            }
        }
        return false;
    };

    deleteLevel(learningPath.learningPathLevels);
};

export const GetAllLevelsCountByLearningPath = (learningPath: LearningPathViewModel) : number => {
    let count = 0;
    const countLevels = (levels: LearningPathLevelsViewModel[]) => {
        for (let i = 0; i < levels.length; i++) {
            count++;
            if (levels[i].children) {
                countLevels(levels[i].children);
            }
        }
    };

    countLevels(learningPath.learningPathLevels);
    return count;
};

export const GetAllLevelsByLearningPath = (learningPath: LearningPathViewModel) : LearningPathLevelsViewModel[] => {
    let result: LearningPathLevelsViewModel[] = [];
    
    const getLevels = (levels: LearningPathLevelsViewModel[]) => {
        for (let i = 0; i < levels.length; i++) {
            result.push(levels[i]);
            if (levels[i].children) {
                getLevels(levels[i].children);
            }
        }
    };

    getLevels(learningPath.learningPathLevels);
    return result;
}

export const GetAllTopicsCountByLearningPath = (learningPath: LearningPathViewModel) : number => {
    let count = 0;
    const countTopics = (levels: LearningPathLevelsViewModel[]) => {
        for (let i = 0; i < levels.length; i++) {
            count += levels[i].learningPathLevelTopicSequences.length;
            if (levels[i].children) {
                countTopics(levels[i].children);
            }
        }
    };

    countTopics(learningPath.learningPathLevels);
    return count;
};

export const VerifyIfLearningPathLevelIsMasterized = (learningPathLevel: LearningPathLevelsViewModel, topicsMasterized : number[]) : boolean => {
    for (let i = 0; i < learningPathLevel.learningPathLevelTopicSequences.length; i++) {
        if (!topicsMasterized?.includes(learningPathLevel.learningPathLevelTopicSequences[i].topicId))  
            return false;
    }

    return true;
};

export const VerifyIfLearningPathLevelParentIsMasterized = (learningPath: LearningPathViewModel, topicsMasterized: number[], learningPathLevelParentId: number): boolean => {

    const verifyParent = (learningPathLevel: LearningPathLevelsViewModel): boolean => {
        if (learningPathLevel.id === learningPathLevelParentId) {
            return VerifyIfLearningPathLevelIsMasterized(learningPathLevel, topicsMasterized);
        }

        if (learningPathLevel.children) {
            for (let i = 0; i < learningPathLevel.children.length; i++) {
                if (verifyParent(learningPathLevel.children[i])) {
                    return true;
                }
            }
        }
        
        return false;
    };

    for (let a = 0; a < learningPath?.learningPathLevels?.length; a++) {
        if (verifyParent(learningPath.learningPathLevels[a])) {
            return true;
        }
    }
    
    return false;
};
