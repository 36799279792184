import { Alert, Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface QuestionsAnsweredProps {
    question: any;
    seeAnswer: boolean;
    backToQuestions: () => void;
    setIsOpenModalAnswerUniqueQuestion: (value: boolean) => void;
    setCurrentQuestionToAnswer: (value: any) => void;

};


const QuestionsAnswered = (props: QuestionsAnsweredProps) => {

    const [isExpand, setIsExpand] = useState<boolean>(false);
    const handleSelectCurrentQuestionToAnswer = () => {
        setIsExpand(false);
        props.setIsOpenModalAnswerUniqueQuestion(true)
        props.setCurrentQuestionToAnswer(props.question);
    };

    console.log("isExpand", isExpand)

    return (<Box>
        <Box sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
                <Typography variant="h5" sx={{ fontSize: '1.5rem' }}>
                    {props?.question?.questionDescription !== "" ? props?.question?.questionDescription : ("Questão " + props?.question?.questionId)}
                </Typography>
                <Typography variant="h5" sx={{ fontSize: '1rem', color: '#4F7396' }}>
                    {props?.question?.topics?.length > 1 ? "Conteúdos: " : "Conteúdo: "} {props?.question?.topics?.join(',')}
                </Typography>
            </Box>
            <Box>
                {/* <Button
                        sx={{ height: '100%' }}
                        onClick={handleShowCurrentAnswer}
                    >
                        Ver resposta
                    </Button> */}

                <Button
                    sx={{ height: '100%' }}
                    onClick={() => setIsExpand(!isExpand)}
                >
                    {isExpand ? (<>Minimizar <ExpandLessIcon /></>) : (<>Expandir <ExpandMoreIcon /></>)}
                </Button>

            </Box>
        </Box>
        {
            isExpand && (props.seeAnswer ? (
                <Box sx={{
                    marginTop: '0.25rem',
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '0.5rem',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#eee',
                        borderRadius: '4px',
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
                    }
                }}>
                    <Typography sx={{ fontWeight: 'bold' }}>Enunciado</Typography>
                    <div style={{ marginTop: '0.25rem' }} dangerouslySetInnerHTML={{ __html: props?.question?.statement }} />
                    <Box sx={{ marginTop: '0.5rem' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Alternativas</Typography>
                        {
                            props?.question?.alternatives.map((alternative, index) => {
                                const isCorrect = alternative.isCorrect;
                                const isWrong = alternative.id === props.question?.selectedAlternative?.id && !isCorrect;
                                return (
                                    <Box key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                        <FormControlLabel control={<Checkbox
                                            checked={props?.question?.selectedAlternative?.id === alternative.id}
                                            disabled={true}
                                        />} label={
                                            isCorrect ?
                                                <Alert severity="success" sx={{ width: '25rem', display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span dangerouslySetInnerHTML={{ __html: alternative.text }} style={{ marginRight: '8px' }} />
                                                        <span> - Alternativa correta!</span>
                                                    </div>
                                                </Alert>
                                                :
                                                isWrong ?
                                                    <Alert severity="error" sx={{ width: '25rem', display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <span dangerouslySetInnerHTML={{ __html: alternative.text }} style={{ marginRight: '8px' }} />
                                                            <span> - Alternativa incorreta!</span>
                                                        </div>
                                                    </Alert>
                                                    :
                                                    <label
                                                        dangerouslySetInnerHTML={{ __html: alternative.text }}
                                                    />
                                        } />
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    <h3 style={{ marginTop: '0.5rem' }}>Justificativa</h3>
                    <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} dangerouslySetInnerHTML={{ __html: props?.question?.justification }} />
                </Box>
            ) : (
                <Box sx={{
                    marginTop: '0.25rem',
                    overflowX: 'auto',
                    display: 'flex',
                    '&::-webkit-scrollbar': {
                        width: '0.5rem',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#eee',
                        borderRadius: '4px',
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
                    }
                }}>
                    <Box sx={{ width: '80%' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Enunciado</Typography>
                        <div style={{ marginTop: '0.25rem' }} dangerouslySetInnerHTML={{ __html: props?.question?.statement }} />
                    </Box>

                    <Box sx={{ marginTop: '0.5rem', width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleSelectCurrentQuestionToAnswer}>
                            Visualizar alternativas
                        </Button>
                    </Box>
                </Box>
            ))
        }



    </Box>
    );
};

export default QuestionsAnswered;