import React, { createContext, useState, useContext, useEffect } from 'react';

const NotificationContext = createContext(undefined);

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (message, color = 'green', timeout = 5000) => {
        const newNotification = { message, color, timeoutId: null };

        const newNotifications = [...notifications, newNotification];
        setNotifications(newNotifications);

        newNotification.timeoutId = setTimeout(() => {
            removeNotification(newNotification);
        }, timeout);
    };

    const removeNotification = (notificationToRemove) => {
        const newNotifications = notifications.filter(
            (notification) => notification !== notificationToRemove
        );
        setNotifications(newNotifications);

        clearTimeout(notificationToRemove.timeoutId);
    };

    useEffect(() => {
        return () => {
            notifications.forEach((notification) => {
                clearTimeout(notification.timeoutId);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <NotificationContext.Provider
            value={{ notifications, addNotification, removeNotification }}
        >
            {children}
        </NotificationContext.Provider>
    );
};