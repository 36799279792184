import React, { useState } from 'react'
import { Container, Content, Label } from './styles'
import { FaBars, FaSignOutAlt } from 'react-icons/fa'
import Sidebar from '../Sidebar'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [sidebar, setSidebar] = useState(false)

  const showSiderbar = () => setSidebar(!sidebar);
  
  const { signout } = useAuth();
  const navigate = useNavigate();

  const logout = async () =>{ 
    await signout();
    navigate("/");
  }
  return (
    <Container>
      {sidebar && <Sidebar active={setSidebar} />}

    <FaBars onClick={showSiderbar} color={'white'} />
    
    <Content>
        <Label>{"Exam Platform"}</Label>
      </Content>

      <FaSignOutAlt color={'white'} onClick={logout}/>

    </Container>
  )
}

export default Header