export type ExamViewModel = {
    id: number;
    description: string;
    guid: string;
    active: boolean;
    startTime?: Date;
    endTime?: Date;
    studentId: number;
    questions: ExamQuestionViewModel[];
    status: ExamStatusEnum;
};
export enum ExamStatusEnum {
    created = 1,
    started = 2,
    finished = 3
};
export type ExamQuestionViewModel = {
    id: number;
    questionId: number;
    questionGuid: string;
    statement: string;
    year: number;
    knowledgeArea: string;
    examBoard: string;
    taxonomy: string;
    topics: string[];
    alternatives: ExamQuestionAlternativeViewModel[];
    examId: number;
    selectedAlternativeId?: number;
    startTime?: Date;
    endTime?: Date;
};

export type ExamQuestionAlternativeViewModel = {
    id: number;
    guid: string;
    text: string;
};