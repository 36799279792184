/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateTaxonomyCommand } from '../models/ActivateTaxonomyCommand';
import type { CreateTaxonomyCommand } from '../models/CreateTaxonomyCommand';
import type { DeactivateTaxonomyCommand } from '../models/DeactivateTaxonomyCommand';
import type { UpdateTaxonomyCommand } from '../models/UpdateTaxonomyCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TaxonomiesService {
    /**
     * Obtém uma Taxonomia pelo Id.
     * @param id Id da Taxonomia.
     * @param tenantId Id da Empresa
     * @returns any Success
     * @throws ApiError
     */
    public static getApiTaxonomies(
        id: number,
        tenantId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/taxonomies/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
        });
    }
    /**
     * Altera as informações de uma Taxonomia.
     * Exemplo:
     * { "level": "Médio", "percentage": 40 }
     * @param id Id da Taxonomia
     * @param tenantId Id da Empresa
     * @param requestBody Command que contém as informações para atualizar a Taxonomia.
     * @returns void
     * @throws ApiError
     */
    public static putApiTaxonomies(
        id: number,
        tenantId: number,
        requestBody?: UpdateTaxonomyCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/taxonomies/{id}',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Cria uma nova Taxonomia.
     * Exemplo:
     * { "level": "Fácil", "percentage": 20, "companyId": 1 }
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo as informações da Taxonomia para cadastro.
     * @returns any Created
     * @throws ApiError
     */
    public static postApiTaxonomies(
        tenantId: number,
        requestBody?: CreateTaxonomyCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/taxonomies',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Ativa uma Taxonomia a partir de seu Id.
     * @param id Id da Taxonomia a ser ativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para ativar a Taxonomia.
     * @returns void
     * @throws ApiError
     */
    public static putApiTaxonomiesActivate(
        id: number,
        tenantId: number,
        requestBody?: ActivateTaxonomyCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/taxonomies/{id}/activate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Desativa uma Taxonomia a partir de seu Id.
     * @param id Id da Taxonomia a ser desativada.
     * @param tenantId Id da Empresa
     * @param requestBody O command contendo os detalhes para desativar a Taxonomia.
     * @returns void
     * @throws ApiError
     */
    public static putApiTaxonomiesDeactivate(
        id: number,
        tenantId: number,
        requestBody?: DeactivateTaxonomyCommand,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/taxonomies/{id}/deactivate',
            path: {
                'id': id,
            },
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
