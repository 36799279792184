import React, { useEffect } from 'react';
import { Alert, Box, Button, Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import { AnswerExerciseQuestionCommand, ExerciseQuestionsService } from 'api-gen';
import useAuth from '../../../hooks/useAuth.js';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNotification } from '../../../services/NotificationContext';

interface AnswerQuestionProps {
    topicId: number;
    setIsAnsweringQuestion: () => void;
};

const AnswerQuestion = (props: AnswerQuestionProps) => {
    const { user } = useAuth();
    const [questions, setQuestions] = React.useState([]);
    const [currentQuestion, setCurrentQuestion] = React.useState(null);
    const [isLoadingAnswerQuestion, setIsLoadingAnswerQuestion] = React.useState(false); 
    const [noMoreQuestions, setNoMoreQuestions] = React.useState(false);
    const [questionsAnsweredNow, setQuestionsAnsweredNow] = React.useState<any>();
    const [isAnsweredQuestion, setIsAnsweredQuestion] = React.useState(false);
    const { addNotification } = useNotification();

    const getQuestions = async () => {
        try {
            var topic = Number(props.topicId);
            if (user?.companyId && props.topicId) {
                const response = await ExerciseQuestionsService.getApiExerciseQuestions(user?.companyId, topic, user?.studentId);
                if (response && response?.questions?.length > 0) {
                    setQuestions(response.questions);
                    setCurrentQuestion({
                        question: response?.questions[0],
                        selectedAlternative: 0,
                        startTime: localISOTime(new Date()),
                    });
                } else {
                    setNoMoreQuestions(true);
                }
            }
        } catch (e) {
            addNotification('Erro ao buscar questões', 'red');
        }
    };

    const handleSelectAlternativeCurrentQuestion = (alternativeId) => {
        setCurrentQuestion({
            ...currentQuestion,
            selectedAlternative: alternativeId
        });
    };

    useEffect(() => {
        getQuestions();
        // eslint-disable-next-line
    }, [user]);

    const handleAnswerQuestion = async () => {
        setIsLoadingAnswerQuestion(true);
        try {
            const body: AnswerExerciseQuestionCommand = {
                questionId: currentQuestion?.question?.questionId,
                selectedAlternativeId: currentQuestion?.selectedAlternative,
                startTime: currentQuestion?.startTime,
                endTime: localISOTime(new Date()),
                studentId: user?.studentId
            };
            const response = await ExerciseQuestionsService.postApiExerciseQuestions(user?.companyId, body);
            setIsAnsweredQuestion(true);
            setQuestionsAnsweredNow(response?.answerExerciseQuestionViewModel);

        } catch (e) {
            addNotification('Erro ao responder questão', 'red');
        }
        setIsLoadingAnswerQuestion(false);
    };

    const handleFinishSession = async () => {
        props.setIsAnsweringQuestion();

    };

    const localISOTime = (date) => {
        const options: any = {
            timeZone: 'America/Sao_Paulo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            fractionalSecondDigits: 3,
            hourCycle: 'h23'
        };

        const formatter = new Intl.DateTimeFormat('sv-SE', options);
        const parts = formatter.formatToParts(date);
        const year = parts.find(p => p.type === 'year').value;
        const month = parts.find(p => p.type === 'month').value;
        const day = parts.find(p => p.type === 'day').value;
        const hour = parts.find(p => p.type === 'hour').value;
        const minute = parts.find(p => p.type === 'minute').value;
        const second = parts.find(p => p.type === 'second').value;
        const millisecond = '000';

        const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;

        return formattedDate;
    }

    const getValueInPercentage = (value) => {
        return (value * 100).toFixed(2);
    }

    const handleGoToNextQuestion = async () => {
        setIsAnsweredQuestion(false);
        setQuestionsAnsweredNow(undefined);

        const currentQuestionIndex = questions.findIndex(q => q.questionId === currentQuestion?.question?.questionId);

        if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestion({
                    question: questions[currentQuestionIndex + 1],
                    selectedAlternative: 0,
                    startTime: localISOTime(new Date()),
                });
            } else {
                var responseQuestions = await ExerciseQuestionsService.getApiExerciseQuestions(user?.companyId, Number(props.topicId), user?.studentId);
                if (responseQuestions && responseQuestions?.questions?.length > 0) {
                    setQuestions((pre) => [...pre,
                    responseQuestions.question]);
                    setCurrentQuestion({
                        question: responseQuestions?.questions[0],
                        selectedAlternative: 0,
                        startTime: localISOTime(new Date()),
                    });
                } else {
                    setNoMoreQuestions(true); 
                }
            }
         
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: 'calc(100vh - 80px)' }}>
                <Box sx={{ width: '70%', marginTop: '2rem', height: '70vh' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                        
                        {

                            noMoreQuestions ? (
                                <Box sx={{ width: '100%', height: '35rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <h1>{noMoreQuestions ? "As questões disponiveis acabaram." : "Você finalizou as questões"}</h1>
                                    <Button onClick={() => props.setIsAnsweringQuestion()}>Voltar para as questões</Button>
                                </Box>
                            ) :
                                <>

                                    {
                                        <>
                                            <Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                                <h1>Sessão de estudos</h1>
                                                <Box>
                                                    <Typography color='primary' sx={{ fontWeight: 'bold' }}>Área de conhecimento : {currentQuestion?.question?.knowledgeArea}</Typography>
                                                    {/* <Typography color='primary' sx={{ fontWeight: 'bold' }}>Disciplina : Matemática</Typography> */}
                                                    <Typography color='primary' sx={{ fontWeight: 'bold' }}>Conteúdos: {currentQuestion?.question?.topics?.join(',')}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{marginTop: '1.5rem'}}>
                                                <div dangerouslySetInnerHTML={{ __html: currentQuestion?.question?.statement }} />
                                                <Box>

                                                    {!isAnsweredQuestion ?
                                                        currentQuestion?.question?.alternatives.map((alternative, index) => (
                                                            <Box key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                                                <FormControlLabel control={<Checkbox checked={currentQuestion?.selectedAlternative === alternative.id} onChange={() => { handleSelectAlternativeCurrentQuestion(alternative.id) }} />} label={<label dangerouslySetInnerHTML={{ __html: alternative.text }} />} />
                                                            </Box>
                                                        ))
                                                        :
                                                        questionsAnsweredNow &&
                                                        currentQuestion?.question?.alternatives.map((alternative, index) => {
                                                            const isCorrect = alternative.id === questionsAnsweredNow?.correctAlternativeId;
                                                            const isWrong = alternative.id === currentQuestion?.selectedAlternative;

                                                            return (
                                                                <Box key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                                                    <FormControlLabel control={<Checkbox
                                                                        checked={currentQuestion?.selectedAlternative === alternative.id}
                                                                        disabled={true}
                                                                    />} label={
                                                                        isCorrect ?
                                                                            <Alert severity="success" sx={{ width: '25rem', display: 'flex', alignItems: 'center' }}>
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <span dangerouslySetInnerHTML={{ __html: alternative.text }} style={{ marginRight: '8px' }} />
                                                                                    <span> - Alternativa correta!</span>
                                                                                </div>
                                                                            </Alert>
                                                                            :
                                                                            isWrong ?
                                                                                <Alert severity="error" sx={{ width: '25rem', display: 'flex', alignItems: 'center' }}>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <span dangerouslySetInnerHTML={{ __html: alternative.text }} style={{ marginRight: '8px' }} />
                                                                                        <span> - Alternativa incorreta!</span>
                                                                                    </div>
                                                                                </Alert>
                                                                                :
                                                                                <label
                                                                                    dangerouslySetInnerHTML={{ __html: alternative.text }}
                                                                                />
                                                                    } />
                                                                </Box>
                                                            )
                                                        })

                                                    }
                                                    {
                                                        questionsAnsweredNow &&
                                                        <>
                                                        <h3 style={{marginTop: '1rem'}}>Justificativa</h3>
                                                         <div dangerouslySetInnerHTML={{ __html:questionsAnsweredNow?.justification ?? "-"}} />  
                                                        <h3 style={{marginTop: '1rem'}}>Porcentagem de acerto</h3>
                                                                <Typography>{getValueInPercentage(questionsAnsweredNow?.correctAnswersAverage)} %</Typography>
                                                        </>
                                                    }

                                                </Box>
                                                
                                            </Box>
                                            </Box>
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                                                    {
                                                        questionsAnsweredNow ?
                                                            <Button onClick={() => {handleGoToNextQuestion()}}>Proxima questão</Button>
                                                            :
                                                            <LoadingButton
                                                        loading={isLoadingAnswerQuestion}
                                                        onClick={handleAnswerQuestion}
                                                    >Responder</LoadingButton>
                                                    }
                                                    
                                                    <Tooltip title={""}>
                                                        <Box>
                                                            <Button onClick={() => handleFinishSession()} >Finalizar estudos</Button>
                                                        </Box>
                                                    </Tooltip>
                                                </Box>
                                        </>
                                    }
                                </>
                        }
                    </Box>
                </Box>
            </Box >
        </>
    );

};

export default AnswerQuestion;