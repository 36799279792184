import React, { useEffect, useState } from 'react';
import { Container, Content, Header, Table, TableContainer, Td, Th, Title } from './styles.js'
import AddAreaModal from './Components/AddModal';
import Menu from "../../components/Header/index.jsx"
import useAuth from '../../hooks/useAuth.js';
import { CompaniesService } from 'api-gen';
import { Button } from '@mui/material';
import { useDesignParams } from 'contexts/DesignParamsContext';


const Subjects = () => {
    const { user } = useAuth();
    const designParams = useDesignParams();

    const [subjects, setSubjects] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [subjectId, setSubjectId] = useState(null)
    const handleOpenModal = () => {
        setModalIsOpen(true);
    }

    const handleCloseModal = () => {
        setSubjectId(null);
        setModalIsOpen(false);
        getSubjects();
    }

    const getSubjects = async () => {
        try {
            if (user?.companyId) {
                const result = await CompaniesService.getApiCompaniesSubjects(user?.companyId);
                if (result && result?.subjects?.length > 0) {
                    setSubjects(result.subjects);
                }
            }
        }
        catch (e) {
            console.log("deuerro:", e);
        }
    };

    useEffect(() => {
        getSubjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const handleEdit = (id) => {
        // Lógica para editar a empresa com o ID fornecido
        setSubjectId(id);
    };

    useEffect(() => {
        if (subjectId) {
            handleOpenModal();
        }
    }, [subjectId])

    const handleDisable = (id) => {
        // Lógica para desativar a empresa com o ID fornecido
        console.log("Desativar empresa com ID:", id);
    };

    return (
        <>
            <Menu />
            <Container>
                <Content>
                    <Header>
                        <Title>Disciplinas</Title>
                        <Button
                         variant='contained'
                         sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
                         onClick={handleOpenModal}>
                            Adicionar
                        </Button>
                    </Header>
                    <TableContainer>
                        <Table>
                            <thead>
                                <tr>
                                    <Th>Descrição</Th>
                                    <Th>Área</Th>
                                    <Th>Editar</Th>
                                    <Th>Desativar</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {subjects && subjects.length > 0 && subjects.map((subject) => (
                                    <tr key={subject.id}>
                                        <Td>{subject.description}</Td>
                                        <Td>{subject.knowledgeArea.acronym}</Td>
                                        <Td>
                                            <Button
                                             variant='contained'
                                             sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
                                             onClick={() => handleEdit(subject.id)}>
                                                Editar
                                            </Button>
                                        </Td>
                                        <Td>
                                            <Button
                                             variant='contained'
                                             sx={{ backgroundColor: designParams?.secondaryColorHex || 'red' }}
                                             onClick={() => handleDisable(subject.id)}
                                             >
                                                Desativar
                                            </Button>
                                        </Td>
                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </TableContainer>
                </Content>



            </Container>
            <AddAreaModal
                isOpen={modalIsOpen}
                onClose={handleCloseModal}
                id={subjectId}
            // Passar os setters como propriedades para o modal
            />
        </>

    );
}

export default Subjects;
