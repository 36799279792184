import styled from 'styled-components';

export const Container = styled.div`
  height: 80px;
  display: flex;
  background-color: #1A202C; 
  box-shadow: 0 0 20px 3px;
  align-items: center;
  justify-content: space-between; 
  padding: 0 20px; 

  > svg {
    color: white;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center; 
  justify-content: center; 
  color: white;
`;

export const Label = styled.label`
  color: 'white';
  font-size: 22px; 
  font-weight: bold; 
`;
