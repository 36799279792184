import React from 'react'
import { Container, Content } from './styles'
import { 
  FaTimes, 
  FaHome,  
  FaUserAlt, 
  FaIdCardAlt, 
  FaRegFileAlt,
  FaRegCalendarAlt, 
  FaSignOutAlt,
  FaAdn,
  FaTag, 
  FaBook,
  FaFile,
  FaBriefcase,
  FaDochub
} from 'react-icons/fa'
import SidebarItem from '../SidebarItem'
import { useNavigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import SettingsIcon from '@mui/icons-material/Settings';
import BookIcon from '@mui/icons-material/Book';

const Sidebar = ({ active }) => {

  const navigate = useNavigate();
  const { user, signout } = useAuth();

  const closeSidebar = () => {
    active(false)
  }

  const handleRedirect = (path) => {
    navigate(path);
  };
  return (
    <Container sidebar={active}>
      <FaTimes onClick={closeSidebar} />  
      <Content>
        
        {user?.role === 1 &&
                <SidebarItem Icon={FaBriefcase} Text="Empresas" onClick={() => handleRedirect('/empresas')} />
        }
        {user?.role === 2 &&
          <>
            <SidebarItem Icon={FaHome} Text="Home" onClick={() => handleRedirect('/home')}/>
            <SidebarItem Icon={FaUserAlt} Text="Alunos" onClick={() => handleRedirect('/alunos')}/>
            <SidebarItem Icon={FaBook} Text="Áreas do Conhecimento" onClick={() => handleRedirect('/areas')} />
            <SidebarItem Icon={FaDochub} Text="Disciplinas" onClick={() => handleRedirect('/subjects')}/>
            <SidebarItem Icon={FaRegCalendarAlt} Text="Questões"onClick={() => handleRedirect('/questoes')} />
            <SidebarItem Icon={FaIdCardAlt} Text="Professores" onClick={() => handleRedirect('/professores')} />
            <SidebarItem Icon={FaAdn} Text="Aplicadoras" onClick={() => handleRedirect('/aplicadoras')}/>
            <SidebarItem Icon={FaTag} Text="Taxonomias" onClick={() => handleRedirect('/taxonomias')} />
            <SidebarItem Icon={SettingsIcon} Text="Trilhas de estudos" onClick={() => handleRedirect('/trilhasestudo')} />
            <SidebarItem Icon={FaRegFileAlt} Text="Conteúdos" onClick={() => handleRedirect('/conteudos')} />
            <SidebarItem Icon={SettingsIcon} Text="Configurações" onClick={() => handleRedirect('/configuracoes')} />
          </>
        }
        {user?.role === 3 &&
          <>
            <SidebarItem Icon={FaHome} Text="Home" onClick={() => handleRedirect('/home')}/>
            {/* <SidebarItem Icon={FaFile} Text="Provas" onClick={() => handleRedirect('/provas')} /> */}
            {/* <SidebarItem Icon={FaFile} Text="Questões" onClick={() => handleRedirect('/questoesalunos')} /> */}
            <SidebarItem Icon={FaFile} Text="Questões" onClick={() => handleRedirect('/sessaodeestudo')} />
            <SidebarItem Icon={BookIcon} Text="Trilhas de Estudo" onClick={() => handleRedirect('/meusestudos')} />
          </>
        }
        { user?.role === 4 &&
          <>
            <SidebarItem Icon={FaHome} Text="Home" onClick={() => handleRedirect('/home')}/>
            <SidebarItem Icon={FaBook} Text="Áreas do Conhecimento" onClick={() => handleRedirect('/areas')} />
            <SidebarItem Icon={FaDochub} Text="Disciplinas" onClick={() => handleRedirect('/subjects')}/>
            <SidebarItem Icon={FaRegCalendarAlt} Text="Questões"onClick={() => handleRedirect('/questoes')} /> 
            <SidebarItem Icon={FaAdn} Text="Aplicadoras" onClick={() => handleRedirect('/aplicadoras')}/>
            <SidebarItem Icon={FaTag} Text="Taxonomias" onClick={() => handleRedirect('/taxonomias')} />
            <SidebarItem Icon={FaRegFileAlt} Text="Conteúdos" onClick={() => handleRedirect('/conteudos')} />
          </>
        }
       
        <SidebarItem Icon={FaSignOutAlt} Text="Sair" onClick={() => [signout(), navigate("/")]}
      />
      </Content>
    </Container>
  )
}

export default Sidebar