/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnswerQuestionFromSomeTopicsAgainCommand } from '../models/AnswerQuestionFromSomeTopicsAgainCommand';
import type { CreateLearningPathCommand } from '../models/CreateLearningPathCommand';
import type { CreateLearningPathLevelCommand } from '../models/CreateLearningPathLevelCommand';
import type { CreateLearningPathLevelTopicSequenceCommand } from '../models/CreateLearningPathLevelTopicSequenceCommand';
import type { DeactiveLearningPathCommand } from '../models/DeactiveLearningPathCommand';
import type { DeactiveLearningPathLevelCommand } from '../models/DeactiveLearningPathLevelCommand';
import type { EditLearningPathLevelCommand } from '../models/EditLearningPathLevelCommand';
import type { GetQuestionsFromNextTopicCommand } from '../models/GetQuestionsFromNextTopicCommand';
import type { GetQuestionsFromNextTopicViewModelResultApiResponse } from '../models/GetQuestionsFromNextTopicViewModelResultApiResponse';
import type { LearningPathInfoStudentViewModelResultApiResponse } from '../models/LearningPathInfoStudentViewModelResultApiResponse';
import type { LearningPathLevelsViewModelResultApiResponse } from '../models/LearningPathLevelsViewModelResultApiResponse';
import type { LearningPathLevelTopicSequenceViewModelListResultApiResponse } from '../models/LearningPathLevelTopicSequenceViewModelListResultApiResponse';
import type { LearningPathViewModelListResultApiResponse } from '../models/LearningPathViewModelListResultApiResponse';
import type { LearningPathViewModelResultApiResponse } from '../models/LearningPathViewModelResultApiResponse';
import type { QuestionsFromLearningPathLevelViewModelResultApiResponse } from '../models/QuestionsFromLearningPathLevelViewModelResultApiResponse';
import type { QuestionViewModelListResultApiResponse } from '../models/QuestionViewModelListResultApiResponse';
import type { UnitResultApiResponse } from '../models/UnitResultApiResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LearningPathService {
    /**
     * @param tenantId Id da Empresa
     * @returns LearningPathViewModelListResultApiResponse Success
     * @throws ApiError
     */
    public static getApiLearningPath(
        tenantId: number,
    ): CancelablePromise<LearningPathViewModelListResultApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/learningPath',
            headers: {
                'TenantId': tenantId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param requestBody
     * @returns LearningPathViewModelResultApiResponse Success
     * @throws ApiError
     */
    public static postApiLearningPath(
        tenantId: number,
        requestBody?: CreateLearningPathCommand,
    ): CancelablePromise<LearningPathViewModelResultApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/learningPath',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param requestBody
     * @returns LearningPathLevelsViewModelResultApiResponse Success
     * @throws ApiError
     */
    public static postApiLearningPathAddLearningPathLevel(
        tenantId: number,
        requestBody?: CreateLearningPathLevelCommand,
    ): CancelablePromise<LearningPathLevelsViewModelResultApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/learningPath/AddLearningPathLevel',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param requestBody
     * @returns LearningPathLevelTopicSequenceViewModelListResultApiResponse Success
     * @throws ApiError
     */
    public static postApiLearningPathAddLearningPathLevelTopicSequence(
        tenantId: number,
        requestBody?: CreateLearningPathLevelTopicSequenceCommand,
    ): CancelablePromise<LearningPathLevelTopicSequenceViewModelListResultApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/learningPath/AddLearningPathLevelTopicSequence',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param requestBody
     * @returns LearningPathLevelsViewModelResultApiResponse Success
     * @throws ApiError
     */
    public static putApiLearningPathEditLearningPathLevel(
        tenantId: number,
        requestBody?: EditLearningPathLevelCommand,
    ): CancelablePromise<LearningPathLevelsViewModelResultApiResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/learningPath/EditLearningPathLevel',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param requestBody
     * @returns UnitResultApiResponse Success
     * @throws ApiError
     */
    public static deleteApiLearningPathRemoveLearningPathLevel(
        tenantId: number,
        requestBody?: DeactiveLearningPathLevelCommand,
    ): CancelablePromise<UnitResultApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/learningPath/RemoveLearningPathLevel',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param requestBody
     * @returns UnitResultApiResponse Success
     * @throws ApiError
     */
    public static deleteApiLearningPathRemoveLearningPath(
        tenantId: number,
        requestBody?: DeactiveLearningPathCommand,
    ): CancelablePromise<UnitResultApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/learningPath/RemoveLearningPath',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param studentId
     * @param companyId
     * @returns LearningPathInfoStudentViewModelResultApiResponse Success
     * @throws ApiError
     */
    public static getApiLearningPathGetLearningPathsStudent(
        tenantId: number,
        studentId?: number,
        companyId?: number,
    ): CancelablePromise<LearningPathInfoStudentViewModelResultApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/learningPath/GetLearningPathsStudent',
            headers: {
                'TenantId': tenantId,
            },
            query: {
                'studentId': studentId,
                'companyId': companyId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param studentId
     * @param companyId
     * @param learningPathLevelId
     * @returns QuestionsFromLearningPathLevelViewModelResultApiResponse Success
     * @throws ApiError
     */
    public static getApiLearningPathGetQuestionsByLearningPathLevel(
        tenantId: number,
        studentId?: number,
        companyId?: number,
        learningPathLevelId?: number,
    ): CancelablePromise<QuestionsFromLearningPathLevelViewModelResultApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/learningPath/GetQuestionsByLearningPathLevel',
            headers: {
                'TenantId': tenantId,
            },
            query: {
                'studentId': studentId,
                'companyId': companyId,
                'learningPathLevelId': learningPathLevelId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param requestBody
     * @returns GetQuestionsFromNextTopicViewModelResultApiResponse Success
     * @throws ApiError
     */
    public static postApiLearningPathGetQuestionsFromNextTopic(
        tenantId: number,
        requestBody?: GetQuestionsFromNextTopicCommand,
    ): CancelablePromise<GetQuestionsFromNextTopicViewModelResultApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/learningPath/GetQuestionsFromNextTopic',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @param tenantId Id da Empresa
     * @param requestBody
     * @returns QuestionViewModelListResultApiResponse Success
     * @throws ApiError
     */
    public static deleteApiLearningPathRemovePendingQuestionsFromLearningPathLevelTopic(
        tenantId: number,
        requestBody?: AnswerQuestionFromSomeTopicsAgainCommand,
    ): CancelablePromise<QuestionViewModelListResultApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/learningPath/RemovePendingQuestionsFromLearningPathLevelTopic',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }
}
