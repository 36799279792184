import React from 'react';
import { useNotification } from '../../services/NotificationContext';
import { NotificationItem, CloseButton, NotificationWrapper, NotificationText, CloseIcon } from './styles';

const NotificationConsumer = () => {
    const { notifications, removeNotification } = useNotification();

    return (
        <NotificationWrapper>
            {notifications.map((notification, index) => (
                <NotificationItem key={index} color={notification.color}>
                    <NotificationText>{notification.message}</NotificationText>
                    <CloseButton onClick={() => removeNotification(notification)}>
                        <CloseIcon />
                    </CloseButton>
                </NotificationItem>
            ))}
        </NotificationWrapper>
    );
};

export default NotificationConsumer;