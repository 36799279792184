import React, { useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import * as C from "./styles";
import { Link, useNavigate } from "react-router-dom";
import InputMask from "../../components/InputMask";
import { cpfMask, removeCaracteres } from "../../util/mask";

import "react-datepicker/dist/react-datepicker.css";

import { UserService } from "../../services/user/UserService";
import { CustomDatePickerInput } from "../../components/Input/styles";
const Signup = () => {
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [emailConf, setEmailConf] = useState("");
  const [senha, setSenha] = useState("");
  const [cpf, setCpf] = useState("");
  const [dataNascimento, setDataNascimento] = useState(new Date());
  const [telefone, setTelefone] = useState("");


  const [error, setError] = useState("");
  
  const navigate = useNavigate();


  const handleSignup = async () => {
    if (!email | !emailConf | !senha) {
      setError("Preencha todos os campos");
      return;
    } else if (email !== emailConf) {
      setError("Os e-mails não são iguais");
      return;
    }


    const body = { 
      fullName: nome, 
      email,
      password: senha, 
      cpf: removeCaracteres(cpf), 
      birthDate: dataNascimento, 
      phoneNumber: telefone, 
      role: 1 
    }



    const user = await UserService.criarUsuario(body);

    if(user.cpf){
      alert("Usuário cadatrado com sucesso!");
      navigate("/");
    }else{
      setError(user);
    }
    
  };

  const handlechangeCPF = (e) => {
    setCpf(cpfMask(e.target.value))
  }

  return (
    <C.Container>
      <C.Label>CADASTRO DE USUÁRIO</C.Label>
      <C.Content>
      <Input
          type="text"
          placeholder="Digite seu nome completo"
          value={nome}
          onChange={(e) => [setNome(e.target.value), setError("")]}
        />
        <Input
          type="email"
          placeholder="Digite seu E-mail"
          value={email}
          onChange={(e) => [setEmail(e.target.value), setError("")]}
        />
        <Input
          type="email"
          placeholder="Confirme seu E-mail"
          value={emailConf}
          onChange={(e) => [setEmailConf(e.target.value), setError("")]}
        />
        <Input
          type="password"
          placeholder="Digite sua Senha"
          value={senha}
          onChange={(e) => [setSenha(e.target.value), setError("")]}
        />

        <InputMask
          placeholder="Digite o CPF"
          value={cpf}
          onChange={(e) => handlechangeCPF(e)}
        />
        
        <div style={{ width: "100%"}}>
          <CustomDatePickerInput
            selected={dataNascimento} 
            onChange={(date) => setDataNascimento(date)} 
          />
        </div>
       

        <Input
          type="text"
          placeholder="Digite o telefone"
          value={telefone}
          onChange={(e) => [setTelefone(e.target.value), setError("")]}
        />

        <C.labelError>{error}</C.labelError>
        
        <Button Text="Inscrever-se" onClick={handleSignup} />
        <C.LabelSignin>
          Já tem uma conta?
          <C.Strong>
            <Link to="/">&nbsp;Entre</Link>
          </C.Strong>
        </C.LabelSignin>
      </C.Content>
    </C.Container>
  );
};

export default Signup;
