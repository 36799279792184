import React, { useEffect, useState } from 'react';
import Modal from 'react-modal'; 
import InputMask from 'react-input-mask'; 
import { useNotification } from '../../../services/NotificationContext';
import useAuth from '../../../hooks/useAuth.js';
import { CreateStudentCommand, StudentsService } from "api-gen";
import { useDesignParams } from 'contexts/DesignParamsContext';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';

Modal.setAppElement('#root');

const AddAlunoModal = ({ isOpen, onClose, id }) => {
  const designParams = useDesignParams();
  const { addNotification } = useNotification();
  const { user } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [socialSecurityNumber, setSocialSecurityNumber] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  // eslint-disable-next-line no-unused-vars 
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [district, setDistrict] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [stateAcronym, setStateAcronym] = useState("");
  const [country, setCountry] = useState("");
  const [countryAcronym, setCountryAcronym] = useState("");
  const [error, setError] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());

  const preencherDados = (dados) => {
    setName(dados.fullName); 

    setSocialSecurityNumber(dados.socialSecurityNumber);
    setPhone(dados.phoneNumber);
    setBirthDate(new Date(dados.birthDate));
    const { address } = dados; 
    setStreet(address.street);
    setNumber(address.number);
    setComplement(address.complement);
    setDistrict(address.district);
    setZipCode(address.zipCode);
    setCity(address.city.name);
    setState(address.city.state.name);
    setStateAcronym(address.city.state.acronym);
    setCountry(address.city.state.country.name);
    setCountryAcronym(address.city.state.country.acronym)
  }

  const getAluno = async () => {
    try {
      const result = await StudentsService.getApiStudents(id, user?.companyId);

      if (result) {
        preencherDados(result?.student);
      }
    } catch (e) {
      addNotification('Erro ao buscar aluno', 'red');
    }
  }

  useEffect(() => {
    if (id) {
      getAluno();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  const handleAddEmpresa = async () => {

    setError("");
    if (id) {

      const body = {
        id,
        fullName: name,
        phoneNumber: phone,
        street,
        number,
        complement,
        district,
        zipCode: handleCleanCep(zipCode ?? ""),
        city,
        state,
        stateAcronym,
        country,
        countryAcronym,
        birthDate: birthDate.toISOString()
      }
      try{
        await StudentsService.putApiStudents(id,user?.companyId, body);

        addNotification('Aluno alterado com sucesso!', 'green');
        handleCloseModal();
      }
      catch(e){
        if (e && e?.status === 400) {
          if (e?.body?.errors) {
            setError(groupErrorStrings(e?.body?.errors));
          } else if (e?.body?.message) {
            const generalError = e?.body?.message;
            setError(generalError);
          }
        } else
        addNotification('Erro ao alterar aluno!', 'red');
      }


    } else {

      const body : CreateStudentCommand = {
        companyId: user.companyId,
        fullName: name,
        phoneNumber: phone,
        street,
        number,
        complement,
        district,
        zipCode : handleCleanCep(zipCode ?? ""),
        city,
        state,
        stateAcronym,
        country,
        countryAcronym,
        password,
        socialSecurityNumber : handleCleanCpf(socialSecurityNumber ?? ""),
        email,
        birthDate: birthDate.toISOString()
      }
      try {
        await StudentsService.postApiStudents(user?.companyId,body);
        addNotification('Aluno inserido com sucesso!', 'green');
        handleCloseModal();
      }
      catch (e) {
        if (e && e?.status === 400) {
          if (e?.body?.errors) {
            setError(groupErrorStrings(e?.body?.errors));
          } else if (e?.body?.message) {
            const generalError = e?.body?.message;
            setError(generalError);
          }
        } else
          addNotification('Erro ao inserir aluno!', 'red');
      }

    }
  }

  const handleCloseModal = () => {
    setEmail("");
    setPassword("");
    setSocialSecurityNumber("");
    setName("");
    setPhone("");
    setStreet("");
    setNumber("");
    setComplement("");
    setDistrict("");
    setZipCode("");
    setCity("");
    setState("");
    setError("");
    onClose();
  };

  const handleCleanCpf = (value) => {
    return value.replace(/\D/g, '');
  };

  const handleCleanCep = (cep: string): string => {
    return cep.replace(/[^\d]/g, '');
  }

  const groupErrorStrings = (object) => {
    let errors = [];
    for (let key in object) {
      if (Array.isArray(object[key]) && object[key].every(item => typeof item === 'string')) {
        errors.push(object[key].join(""));
      }
    }
    return errors.join(" ");
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      fullWidth={true}
      maxWidth="md"
    >
    <DialogTitle>{id ? "Editar Aluno" : "Adicionar Aluno"}</DialogTitle>
      <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '1.5rem'}}>
          <FormControl sx={{marginTop: '1rem'}}>
            <TextField
              label="Nome"
              type="text"
              placeholder="Digite o nome do aluno"
              value={name}
              onChange={(e) => setName(e.target.value)} />
          </FormControl>
          {
            !id &&
            <FormControl>
              <TextField
                label="Email da Aluno"
                type="email"
                placeholder="Digite o email do aluno"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={id ? true : false}
              />
            </FormControl>
          }
          {
            !id &&
            <FormControl>
              <TextField
                label="Senha"
                type="password"
                placeholder="Digite a senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={id ? true : false}
              />
            </FormControl>
          }

          <FormControl>
            <TextField
              label="Telefone"
              type="text"
              placeholder="Digite o telefone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)} />
          </FormControl>

          <FormControl>
            <TextField
              label="CPF"
              type="text"
              placeholder="Digite o CPF"
              value={socialSecurityNumber}
              onChange={(e) => setSocialSecurityNumber(e.target.value)}
              disabled={id ? true : false}
              InputProps={{
                inputComponent: InputMask,
                inputProps: {
                  mask: "999.999.999-99",
                },
              }}
            />
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br"> 
            <DatePicker 
              label="Data de nascimento"
              value={dayjs(birthDate)}
              onChange={(date) => setBirthDate(date ? date.toDate() : undefined)} 
            /> 
          </LocalizationProvider>
          <FormControl>
            <TextField
              label="Rua"
              placeholder='Digite a rua'
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </FormControl>
          <FormControl>

            <TextField
              label={"Número"}
              placeholder="Digite o número"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <TextField
              label={"Complemento"}
              placeholder="Digite o complemento"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
            />
          </FormControl>
          
          <FormControl> 
            <TextField
              label={"Bairro"}
              placeholder="Digite o bairro"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            />
          </FormControl>
          
          <FormControl>
            <TextField
              label={"Cep"}
              placeholder="Digite o CEP"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              InputProps={{
                inputComponent: InputMask,
                inputProps: {
                  mask: "99999-999",
                },
              }}
            />
          </FormControl>
          
          <FormControl> 
            <TextField
              label={"Cidade"}
              placeholder="Digite a cidade"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </FormControl>
          
          <FormControl>
            <TextField
              label={"Estado"}
              placeholder="Digite o estado"
              value={state}
              onChange={(e) => setState(e.target.value)}
              />
          </FormControl>

        </DialogContent>
            { error &&
              <Alert severity="error">{error}</Alert>
            }
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '1rem' }}> 
            <Button
              variant='contained'
              style={{ backgroundColor: designParams?.secondaryColorHex || 'red' }}
              onClick={handleCloseModal}>Cancelar</Button> 
            <Button
              variant='contained'
              style={{ backgroundColor: designParams?.primaryColorHex || '' }}
              onClick={handleAddEmpresa}> {id ? "Alterar" : "Adicionar"}</Button>
        </DialogActions>
    </Dialog>
  );
}

export default AddAlunoModal;