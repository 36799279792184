/* eslint-disable */

import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { CompaniesService, DegreeAreasService, ExaminationsService, ExpertiseAreasService, JobPositionsService, KnowledgeAreasService, SubjectsService } from "api-gen";
import React, { useEffect } from "react";
import { useNotification } from '../../../services/NotificationContext';
import dayjs from "dayjs"; 
interface FilterQuestionsProps {
    filters: any;
    setFilters: any;
    filtersTextField: any;
    setFiltersTextField: any;
    user: any;
    handleApplyFilters: any;
};
const FilterQuestions = (props: FilterQuestionsProps) => {
    const { addNotification } = useNotification(); 


    const handleChange = async (event) => {
        const { name, value } = event.target;
        const prevValue = props.filters[name];

        if (Array.isArray(value)) {
            prevValue.selectValuesIds = value.filter((item) => item !== 0);
        } else {
            prevValue.selectValuesIds = value;
        }





        if (name === "areaConhecimento" && value != 0) {

            var subjects = await getAllSubjects(value);

            props.setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: prevValue,
                disciplina: {
                    ...prevFilters.disciplina,
                    values: subjects,
                    selectValuesIds: [],
                },
                conteudo: {
                    ...prevFilters.conteudo,
                    selectValuesIds: [],
                }
            }));
        } else if (name === "disciplina" && value != 0) {
            var topics = await getAllTopics(value);
            props.setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: prevValue,
                conteudo: {
                    ...prevFilters.conteudo,
                    values: topics,
                    selectValuesIds: [],
                }
            }));
        }
        else {
            props.setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: prevValue,
            }));
        }


    };


    const getAllExamBoards = async () => {
        try {
            var response = await CompaniesService.getApiCompaniesExamBoards(props.user?.companyId);
            if (response && response?.examBoards) {
                const allExamBoards = response?.examBoards?.map((examBoard) => {
                    return {
                        id: examBoard.id,
                        name: examBoard.name,
                    }
                })
                return allExamBoards;
            }
            return [];
        } catch (e) {
            addNotification("Erro ao carregar aplicadoras", "#bb2124");
        }
    };

    const getAllKnowledgeAreas = async () => {
        //setIsLoadingKnowLedgeAreas(true);
        try {
            if (!props.user?.companyId) {
                return [];
            }
            var response = await CompaniesService.getApiCompaniesKnowledgeAreas(props.user?.companyId);
            if (response && response?.knowledgeAreas) { 
                const allKnowLedgeAreas = response?.knowledgeAreas?.map((area) => {
                    return {
                        id: area.id,
                        name: area.description,
                    }
                })
                return allKnowLedgeAreas;
            }
            return [];

        } catch (e) {
            addNotification("Erro ao carregar áreas de conhecimento", "#bb2124");
        }
        //setIsLoadingKnowLedgeAreas(false);
    };

    const getAllTopics = async (idSubject) => {
        try {
            if (!props.user?.companyId) {
                return [];
            }
            var response = await SubjectsService.getApiSubjectsTopics(idSubject, props.user?.companyId);
            if (response && response?.topics) {
                const allTopics = response?.topics?.map((topic) => {
                    return {
                        id: topic.id,
                        name: topic.description,
                    }
                });

                return allTopics;
            }
            return [];
        } catch (e) {
            addNotification("Erro ao carregar tópicos", "#bb2124");
        }
    };

    const getAllSubjects = async (idKnowledgeAreas) => {
        try {
            var response = await KnowledgeAreasService.getApiKnowledgeAreasSubjects(idKnowledgeAreas, props.user?.companyId);
            if (response && response?.subjects) {
                const allSubjects = response?.subjects?.map((subject) => {
                    return {
                        id: subject.id,
                        name: subject.description,
                    }
                })
                return allSubjects;
            }
            return [];

        }
        catch (e) {
            addNotification("Erro ao carregar disciplinas", "#bb2124");
        }
    };

    const getAllTaxonomies = async () => {
        try {
            if (props.user) {
                var response = await CompaniesService.getApiCompaniesTaxonomies(props.user?.companyId); 

                const allTaxonomies = response?.taxonomies?.map((taxonomy) => {
                    return {
                        id: taxonomy.id,
                        name: taxonomy.level,
                    }
                })

                return allTaxonomies;
            }
            return [];

        } catch (e) {
            addNotification("Erro ao carregar taxonomias", "#bb2124");
        }
    };

    const getAllJobsPositions = async () => {
        try {
            var response = await JobPositionsService.getApiJobPositions();
            return response?.jobPositions;
        } catch (e) {
            addNotification("Erro ao carregar cargos", "#bb2124");
        }
    };

    const getAllExpertiseAreas = async () => {
        try {
            var response = await ExpertiseAreasService.getApiExpertiseAreas();
            return response?.expertiseAreas;
        } catch (e) {
            addNotification("Erro ao carregar áreas de atuação", "#bb2124");
        }
    };

    const getAllDegreeAreas = async () => {
        try {
            var response = await DegreeAreasService.getApiDegreeAreas();
            return response?.degreeAreas;
        } catch (e) {
            addNotification("Erro ao carregar áreas de formação", "#bb2124");
        }
    };

    const getAllExaminations = async () => {
        try {
            var response = await ExaminationsService.getApiExaminations();
            return response?.examinations;
        } catch (e) {
            addNotification("Erro ao carregar Concursos", "#bb2124");
        }
    };

    const getAllData = async () => {
        if (props.user) {
            var knoeLedgeAreas = await getAllKnowledgeAreas();

            var taxonomies = await getAllTaxonomies();
            var examBoards = await getAllExamBoards();
            var examinations = await getAllExaminations();
            var jobPositions = await getAllJobsPositions();
            var expertiseAreas = await getAllExpertiseAreas();
            var degreeAreas = await getAllDegreeAreas();
            // var subjects = await getAllSubjects();
            // var topics = await getAllTopics(); 

            var areaDeConhecimento = undefined;
            var taxonomia = undefined;
            var aplicadora = undefined;
            var disciplina = undefined;
            var conteudo = undefined;
            var concurso = undefined;
            var cargo = undefined;
            var areaDeAtuacao = undefined;
            var areaDeFormacao = undefined;


            if (props.filters?.areaConhecimento)
                areaDeConhecimento = {
                    ...props.filters.areaConhecimento,
                    values: knoeLedgeAreas,
                    selectValuesIds: [],
                };

            if (props.filters?.taxonomia)
                taxonomia = {
                    ...props.filters.taxonomia,
                    values: taxonomies,
                    selectValuesIds: [],
                };

            if (props.filters?.aplicadora)
                aplicadora = {
                    ...props.filters.aplicadora,
                    values: examBoards,
                    selectValuesIds: [],
                };

            if (props.filters?.disciplina)
                disciplina = {
                    ...props.filters.disciplina,
                    selectValuesIds: [],
                };

            if (props.filters?.conteudo)
                conteudo = {
                    ...props.filters.conteudo,
                    selectValuesIds: [],
                };

            if (props.filters?.concurso)
                concurso = {
                    ...props.filters.concurso,
                    values: examinations,
                    selectValuesIds: [],
                }

            if (props.filters?.cargo)
                cargo = {
                    ...props.filters.cargo,
                    values: jobPositions,
                    selectValuesIds: [],
                }

            if (props.filters?.areaAtuacao)
                areaDeAtuacao = {
                    ...props.filters.areaAtuacao,
                    values: expertiseAreas,
                    selectValuesIds: [],
                }
            if (props.filters?.areaFormacao)
                areaDeFormacao = {
                    ...props.filters.areaFormacao,
                    values: degreeAreas,
                    selectValuesIds: [],
                }

            props.setFilters({
                ...props.filters,
                areaConhecimento: areaDeConhecimento,
                taxonomia: taxonomia,
                aplicadora: aplicadora,
                disciplina: disciplina,
                conteudo: conteudo,
                concurso: concurso,
                cargo: cargo,
                areaAtuacao: areaDeAtuacao,
                areaFormacao: areaDeFormacao
            });
        }
    };

    useEffect(() => {
        getAllData();
        // eslint-disable-next-line
    }, [props.user]);

    const handleCleanFilters = () => {
        const previousFilter = props.filters;
        const newFilter = { ...previousFilter };

        const previousOtherFilter = props.filtersTextField;
        const newOtherFilter = { ...previousOtherFilter };

        Object.keys(newFilter).forEach((key) => {
            if (key != "filterQuestionType") {
                if (newFilter[key].isUnique) {
                    newFilter[key].selectValuesIds = 0;
                } else {
                    newFilter[key].selectValuesIds = [];
                }
            }
        });

        newOtherFilter.questionYear = '';
        newOtherFilter.questionCode = '';
        props.setFiltersTextField(newOtherFilter);
        props.setFilters(newFilter);
    };

    return (
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginTop: '1.5rem' }}>
            <Alert severity="warning"> Preencha a área de conhecimento  para poder filtrar a disciplina e o conteúdo</Alert>
            <Typography variant="h6" gutterBottom>
                Filtros
            </Typography>
            <Grid container spacing={3}>
                {Object.keys(props.filters).map((key) => (
                    props.filters[key]?.label &&
                    <Grid item xs={12} sm={6} md={4} key={key}>
                        {
                            ((props.filters[key]?.label === "Disciplina" && props.filters?.areaConhecimento?.selectValuesIds == 0) || (props.filters[key]?.label === "Conteúdo" && props.filters?.disciplina?.selectValuesIds == 0)) ?
                                <>
                                </> :
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>{props.filters[key].label}</InputLabel>
                                    {
                                        props.filters[key].isUnique ?
                                            <Select
                                                id={key}
                                                name={key}
                                                value={props.filters[key]?.selectValuesIds || 0}
                                                onChange={handleChange}
                                                label={props.filters[key].label}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            height: 200, 
                                                        },
                                                    },
                                                  }}
                                            >
                                                {
                                                    props.filters[key]?.values?.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))
                                                }

                                            </Select> :
                                            <Select
                                                id={key}
                                                name={key}
                                                multiple
                                                value={props.filters[key]?.selectValuesIds || []}
                                                onChange={handleChange}
                                                label={props.filters[key].label}
                                                MenuProps={{
                                                    PaperProps: {
                                                      style: {
                                                        height: 200, 
                                                      },
                                                    },
                                                  }}
                                            >
                                                {
                                                    props.filters[key]?.values?.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))
                                                }

                                            </Select>

                                    }

                                </FormControl>
                        }

                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            id="year"
                            label="Ano da questão"
                            inputProps={{ min: 1900, step: 9999 }}
                            type='number'
                            value={props.filtersTextField?.questionYear || ''}
                            onChange={(e) => {
                                var value = Number(e.target.value);
                                if (value > 9999) {
                                    value = 9999;
                                }
                                props.setFiltersTextField({
                                    ...props.filtersTextField,
                                    questionYear: value
                                })
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            label="Código da Questão"
                            name="questionCode"
                            value={props.filtersTextField?.questionCode || ''}
                            onChange={(e) => props.setFiltersTextField({ ...props.filtersTextField, questionCode: e.target.value })}
                        />
                    </FormControl>
                </Grid>
                {
                    (!props.filters?.filterQuestionType?.selectValuesIds || props.filters?.filterQuestionType?.selectValuesIds === 1)  &&
                    (
                        <>
                            <Grid item xs={12} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        label="Data Inicial"
                                        value={dayjs(props.filtersTextField?.startDate)}
                                        onChange={(date) => props.setFiltersTextField(
                                            {
                                                ...props.filtersTextField,
                                                startDate: date ? date.toDate() : undefined
                                            })}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        label="Data Final"
                                        value={dayjs(props.filtersTextField?.endDate)}
                                        onChange={(date) => props.setFiltersTextField(
                                            {
                                                ...props.filtersTextField,
                                                endDate: date ? date.toDate() : undefined
                                            })}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </>
                    )
                }
            </Grid>
            <Box mt={3} display="flex" justifyContent="flex-end" gap='1rem'>
                <Button onClick={handleCleanFilters}>
                    Limpar Filtros
                </Button>
                <Button variant="contained" color="primary" onClick={() => { props.handleApplyFilters() }}
                >
                    Aplicar Filtros
                </Button>
            </Box>
        </Paper>

    )
};

export default FilterQuestions;