/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnswerExerciseQuestionCommand } from '../models/AnswerExerciseQuestionCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExerciseQuestionsService {
    /**
     * Obtém 25 questões de um determinado conteúdo para uma sessão de exercícios.
     * @param tenantId Id da Empresa
     * @param topicId Id do conteúdo
     * @param studentId Id do aluno
     * @returns any Success
     * @throws ApiError
     */
    public static getApiExerciseQuestions(
        tenantId: number,
        topicId?: number,
        studentId?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/exerciseQuestions',
            headers: {
                'TenantId': tenantId,
            },
            query: {
                'topicId': topicId,
                'studentId': studentId,
            },
        });
    }
    /**
     * Submete uma questão de exercício.
     * Exemplo:
     * { "questionId": 1234, "selectedAlternativeId": 1515, "startTime": "2024-05-01T10:00:00.000Z", "endTime": "2024-05-01T10:01:00.000Z", "studentId": 25 }
     * @param tenantId Id da Empresa
     * @param requestBody Command contendo as informações para submeter uma questão de exercício.
     * @returns any Success
     * @throws ApiError
     */
    public static postApiExerciseQuestions(
        tenantId: number,
        requestBody?: AnswerExerciseQuestionCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/exerciseQuestions',
            headers: {
                'TenantId': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
