import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth.js';
import Menu from "../../components/Header";
import { Container, Content, Header, Table, TableContainer, Td, Th, Title } from './styles.js'; 
import { useNotification } from '../../services/NotificationContext';
import StartExamModal from './Components/StartExamModal';
import { ExamStatusEnum, ExamViewModel } from '../../customTypes/ExamViewModel';
import CreateExamModal from './Components/CreateExamModal';
import { ViewExamResultModal } from './Components/ViewExamResultModal';
import LoadingButton from '@mui/lab/LoadingButton';
import { ExamsService, StudentsService } from 'api-gen';
import { useDesignParams } from 'contexts/DesignParamsContext';
import { Button } from '@mui/material';

const Exams = () => {

    const { user }: any = useAuth();
    const { addNotification } = useNotification();
    const designParams = useDesignParams();

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [examIdLoanding, setExamIdLoanding] = useState<Number>(0);
    const [modalCreateExamIsOpen, setModalCreateExamIsOpen] = useState<boolean>(false);
    const [examsList, setExamsList] = useState<ExamViewModel[]>([]);
    const [examSelected, setExamSelected] = useState<ExamViewModel>();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [examSelectIsConcluded, setExamSelectIsConcluded] = useState<boolean>(false);
    const [examToViewResult, setExamToViewResult] = useState<ExamViewModel>();
    const [examToViewResultModalIsOpen, setExamToViewResultModalIsOpen] = useState<boolean>(false);
    const handleCloseResultViewModal = () => {
        setExamToViewResult(undefined);
        setExamToViewResultModalIsOpen(false);
    };

    const handleStartExam = async (exam: ExamViewModel) => { 
        setExamIdLoanding(exam.id);
        if(exam.status === ExamStatusEnum.finished){
            setExamToViewResult(exam);
            setExamToViewResultModalIsOpen(true); 
            return;
        }

        let currentQuestion = 0;
        setIsLoading(true);
        if (!exam.startTime) {
            try {
                await ExamsService.postApiExamsStartExam(exam.id, user?.companyId);
                
                await ExamsService.postApiExamsQuestionsStartSolvingQuestion(exam.id, exam.questions[0].id, user?.companyId);
            }
            catch (e) {
                addNotification("Erro ao iniciar prova.", "red");
                setIsLoading(false);
                return;
            }
        }
        else
            currentQuestion = await getCurrentQuestion(exam);

        setCurrentQuestionIndex(currentQuestion);
        setExamSelected(exam);
        setModalIsOpen(true);
        setIsLoading(false);
    }

    const handleOpenModalCreateExam = () => {
        setModalCreateExamIsOpen(true);
    }

    const handleCloseModalCreateExam = () => {
        setModalCreateExamIsOpen(false);
    };



    const getCurrentQuestion = async (exam: ExamViewModel) => {
        const currentQuestion = exam.questions.findIndex(question => !question.selectedAlternativeId);
        if (currentQuestion === -1 && exam.endTime) {
            setExamSelectIsConcluded(true);
        }

        if (!exam.questions[currentQuestion]?.startTime && currentQuestion !== -1) {
            try {
                await ExamsService.postApiExamsQuestionsStartSolvingQuestion(exam.id, exam.questions[currentQuestion].id, user?.companyId);
            }
            catch (e) {
                addNotification("Erro ao iniciar resolução da questão.", "red");
                return currentQuestion > 0 ? currentQuestion - 1 : 0;
            }
        }
        return currentQuestion === -1 ? exam.questions.length - 1 : currentQuestion;
    }

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setExamSelected(undefined);
        setExamSelectIsConcluded(false);
        setCurrentQuestionIndex(0);
    }


    const getExams = async () => {

        try {
            if (user?.studentId && user?.companyId) {
                const result = await StudentsService.getApiStudentsExams(user?.studentId, user?.companyId);
                if (result && result.exams) {
                    setExamsList(result.exams);
                }

            }
        }
        catch (e) {
            addNotification("Erro ao recuperar provas.", "red");
        }
    };

    useEffect(() => {
        getExams();
        // eslint-disable-next-line
    }, [user]);

    return (
        <>
            <Menu />
            <Container>
                <Content>
                    <Header>
                        <Title>Provas</Title>
                        <Button
                         variant='contained'
                         sx={{backgroundColor: designParams?.primaryColorHex || ''}}
                         onClick={handleOpenModalCreateExam}>
                            Adicionar Prova
                         </Button>
                    </Header>
                    <TableContainer>
                        <Table>
                            <thead>
                                <tr>
                                    <Th>Descrição</Th>
                                    <Th>Realizar Prova</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {examsList?.map((exam) => (
                                    <tr key={exam.id}>
                                        <Td>{exam?.description}</Td>
                                        <Td> 
                                            <LoadingButton
                                                onClick={() => { handleStartExam(exam) }}
                                                loading={isLoading && examIdLoanding === exam.id}
                                                variant="contained"
                                                sx={{width: "15rem", backgroundColor: designParams?.primaryColorHex || ''}}
                                            >
                                                {
                                                exam.status === ExamStatusEnum.finished ? "Ver Resultado" :
                                                exam.status === ExamStatusEnum.started ? "Continuar Prova" : "Começar Prova"
                                                } 
                                            </LoadingButton> 
                                        </Td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </TableContainer>
                </Content>
            </Container>
            {
                examSelected &&
                <StartExamModal
                    isOpen={modalIsOpen}
                    setExams={setExamsList}
                    onClose={handleCloseModal}
                    exam={examSelected as ExamViewModel}
                    currentQuestionIndex={currentQuestionIndex}
                    examIsConcluded={examSelectIsConcluded}
                    setExamToViewResult={setExamToViewResult}
                    setExamToViewResultModalIsOpen={setExamToViewResultModalIsOpen}
                />
            }
            {
                <CreateExamModal
                    isOpen={modalCreateExamIsOpen}
                    onClose={handleCloseModalCreateExam}
                    setExams={setExamsList}
                />
            }

            {
                examToViewResult &&
                <ViewExamResultModal
                    isOpen={examToViewResultModalIsOpen}
                    exam={examToViewResult}
                    onClose={handleCloseResultViewModal}
                    currentQuestionIndex={0}
                />
            }

        </>

    );
}

export default Exams;
