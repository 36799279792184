/* eslint-disable */
import Menu from "../../components/Header/index.jsx"
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAuth from '../../hooks/useAuth.js';
import { CompaniesService, GetExerciseQuestionsByStudentQuery, QuestionTypeFilterEnum, StudentsService } from "api-gen";
import QuestionAnswered from "pages/QuestionsAnswered/Components/QuestionAnswered";
import StartStudySessionModal from "./Components/StartStudySessionModal";
import { useDesignParams } from "contexts/DesignParamsContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AnswerQuestion from "./Components/AnswerQuestions";
import FilterQuestions from "./Components/FilterQuestions";
import { useNotification } from '../../services/NotificationContext'; 
import AnswerUniqueQuestion from "./Components/AnswerUniqueQuestion";



const StudySession = () => {
    const [questions, setQuestions] = React.useState([]);
    const { user } = useAuth();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selectedTopic, setSelectedTopic] = React.useState();
    const [isAnswerQuestionOpen, setIsAnswerQuestionOpen] = React.useState(false);
    const [filterIsOpen, setFilterIsOpen] = React.useState(false);
    const designParams = useDesignParams();
    const { addNotification } = useNotification(); 
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [allPages, setAllPages] = useState(1); 
    const [isAnsweredQuestions, setIsAnsweredQuestions] = React.useState(true);
    const [isOpenModalAnswerUniqueQuestion, setIsOpenModalAnswerUniqueQuestion] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<any>(undefined);
    const [isLoadingAllQuestions, setIsLoadingAllQuestions] = useState(false); 

    const [filters, setFilters]: any = useState({
        areaConhecimento: {
            values: [],
            selectValuesIds: 0,
            label: 'Área de Conhecimento',
            isUnique: true
        },
        disciplina: {
            values: [],
            selectValuesIds: 0,
            label: 'Disciplina',
            isUnique: true
        },
        conteudo: {
            values: [],
            selectValuesIds: 0,
            label: 'Conteúdo',
            isUnique: true
        },
        concurso: {
            values: [],
            selectValuesIds: [],
            label: 'Concurso',
        },
        cargo: {
            values: [],
            selectValuesIds: [],
            label: 'Cargo',
        },
        areaAtuacao: {
            values: [],
            selectValuesIds: [],
            label: 'Área de Atuação',
        },
        areaFormacao: {
            values: [],
            selectValuesIds: [],
            label: 'Área de Formação',
        },
        aplicadora: {
            values: [],
            selectValuesIds: [],
            label: 'Aplicadora',
        },
        taxonomia: {
            values: [],
            selectValuesIds: [],
            label: 'Dificuldade',
        }
    });

    const localISOTime = (date,addYour) => {
        if(addYour) {
            date.setHours(date.getHours() + 1)
        }
        const options: any = {
            timeZone: 'America/Sao_Paulo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            fractionalSecondDigits: 3,
            hourCycle: 'h23'
        };

        const formatter = new Intl.DateTimeFormat('sv-SE', options);
        const parts = formatter.formatToParts(date);
        const year = parts.find(p => p.type === 'year').value;
        const month = parts.find(p => p.type === 'month').value;
        const day = parts.find(p => p.type === 'day').value;
        const hour = parts.find(p => p.type === 'hour').value;
        const minute = parts.find(p => p.type === 'minute').value;
        const second = parts.find(p => p.type === 'second').value;
        const millisecond = '000';

        const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;

        return formattedDate;
    };
 

    const inicialDateStart = new Date().setDate(new Date().getDate() - 15);
    const [filtersTextField, setFiltersTextField] = useState({
        questionYear: undefined,
        questionCode: "",
        startDate: localISOTime(inicialDateStart,false),
        endDate: localISOTime(new Date(),false),
    });

    const handleApplyFilters = async () => {
        setPage(1);
        setAllPages(1);
        setQuestions([]);

        const body: GetExerciseQuestionsByStudentQuery = {
            studentId: user?.studentId,
            companyId: user?.companyId,
            examBoardsIds: filters?.aplicadora?.selectValuesIds?.length > 0 ? filters?.aplicadora?.selectValuesIds as number[] : [],
            knowledgeAreasIds: filters?.areaConhecimento?.selectValuesIds != 0 ? [filters?.areaConhecimento?.selectValuesIds] as number[] : [],
            subjectsIds: filters?.disciplina?.selectValuesIds != 0 ? [filters?.disciplina?.selectValuesIds] as number[] : [],
            topicsIds: filters?.conteudo?.selectValuesIds != 0 ? [filters?.conteudo?.selectValuesIds] as number[] : [],
            taxonomiesIds: filters?.taxonomia?.selectValuesIds ? filters?.taxonomia?.selectValuesIds as number[] : [],
            examinationsIds: filters?.concurso?.selectValuesIds ? filters?.concurso?.selectValuesIds as number[] : [],
            jobPositionsIds: filters?.cargo?.selectValuesIds ? filters?.cargo?.selectValuesIds as number[] : [],
            degreeAreasIds: filters?.areaFormacao?.selectValuesIds ? filters?.areaFormacao?.selectValuesIds as number[] : [],
            expertiseAreasIds: filters?.areaAtuacao?.selectValuesIds ? filters?.areaAtuacao?.selectValuesIds as number[] : [],
            year: filtersTextField?.questionYear ? filtersTextField?.questionYear : null,
            startDate: localISOTime(new Date(filtersTextField?.startDate), true),
            endDate: localISOTime(new Date(filtersTextField?.endDate), true),
            questionCode  : filtersTextField?.questionCode ? filtersTextField?.questionCode : null,
            questionType: filters?.filterQuestionType?.selectValuesIds == 2 ? QuestionTypeFilterEnum._2 : QuestionTypeFilterEnum._1,
            page: 1,
            pageSize: 20
        };
        await getAllQuestionsWithFilters(body);
    };



    const getAllQuestionsWithFilters = async (body: GetExerciseQuestionsByStudentQuery, concatenedValues: boolean = false) => {
        if (page > allPages)
            return;
        try {
            if (!user?.studentId || !user?.companyId) {
                return;
            }
            setLoading(true);
            var response = await StudentsService.postApiStudentsQuestions(user?.studentId, user?.companyId, body);
            const questionsPrev = questions;
            setQuestions([]);
            if (concatenedValues) {
                setQuestions([...questionsPrev, ...response?.questions?.questions]);
            } else
                setQuestions(response?.questions?.questions);

            if (response?.questions?.total > 0) {
                let totalPages = Math.ceil(response?.questions?.total / 20);
                setAllPages(totalPages);
            } 

            if (filters?.filterQuestionType?.selectValuesIds == 2) {
                setIsAnsweredQuestions(false);
            } else {
                setIsAnsweredQuestions(true);
            }
        } catch (e) {
            addNotification('Erro ao filtrar questões', 'error');
        };

        setLoading(false);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const getCompanyParameters = async () => {
        try {
            if (user?.companyId) {
                var response = await CompaniesService.getApiCompaniesParameters(user?.companyId);

                if (response?.companyParameters?.allowQuestionCatalog) {
                    setFilters((prev) => ({
                        ...prev, filterQuestionType: {
                            values: [
                                {
                                    id: 1,
                                    name: 'Sim'
                                },
                                {
                                    id: 2,
                                    name: 'Não'
                                }
                            ],
                            selectValuesIds: 1,
                            label: 'Questões respondidas?',
                            isUnique: true
                        }
                    }));
                }
 
            }
        } catch (e) {
            addNotification('Erro ao buscar configurações', 'red');
        }
    };

    useEffect(() => {
        getAllQuestion();
        getCompanyParameters();
        // eslint-disable-next-line
    }, [user]);


    useEffect(() => {
        const fetchItems = async () => {
            const body: GetExerciseQuestionsByStudentQuery = {
                studentId: user?.studentId,
                companyId: user?.companyId,
                examBoardsIds: filters?.aplicadora?.selectValuesIds?.length > 0 ? filters?.aplicadora?.selectValuesIds as number[] : [],
                knowledgeAreasIds: filters?.areaConhecimento?.selectValuesIds != 0 ? [filters?.areaConhecimento?.selectValuesIds] as number[] : [],
                subjectsIds: filters?.disciplina?.selectValuesIds != 0 ? [filters?.disciplina?.selectValuesIds] as number[] : [],
                topicsIds: filters?.conteudo?.selectValuesIds != 0 ? [filters?.conteudo?.selectValuesIds] as number[] : [],
                taxonomiesIds: filters?.taxonomia?.selectValuesIds ? filters?.taxonomia?.selectValuesIds as number[] : [],
                examinationsIds: filters?.concurso?.selectValuesIds ? filters?.concurso?.selectValuesIds as number[] : [],
                jobPositionsIds: filters?.cargo?.selectValuesIds ? filters?.cargo?.selectValuesIds as number[] : [],
                degreeAreasIds: filters?.areaFormacao?.selectValuesIds ? filters?.areaFormacao?.selectValuesIds as number[] : [],
                expertiseAreasIds: filters?.areaAtuacao?.selectValuesIds ? filters?.areaAtuacao?.selectValuesIds as number[] : [],
                year: filtersTextField?.questionYear ? filtersTextField?.questionYear : null,
                startDate: localISOTime(new Date(filtersTextField?.startDate), true),
                endDate: localISOTime(new Date(filtersTextField?.endDate), true),
                //mudar question code para string
                //questionCode  : filtersTextField?.questionCode ? filtersTextField?.questionCode : null,
                questionType: filters?.filterQuestionType?.selectValuesIds == 2 ? QuestionTypeFilterEnum._2 : QuestionTypeFilterEnum._1,
                page: page,
                pageSize: 20
            };
            await getAllQuestionsWithFilters(body, true);
        };

        fetchItems();
    }, [page]);


    const getAllQuestion = async () => {
        try {
            if (!user?.studentId || !user?.companyId) {
                return;
            }

            const body: GetExerciseQuestionsByStudentQuery = {
                studentId: user?.studentId,
                companyId: user?.companyId,
                examBoardsIds: [],
                knowledgeAreasIds: [],
                subjectsIds: [],
                topicsIds: [],
                taxonomiesIds: [],
                examinationsIds: [],
                jobPositionsIds: [],
                degreeAreasIds: [],
                expertiseAreasIds: [],
                year: null,
                startDate: localISOTime(inicialDateStart, false),
                endDate: localISOTime(new Date(), false),
                questionCode: null,
                questionType: QuestionTypeFilterEnum._1,
                page: 1,
                pageSize: 20
            };

            var response = await StudentsService.postApiStudentsQuestions(user?.studentId, user?.companyId, body);

            response?.questions?.questions?.length > 0 ? setQuestions(response?.questions?.questions) : setQuestions([]);

            if (response?.questions?.total > 0) {
                let totalPages = Math.ceil(response?.questions?.total / 20);
                setAllPages(totalPages);
            } 

        } catch (e) {
            addNotification('Erro ao filtrar questões', 'error');
        };
    };

    const handleBackToQuestions = async () => { 
        setIsLoadingAllQuestions(true);
        setIsAnswerQuestionOpen(false);
        setQuestions([]);
        if (filters?.filterQuestionType?.selectValuesIds == 2) {
            setFilters((prev) => ({
                ...prev, filterQuestionType: {
                    values: [
                        {
                            id: 1,
                            name: 'Sim'
                        },
                        {
                            id: 2,
                            name: 'Não'
                        }
                    ],
                    selectValuesIds: 1,
                    label: 'Questões respondidas?',
                    isUnique: true
                }
            }));
        }
        setCurrentQuestion(undefined);
        setIsAnsweredQuestions(true);
        await getAllQuestion();
        setIsLoadingAllQuestions(false);
    }; 


    const handleScroll = () => {
        const { scrollTop, scrollHeight } = document.documentElement;

        if (window.innerHeight + scrollTop >= scrollHeight - 5 && !loading) {
            setPage(prevPage => prevPage + 1);
        }
    };


    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);

    }, [loading]);

    return (
        <>
            <Menu />

            {
                 isOpenModalAnswerUniqueQuestion ?
                 <AnswerUniqueQuestion
                     question={currentQuestion}
                     setIsOpenModalAnswerUniqueQuestion={setIsOpenModalAnswerUniqueQuestion}
                     backToQuestions={handleBackToQuestions}
                 />
                 :
                isAnswerQuestionOpen && selectedTopic ?
                    <AnswerQuestion
                        topicId={selectedTopic}
                        setIsAnsweringQuestion={handleBackToQuestions}
                    /> :
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: 'calc(100vh - 80px)' }}>
                        <Box sx={{ width: '70%', marginTop: '2rem' }}>
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h1>Questões </h1>
                                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                                        <Button onClick={() => { setFilterIsOpen(!filterIsOpen) }}> {filterIsOpen ? (<>Fechar Filtro  <ExpandLessIcon /></>) : (<>Abrir Filtro <ExpandMoreIcon /></>)}</Button>
                                        <Button variant="contained" onClick={() => { setModalIsOpen(true) }}> Iniciar nova sessão de estudos</Button>
                                    </Box>

                                </Box>
                                {
                                    filterIsOpen && (
                                        <FilterQuestions
                                            filters={filters}
                                            setFilters={setFilters}
                                            filtersTextField={filtersTextField}
                                            setFiltersTextField={setFiltersTextField}
                                            user={user}
                                            handleApplyFilters={handleApplyFilters}
                                        />
                                    )
                                }
                            </Box>
                            <Box
                                id="questions-answered"
                                sx={{
                                    width: '100%',
                                    marginTop: '2rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '2rem',
                                    marginBottom: '5rem'
                                }}
                            >
                                {
                                    questions?.length === 0 && !loading && !isLoadingAllQuestions ?
                                        <Alert severity="warning">Não há questões respondidas no período selecionado</Alert>
                                        :
                                        questions?.map((question: any) => {
                                            return <QuestionAnswered
                                                key={question?.exerciseQuestionId || question?.questionId}
                                                question={question}
                                                setCurrentQuestionToAnswer={setCurrentQuestion}
                                                setIsOpenModalAnswerUniqueQuestion={setIsOpenModalAnswerUniqueQuestion}
                                                seeAnswer={isAnsweredQuestions}
                                                backToQuestions={handleBackToQuestions}
                                            />
                                        })

                                }
                                {
                                    (loading || isLoadingAllQuestions) &&
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </Box>

                                }
                            </Box>
                        </Box>
                    </Box>
            }
            {
                modalIsOpen &&
                <StartStudySessionModal
                    selectedTopic={selectedTopic}
                    setSelectedTopic={setSelectedTopic}
                    isAnswerQuestionOpen={isAnswerQuestionOpen}
                    setIsAnswerQuestionOpen={setIsAnswerQuestionOpen}
                    user={user}
                    modalIsOpen={modalIsOpen}
                    handleCloseModal={handleCloseModal}
                    designParams={designParams}
                />

            }

        </>

    );

};


export default StudySession;