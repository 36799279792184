import { Api } from "../api";


const criarUsuario = async (body) => {
  try {
    const { data } = await Api.post('/users', body);

    if (data) {
      return data;
    }

    return new Error('Erro ao criar usuário.');
  } catch (error) {
    console.error(error);
    return error.response.data.message || "Erro ao criar usuário";
  }
};

export const UserService = {
  criarUsuario,
};
