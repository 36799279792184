import React, { useEffect } from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { CreateLearningPathLevelTopicSequenceCommand, LearningPathLevelsViewModel, LearningPathLevelTopicSequenceViewModel, LearningPathService, LearningPathViewModel, SubjectsService } from 'api-gen';
import { useNotification } from 'services/NotificationContext';
import { LoadingButton } from "@mui/lab";
import { ReplaceLearningPathLevelTopicSequence } from "../Commom/FindLearningPathLevel";

interface AddLearningPathContentToLevelModalProps {
    open: boolean;
    onClose: () => void;
    learningPathLevel: LearningPathLevelsViewModel;
    learningPath: LearningPathViewModel;

    allLearningPaths: LearningPathViewModel[];

    setLearningPaths: (learningPath: LearningPathViewModel[]) => void;

    sequences: LearningPathLevelTopicSequenceViewModel[];

    setSequences: (sequences: LearningPathLevelTopicSequenceViewModel[]) => void;
}

const AddLearningPathContentToLevelModal: React.FC<AddLearningPathContentToLevelModalProps> = (props: AddLearningPathContentToLevelModalProps) => {
    const [topics, setTopics] = React.useState<any[]>([]);
    const [selectedTopic, setSelectedTopic] = React.useState<number>(undefined);
    const [isAddingTopic,setIsAddingTopic] = React.useState<boolean>(false);

    const { addNotification }: any = useNotification();

    const getAllTopics = async () => {
        try {
            var response = await SubjectsService.getApiSubjectsTopics(props.learningPath.subjectId, props.learningPath.companyId);
            if (response && response?.topics) {
                const allTopics = response?.topics?.map((topic) => {
                    return {
                        id: topic.id,
                        name: topic.description,
                    };
                });

                setTopics(allTopics);
            }
            return [];
        } catch (e) {
            addNotification("Erro ao carregar conteúdos", "#bb2124");
        }
    };

    useEffect(() => {
        getAllTopics();
        // eslint-disable-next-line
    }, []);

    const handleAddTopic = async () => {
        setIsAddingTopic(true);
        try {
            const body: CreateLearningPathLevelTopicSequenceCommand = {
                learningPathLevelId: props.learningPathLevel.id,
                companyId: props.learningPath.companyId,
                topicId: selectedTopic,
                level: props.learningPathLevel.learningPathLevelTopicSequences?.length + 1
            };


            const result = await LearningPathService.postApiLearningPathAddLearningPathLevelTopicSequence(props.learningPath.companyId, body);
            if (result.statusCode?.toString().startsWith("20")) {
                let allLearningPathsUpdated = props.allLearningPaths;
                const updatedLearningPath = allLearningPathsUpdated.find(lp => lp.id === props.learningPath.id);

                ReplaceLearningPathLevelTopicSequence(updatedLearningPath, props.learningPathLevel.id, result.data);

                const index = allLearningPathsUpdated.findIndex(lp => lp.id === props.learningPath.id);

                allLearningPathsUpdated[index] = updatedLearningPath;

                props.setLearningPaths(allLearningPathsUpdated);

                props.setSequences(result.data);
                addNotification("Conteúdo adicionado com sucesso", "#4BB543");
            } else {
                addNotification(result?.message, "#bb2124");
            }
            props.onClose();
        }
        catch (e) {
            addNotification("Erro ao adicionar conteúdo", "#bb2124");
        }
        setIsAddingTopic(false);
    };



    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth='sm'>
            <DialogTitle>Adicionar Conteúdo</DialogTitle>
            <DialogContent sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
                <FormControl variant='standard' fullWidth >
                    <Autocomplete
                        options={topics}
                        getOptionLabel={(option) => option.name}
                        value={topics.find(topic => topic.id === selectedTopic) || null}
                        onChange={(event, newValue) => {
                            setSelectedTopic(newValue ? newValue.id : null);
                        }}
                        sx={{ marginTop: '1rem' }}
                        noOptionsText="-"
                        renderInput={(params) => <TextField {...params} label="Selecione um conteúdo" />}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancelar</Button>
                <LoadingButton
                    loading={isAddingTopic}
                    onClick={handleAddTopic}
                    disabled={!selectedTopic || selectedTopic === 0}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddLearningPathContentToLevelModal;