import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9999;
`;

export const NotificationItem = styled.div`
  background-color: ${(props) => props.color};
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: #fff;
  margin-left: 10px;
`;

export const NotificationText = styled.p`
  margin: 0;
  font-weight: bold;
  color: white;
`;

export const CloseIcon = styled(FaTimes)`
  color: black;
`;