import React, { useEffect, useState } from "react";
import Modal from "react-modal/lib/components/Modal";
import { Container, Content, Label } from "./CreateExamModalStyles";
import { FaTimes } from "react-icons/fa";
import { KnowledgeAreaViewModel } from "../../../customTypes/KnowledgeAreaViewModel";
import useAuth from "../../../hooks/useAuth";
import { CreateExamCommand } from "../../../customTypes/CreateExamCommand"; 
import { useNotification } from "../../../services/NotificationContext";
import { TextField, Autocomplete } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CompaniesService, ExamsService, StudentsService } from "api-gen";
import { useDesignParams } from "contexts/DesignParamsContext";
const CreateExamModal = ({ isOpen, onClose, setExams }) => {

    const { user }: any = useAuth();
    const designParams = useDesignParams();

    const [knowledgeAreas, setKnowledgeAreas] = useState<KnowledgeAreaViewModel[]>([]);
    const [selectedKnowledgeAreas, setSelectedKnowledgeAreas] = useState<KnowledgeAreaViewModel[]>([]);
    const [error, setError] = useState<string>("");
    const { addNotification } = useNotification();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getKnowledgeAreas = async () => {
        if (user?.companyId) {
            var response = await CompaniesService.getApiCompaniesKnowledgeAreas(user?.companyId);
            if(response?.knowledgeAreas?.length > 0) {
                const knowledges = response?.knowledgeAreas?.filter(area => area.active === true);;
                setKnowledgeAreas(knowledges);
            }
        }
    };
    
    const handleChange = (event, values) => {
        if (values.length > 2) {
            setError("selecione apenas 2 áreas de conhecimento");
            return
        }
        else if (error !== "")
            setError("");

            setSelectedKnowledgeAreas(values);
    };


    useEffect(() => {
        getKnowledgeAreas();
        // eslint-disable-next-line
    }, [user]);

    const handleCloseModal = async () => {
        setError("");
        setSelectedKnowledgeAreas([]);
        onClose();
    };

    const handleCreateExam = async () => {

        if (selectedKnowledgeAreas.length !== 2) {
            setError("selecione 2 areas de conhecimento");
            return;
        }
        setIsLoading(true);
        try {
            if (user?.studentId) {
                const command: CreateExamCommand = {
                    studentId: user?.studentId,
                    knowledgeAreasIds: selectedKnowledgeAreas.map(area => area.id)
                };
                await ExamsService.postApiExams(user?.companyId,command);
                const result = await StudentsService.getApiStudentsExams(user?.studentId, user?.companyId);
                if (result?.exams?.length > 0) {
                    setExams(result?.exams);
                }
                handleCloseModal();
            }
        } catch (e) {
            if (e?.message) {
                addNotification(e.message, "red")
            }
            else
                addNotification("Erro ao criar prova.", "red");
        }
        setIsLoading(false);
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Adicionar Aplicadora"
            appElement={document.getElementById('root')}
            style={{
                content: {
                    width: '20%',
                    height: '35%',
                    margin: 'auto',
                    background: '#fff',
                    border: '2px solid #ccc',
                    borderRadius: '8px',
                    padding: '20px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }
            }}
        >
            <Container>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <div style={{ flex: '1', textAlign: 'center' }}>
                        <Label>Criar Prova</Label>
                    </div>
                    <div>
                        <FaTimes onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
                    </div>
                </div>
                <Content> 

                        <Autocomplete
                            multiple
                            id="knowledgeAreas"
                            options={knowledgeAreas}
                            getOptionLabel={(option) => option.description}
                            defaultValue={[]}
                            value={selectedKnowledgeAreas}
                            sx={{ width: 300 }}
                            onChange={handleChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Selecione duas áreas de conhecimento"
                                    placeholder="áreas de conhecimento"
                                />
                            )}
                        />
                        <div style={{ display: "flex", width: "100%", justifyContent: error ? "space-between" : "flex-end", alignItems: "center",
                                    marginTop: '4rem' }}>
                            {
                                error && <label style={{ color: "red" }}>{error}</label>
                            }
                            <LoadingButton
                                sx={{backgroundColor: designParams?.primaryColorHex || ''}}
                                onClick={handleCreateExam}
                                loading={isLoading}
                                variant="contained"
                                >Criar Prova</LoadingButton>
                        </div> 
                </Content>
            </Container>
        </Modal>
    )
};

export default CreateExamModal;