import React, { useEffect, useState } from 'react';
import { Container, Content, Header, Table, TableContainer, Td, Th, Title } from './styles.js'
import { CompanyService } from "../../services/CompanyService.js";
import AddEmpresaModal from './Components/AddModal';
import { AddButton, DisableButton, EditButton } from '../../styles/styles.js';
import Menu from "../../components/Header"


const EmpresaTable = () => {

    const [empresas, setEmpresas] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [empresaId, setEmpresaId] = useState(null)
    const handleOpenModal = () => {
        setModalIsOpen(true);
    }

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setEmpresaId(null);
        getCompanies();
    }

    const getCompanies = async () => {
        const result = await CompanyService.getAllCompanies();

        if (result) {
            console.log("empresas:", result.companies);
            setEmpresas(result.companies);
        }
    };

    useEffect(() => {
        if (empresas.length === 0) {
            getCompanies();
        }
    }, [empresas])

    const handleEdit = (id) => {
        // Lógica para editar a empresa com o ID fornecido
        setEmpresaId(id);
    };

    useEffect(() => {
        if (empresaId) {
            handleOpenModal();
        }
    }, [empresaId])

    const handleDisable = (id) => {
        // Lógica para desativar a empresa com o ID fornecido
        console.log("Desativar empresa com ID:", id);
    };

    return (
        <>
            <Menu />

            <Container>
                <Content>
                    <Header>
                        <Title>Empresas</Title>
                        <AddButton onClick={handleOpenModal}>Adicionar</AddButton>
                    </Header>
                    <TableContainer>
                        <Table>
                            <thead>
                                <tr>
                                    <Th>Nome Comercial</Th>
                                    <Th>Rua</Th>
                                    <Th>Número</Th>
                                    <Th>Complemento</Th>
                                    <Th>Bairro</Th>
                                    <Th>CEP</Th>
                                    <Th>Cidade</Th>
                                    <Th>Estado</Th>
                                    <Th>Editar</Th>
                                    <Th>Desativar</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {empresas && empresas.length > 0 && empresas.map((empresa) => (
                                    <tr key={empresa.id}>
                                        <Td>{empresa.corporateName}</Td>
                                        <Td>{empresa.address.street}</Td>
                                        <Td>{empresa.address.number}</Td>
                                        <Td>{empresa.address.complement}</Td>
                                        <Td>{empresa.address.district}</Td>
                                        <Td>{empresa.address.zipCode}</Td>
                                        <Td>{empresa.address.city.name}</Td>
                                        <Td>{empresa.address.city.state.name}</Td>
                                        <Td>
                                            <EditButton onClick={() => handleEdit(empresa.id)}>Editar</EditButton>
                                        </Td>
                                        <Td>
                                            <DisableButton onClick={() => handleDisable(empresa.id)}>Desativar</DisableButton>
                                        </Td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </TableContainer>
                </Content>



            </Container>
            <AddEmpresaModal
                isOpen={modalIsOpen}
                onClose={handleCloseModal}
                id={empresaId}
            // Passar os setters como propriedades para o modal
            />
        </>

    );
}

export default EmpresaTable;
