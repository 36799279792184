import React from "react";
import * as C from "./styles";

const Input = ({ type, placeholder, value, onChange, label, disabled,errorLog }) => {


  return (
    <div>
      <C.Label show={label ? true : false}>{label}:</C.Label>
      <C.Input
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
      />
      {
        errorLog != null && 
        errorLog.map((err, index) => (
          <C.ErrorParagraph key={index} show={label ? true : false}>{err}</C.ErrorParagraph>
        ))
      }
    </div>

  );
};

export default Input;
