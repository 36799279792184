import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const Content = styled.div`
    width: 90%;
    max-width: 1200px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 50vh;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const Title = styled.h1`
    margin: 0;
`;



export const TableContainer = styled.div`
    max-height: 22rem;
    overflow-y: auto;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
`;

export const Th = styled.th`
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
`;

export const Td = styled.td`
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
`;
