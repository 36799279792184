/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { Label } from '../../Signin/styles.js';
import { FaTimes } from 'react-icons/fa';
import { Container, Content, LabelError, TabButton, TabContainer, } from './AddModalStyles.js';
import RenderedTexts from '../../../components/RenderHTML/index.js';
import { useNotification } from '../../../services/NotificationContext';
import { verificarParametrosVazios } from '../../../util/utils.js';
import useAuth from '../../../hooks/useAuth.js';
import EditorComponent from '../../../components/EditorJodit/index.js';
import { CompaniesService, CreateQuestionCommand, DegreeAreasService, ExaminationsService, ExpertiseAreasService, JobPositionsService, KnowledgeAreasService, QuestionsService, UpdateQuestionCommand } from 'api-gen';
import { useDesignParams } from 'contexts/DesignParamsContext';
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
Modal.setAppElement('#root');


interface Alternatives {
    id?: number;
    text: string;
};

const AddQuestionModal = ({ isOpen, onClose, id }) => {

    const designParams = useDesignParams();
    const { addNotification } = useNotification();
    const { user } = useAuth();

    const [knowledgeAreaId, setKnowledgeAreaId] = useState(null);
    const [topicsIds, setTopicsIds] = useState<number[]>();
    const [examBoardId, setExamBoardId] = useState(null);
    const [taxonomyId, setTaxonomyId] = useState(null);
    const [error, setError] = useState("");
    const [areasList, setAreasList] = useState([]);
    const [editorStates, setEditorStates] = useState<Alternatives[]>([null]);
    const [checkedIndex, setCheckedIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState('informacoes');
    const [questaoEditor, setQuestaoEditor] = useState('');
    const [justification, setJustification] = useState('');
    const [topicsList, setTopicsList] = useState([]);
    const [examBoardsList, setExamBoardsList] = useState(null);
    const [taxonomyList, setTaxonomyList] = useState(null);
    const [ano, setAno] = useState('');
    const [inepNumber, setInepNumber] = useState<string>(undefined);
    const [examinationId, setExaminationId] = useState<number | null>(null);
    const [jobPositionId, setJobPositionId] = useState<number | null>(null);
    const [degreeAreaId, setDegreeAreaId] = useState<number | null>(null);
    const [expertiseAreaId, setExpertiseAreaId] = useState<number | null>(null);
    const [examinations, setExaminations] = useState([]);
    const [jobPositions, setJobPositions] = useState([]);
    const [degreeAreas, setDegreeAreas] = useState([]);
    const [expertiseAreas, setExpertiseAreas] = useState([]);
 
    const preencherDados = async (dados) => {
        setQuestaoEditor(dados.statement);
        setKnowledgeAreaId(dados.knowledgeArea.id);
        setExamBoardId(dados.examBoard.id);
        setAno(dados.year);
        setInepNumber(dados.inepNumber);
        setTaxonomyId(dados.taxonomy.id);
        setJustification(dados.justification);
        setTopicsIds(dados.topics.map(item => item.id));
        setExaminationId(dados?.examination?.id);
        setJobPositionId(dados?.jobPosition?.id);
        setDegreeAreaId(dados?.degreeArea?.id);
        setExpertiseAreaId(dados.expertiseArea?.id);
        setInepNumber(dados?.code);
        const alternatives: Alternatives[] = dados.alternatives?.map(item => {
            if (item?.isCorrect)
                setCheckedIndex(dados.alternatives.indexOf(item));
            return {
                id: item.id,
                text: item.text
            };
        });

        await getTopics(dados.knowledgeArea.id);
        setEditorStates(alternatives);
    }

    const handleQuestaoChange = (newEditorState) => {
        setQuestaoEditor(newEditorState);
    };

    const handleJustificationChange = (newEditorState) => {
        setJustification(newEditorState);
    }

    const getKnowledgeAreas = async () => {
        if (user?.companyId) {
            const result = await CompaniesService.getApiCompaniesKnowledgeAreas(user.companyId);
            if (result) {
                setAreasList(result?.knowledgeAreas);
            }
        }
    }

    const getQuestions = async () => {
        if (user?.companyId) {
            const result = await QuestionsService.getApiQuestions(id, user?.companyId);
            if (result) {
                await preencherDados(result?.question);
                getKnowledgeAreas();
            }
        }
    }

    const getTaxonomies = async () => {
        try {
            const result = await CompaniesService.getApiCompaniesTaxonomies(user.companyId);
            if (result && result?.taxonomies && result?.taxonomies?.length > 0) {
                setTaxonomyList(result.taxonomies);
            }
        }
        catch (e) {
            console.log("deuerro:", e);
        }
    };

    const getTopics = async (id) => {
        try {
            if (user?.companyId) {
                const result = await KnowledgeAreasService.getApiKnowledgeAreasTopics(id, user?.companyId);
                if (result) {
                    if (result.topics && result.topics.length > 0) {
                        setTopicsList(result.topics);
                    }
                    else {
                        setTopicsList([]);
                    }
                }
            }
        }
        catch (e) {
            console.log("deuerro:", e);
        }
    };

    const getExamBoards = async () => {
        try {
            if (user?.companyId) {
                const result = await CompaniesService.getApiCompaniesExamBoards(user?.companyId);
                if (result && result?.examBoards && result.examBoards?.length > 0) {
                    setExamBoardsList(result.examBoards);

                }
            }
        }
        catch (e) {
            console.log("deuerro:", e);
        }
    };

    const getExaminatios = async () => {
        try {
            if (user?.companyId) {
                const result = await ExaminationsService.getApiExaminations();
                if (result && result?.examinations && result.examinations?.length > 0) {
                    setExaminations(result.examinations);
                }
            }
        }
        catch (e) {
            console.log("deuerro:", e);
        }
    };

    const getJobPositions = async () => {
        try {
            const result = await JobPositionsService.getApiJobPositions();
            if (result && result?.jobPositions && result.jobPositions?.length > 0) {
                setJobPositions(result.jobPositions);
            }
        }
        catch (e) {
            console.log("deuerro:", e);
        }
    };

    const getDegreeAreas = async () => {
        try {
            const result = await DegreeAreasService.getApiDegreeAreas();
            if (result && result?.degreeAreas && result.degreeAreas?.length > 0) {
                setDegreeAreas(result.degreeAreas);
            }
        }
        catch (e) {
            console.log("deuerro:", e);
        }
    };

    const getExpertiseAreas = async () => {
        try {
            const result = await ExpertiseAreasService.getApiExpertiseAreas();
            if (result && result?.expertiseAreas && result.expertiseAreas?.length > 0) {
                setExpertiseAreas(result.expertiseAreas);
            }
        }
        catch (e) {
            console.log("deuerro:", e);
        }
    };

    const handleCloseModal = () => {
        setQuestaoEditor('');
        setKnowledgeAreaId(null);
        setExamBoardId(null);
        setTopicsIds(null);
        setAno('');
        setInepNumber(undefined);
        setTaxonomyId(null);
        setEditorStates([null]);
        setSelectedTab('informacoes');
        setJustification('');
        setError("");
        setTopicsList([]);
        setCheckedIndex(0);
        setExaminationId(null);
        setJobPositionId(null);
        setDegreeAreaId(null);
        setExpertiseAreaId(null);
        onClose();
    }

    const handleChangeKnowledgeArea = async (id) => {
        setKnowledgeAreaId(id)
        await getTopics(id);
    }

    useEffect(() => {
        if (user?.companyId) {
            getKnowledgeAreas();
            getExamBoards();
            getTaxonomies();
            getExaminatios();
            getJobPositions();
            getDegreeAreas();
            getExpertiseAreas();
        }
    }, [user]);



    useEffect(() => {
        if (id) {
            getQuestions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const optionsTopicsList = useMemo(() => {
        if (topicsList) {
            return topicsList.map(item => ({
                value: item.id,
                label: item?.subject?.description ? item?.subject?.description + " - " + item.description : item.description
            }));
        }
        return [];
    }, [topicsList]);

    const optionsTaxonomyList = useMemo(() => {
        if (taxonomyList) {
            return taxonomyList.map(item => ({
                value: item.id.toString(),
                label: item.level
            }));
        }
        return [];
    }, [taxonomyList]);

    const optionsExamBoardsList = useMemo(() => {
        if (examBoardsList) {
            return examBoardsList.map(item => ({
                value: item.id.toString(),
                label: item.name
            }));
        }
        return [];
    }, [examBoardsList]);

    const optionsAreasList = useMemo(() => {
        if (areasList) {
            return areasList.map(item => ({
                value: item.id.toString(),
                label: item.description
            }));
        }
        return [];
    }, [areasList]);

    const groupErrorStrings = (object) => {
        let errors = [];
        for (let key in object) {
            if (Array.isArray(object[key]) && object[key].every(item => typeof item === 'string')) {
                errors.push(object[key].join(","));
            }
        }
        return errors.join(",");
    }


    const handleAddQuestion = async () => {

        var verify = verificarParametrosVazios(
            { name: 'Área do Conhecimento', value: knowledgeAreaId },
            { name: 'Conteúdos', value: topicsIds },
            { name: 'Aplicadora', value: examBoardId },
            { name: 'Taxonomia', value: taxonomyId },
            { name: 'Ano', value: ano },
            { name: 'Questão', value: questaoEditor },
            { name: 'Alternativas', value: editorStates?.map(x => x?.text) },
            { name: 'Justificativa', value: justification },
        );

        if (editorStates.length < 2) {
            if (!verify || verify === "")
                verify = 'Deve ter no mínimo duas alternativas';
            else
                verify += ' e deve ter no mínimo duas alternativas';
        }


        if (!verify) {
            if (id) {
                const bodyEdit: UpdateQuestionCommand = {
                    statement: questaoEditor,
                    justification: justification,
                    year: Number(ano),
                    knowledgeAreaId: parseInt(knowledgeAreaId),
                    examBoardId: parseInt(examBoardId),
                    taxonomyId: parseInt(taxonomyId),
                    topicIds: topicsIds,
                    alternatives: editorStates?.map((item, index) => {
                        return { id: item?.id, text: item.text, isCorrect: index === checkedIndex }
                    }),
                    code: inepNumber,
                    examinationId: examinationId,
                    jobPositionId: jobPositionId,
                    degreeAreaId: degreeAreaId,
                    expertiseAreaId: expertiseAreaId

                }

                try {
                    await QuestionsService.putApiQuestions(id, user?.companyId, bodyEdit);
                    alert('Dados alterados com sucesso!');
                    handleCloseModal();
                } catch (e) {
                    if (e && e?.status === 400) {
                        if (e?.body?.errors) {
                            alert('Erro ao alterar: ' + groupErrorStrings(e?.body?.errors));
                        } else if (e?.body?.message) {
                            const generalError = e?.body?.message;
                            addNotification(generalError, "red");
                        }
                    } else
                        alert('Erro ao alterar questão.');
                }

            } else {

                const body: CreateQuestionCommand = {
                    statement: questaoEditor,
                    justification: justification,
                    year: Number(ano),
                    knowledgeAreaId: parseInt(knowledgeAreaId),
                    examBoardId: parseInt(examBoardId),
                    taxonomyId: parseInt(taxonomyId),
                    topicIds: topicsIds,
                    companyId: user.companyId,
                    alternatives: editorStates?.map((item, index) => {
                        return { text: item.text, isCorrect: index === checkedIndex }
                    }),
                    code: inepNumber,
                    examinationId: examinationId,
                    jobPositionId: jobPositionId,
                    degreeAreaId: degreeAreaId,
                    expertiseAreaId: expertiseAreaId
                }
                try {
                    await QuestionsService.postApiQuestions(user?.companyId, body);
                    addNotification('Questão inserida com sucesso!');
                    handleCloseModal();

                } catch (e) {
                    if (e && e?.status === 400) {
                        if (e?.body?.errors) {
                            alert('Erro ao cadastrar: ' + groupErrorStrings(e?.body?.errors));
                        } else if (e?.body?.message) {
                            const generalError = e?.body?.message;
                            addNotification(generalError, "red");
                        }
                    } else {
                        addNotification('Erro ao cadastrar Questão!', 'red');
                    }

                }
            }
        } else {
            return setError(verify);
        }



    }

    const handleAdvanceStep = () => {
        switch (selectedTab) {
            case 'informacoes':
                if (verifyInformationToAdvanceToQuestionsStep()) {
                    setError("");
                    setSelectedTab('questoes');
                }
                break;
            case 'questoes':
                setSelectedTab('alternativas');
                break;
            case 'alternativas':
                setSelectedTab('respostas');
                break;
            default:
                break;
        }
    }

    const verifyInformationToAdvanceToQuestionsStep = () => {
        var error = "Preencha as seguintes informações:"
        var listValidations = [];

        if (!knowledgeAreaId)
            listValidations.push("área de conhecimento");

        if (!topicsIds || topicsIds?.length === 0)
            listValidations.push("conteúdo");

        if (!taxonomyId)
            listValidations.push("taxonomia");

        if (!ano)
            listValidations.push("ano")

        if (Number(ano) <= 0)
            listValidations.push("o ano deve ser maior que zero.")

        if (listValidations.length > 0) {
            error += listValidations.join(",");
            setError(error);
            return false;
        }

        return true;
    }


    // Função para atualizar o estado de um editor específico
    const addEditor = () => {
        const editorToAdd: Alternatives = {
            text: "",
            id: undefined
        }
        setEditorStates([...editorStates, editorToAdd]);
    };


    const handleEditorStateChange = (index, editorState, id) => {
        const newEditorStates: Alternatives[] = [...editorStates];
        newEditorStates[index] = { id, text: editorState };
        setEditorStates(newEditorStates);
    };

    const handleRadioChange = (index) => {
        setCheckedIndex(index);
    };

    const confirmDeleteEditor = (index) => {
        if (window.confirm('Tem certeza que deseja remover esta alternativa?')) {
            deleteEditor(index);
        }
    };


    const deleteEditor = (index) => {
        const newEditorStates: Alternatives[] = [...editorStates];
        newEditorStates.splice(index, 1);
        setEditorStates(newEditorStates);
    };

    const handleTabClick = (tab) => {
        let canChangeTab = true;
        if (selectedTab === "informacoes") {
            if (verifyInformationToAdvanceToQuestionsStep()) {
                setError("");
            }
            else
                canChangeTab = false;
        }
        if (canChangeTab)
            setSelectedTab(tab);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Adicionar Questões"
            appElement={document.getElementById('root')}
            style={{
                content: {
                    //width: '50%', // Defina o tamanho desejado
                    // height: '35%', // Defina a altura desejada
                    margin: 'auto', // Centralize horizontalmente
                    background: '#fff', // Cor de fundo do modal
                    border: '2px solid #ccc', // Borda do modal
                    borderRadius: '8px', // Bordas arredondadas
                    padding: '20px' // Espaçamento interno
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)' // Cor do fundo ao redor do modal
                }
            }}
        >
            <Container>
                <Label>{id ? "Editar Questão" : "Adicionar Questão"}</Label>
                <FaTimes onClick={handleCloseModal} style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '10px' }} />

                <TabContainer>
                    <TabButton active={selectedTab === 'informacoes'} onClick={() => handleTabClick('informacoes')}>
                        Informações
                    </TabButton>
                    <TabButton active={selectedTab === 'questoes'} onClick={() => handleTabClick('questoes')}>
                        Questão
                    </TabButton>
                    <TabButton active={selectedTab === 'alternativas'} onClick={() => handleTabClick('alternativas')}>
                        Alternativas
                    </TabButton>
                    <TabButton active={selectedTab === 'respostas'} onClick={() => handleTabClick('respostas')}>
                        Respostas
                    </TabButton>
                </TabContainer>

                {selectedTab === 'informacoes' && (
                    <Box sx={{ width: '100%', display: "flex", flexDirection: "column", alignItems: 'center', gap: '1rem' }} >
                        <FormControl variant="standard" sx={{ width: '90%' }}>
                            <InputLabel>Área de conhecimento</InputLabel>
                            <Select
                                id="knowledge-area-select"
                                value={knowledgeAreaId || 0}
                                onChange={(e) => handleChangeKnowledgeArea(e.target.value)}
                                label="Área de conhecimento"
                            >
                                {optionsAreasList?.length > 0 ? (
                                    optionsAreasList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>
                                        -
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl variant='standard' sx={{ width: '90%' }}>
                            <Autocomplete
                                multiple
                                id="contents"
                                options={optionsTopicsList}
                                getOptionLabel={(option) => option.label}
                                defaultValue={[]}
                                value={optionsTopicsList.filter(x => topicsIds?.includes(x.value))}
                                onChange={(event, newValue) => setTopicsIds(newValue.map(option => option.value))}
                                noOptionsText='-'
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Selecione os conteúdos"
                                        placeholder="Conteúdos"
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl variant="standard" sx={{ width: '90%' }}>
                            <InputLabel>Aplicadora</InputLabel>
                            <Select 
                                id="examboard-select"
                                value={examBoardId || 0}
                                onChange={(e) => setExamBoardId(e.target.value)}
                                label="Aplicadora"
                            >
                                {
                                    optionsExamBoardsList?.length > 0 ?
                                        optionsExamBoardsList?.map((option) =>
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ) :
                                        <MenuItem disabled>
                                            -
                                        </MenuItem>
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ width: '90%' }}>
                            <InputLabel>Taxonomia</InputLabel>
                            <Select 
                                id="taxonomy-select"
                                value={taxonomyId || 0}
                                onChange={(e) => setTaxonomyId(e.target.value)}
                                label="Taxonomia"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            height: 200, 
                                        },
                                    },
                                  }}
                            >
                                {
                                    optionsTaxonomyList?.length > 0 ?
                                        optionsTaxonomyList?.map((option) =>
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ) :
                                        <MenuItem disabled>
                                            -
                                        </MenuItem>
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{ width: '90%' }}>
                            <InputLabel>Concurso</InputLabel>
                            <Select
                                labelId="concurso"
                                id="concurso-select"
                                value={examinationId || 0}
                                onChange={(e) => setExaminationId(Number(e.target.value))}
                                label="Concurso"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            height: 200, 
                                        },
                                    },
                                  }}
                            >
                                <MenuItem value={undefined}>
                                    -
                                </MenuItem>
                                {
                                    examinations?.length > 0 &&
                                        examinations?.map((option) =>
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        )  
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{ width: '90%' }}>
                            <InputLabel>Cargo</InputLabel>
                            <Select
                                id="cargo-select"
                                value={jobPositionId || 0}
                                onChange={(e) => setJobPositionId(Number(e.target.value))}
                                label="Cargo"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            height: 200, 
                                        },
                                    },
                                  }}
                            >
                                <MenuItem value={undefined}>
                                    -
                                </MenuItem>
                                {
                                    jobPositions?.length > 0 &&
                                    jobPositions?.map((option) =>
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        )  
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ width: '90%' }}>
                            <InputLabel>Área de Atuação</InputLabel>
                            <Select 
                                id="areaatuacao-select"
                                value={expertiseAreaId || 0}
                                onChange={(e) => setExpertiseAreaId(Number(e.target.value))}
                                label="Área de Atuação"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            height: 200, 
                                        },
                                    },
                                  }}
                            >
                                <MenuItem value={undefined}>
                                    -
                                </MenuItem>
                                {
                                    expertiseAreas?.length > 0 &&
                                    expertiseAreas?.map((option) =>
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        )  
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ width: '90%' }}>
                            <InputLabel>Área de Formação</InputLabel> 
                            <Select 
                                id="areaformacao-select" 
                                value={degreeAreaId || 0}
                                onChange={(e) => setDegreeAreaId(Number(e.target.value))}
                                label="Área de Formação"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            height: 200, 
                                        },
                                    },
                                  }}
                            >
                                <MenuItem value={undefined}>
                                    -
                                </MenuItem>
                                {
                                    degreeAreas?.length > 0 &&
                                    degreeAreas?.map((option) =>
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        )  
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant='standard' sx={{ width: '90%' }}>
                            <TextField
                                id="year"
                                label="Ano"
                                variant="standard"
                                type='number'
                                placeholder='Digite o ano'
                                value={ano}
                                onChange={(e) => [setAno(e.target.value), setError("")]}
                            />
                        </FormControl>
                        <FormControl variant='standard' sx={{ width: '90%' }}>
                            <TextField
                                id="codigo"
                                label="Código da questão"
                                variant="standard"
                                type='text'
                                placeholder='Digite o código da questão'
                                value={inepNumber || ''}
                                onChange={(e) => {
                                    setInepNumber(e.target.value)
                                }}
                            />
                        </FormControl>
                    </Box>
                )}
                {selectedTab === 'questoes' && (
                    <Content>

                        <div style={{ position: 'relative' }}>

                            <EditorComponent setContent={handleQuestaoChange} content={questaoEditor} />

                        </div>
                    </Content>
                )}
                {selectedTab === 'alternativas' && (

                    <Content>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: designParams?.primaryColorHex || '',
                                width: '20%',
                            }}
                            onClick={addEditor} >+ Alternativa</Button>

                        {editorStates.map((editorState, index) => (
                            <div key={index} style={{ position: 'relative' }}>

                                <EditorComponent setContent={(editorState) => handleEditorStateChange(index, editorState, id)} content={editorState?.text} key={index + 1} />
                                <button onClick={() => confirmDeleteEditor(index)} style={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer', background: 'transparent', border: 'none', color: 'red' }}>
                                    <FaTimes />
                                </button>
                            </div>
                        ))}
                    </Content>

                )}
                {selectedTab === 'respostas' && (
                    <Content>
                        {editorStates.map((content, index) => (
                            <RenderedTexts
                                htmlContent={content?.text}
                                isChecked={index === checkedIndex}
                                //onRadioChange={handleRadioChange}
                                setRadio={handleRadioChange}
                                index={index}
                            />
                        ))}
                        <Label>Justificativa</Label>
                        <EditorComponent setContent={handleJustificationChange} content={justification} />
                    </Content>
                )}

                <Content>
                    {error &&
                        <LabelError>{error}</LabelError>
                    }
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', margin: '1rem' }}>
                        {
                            selectedTab === 'respostas' ?
                                <Button
                                    variant='contained'
                                    sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
                                    onClick={handleAddQuestion}
                                >
                                    {id ? "Alterar" : "Adicionar"}
                                </Button>
                                : <Button
                                    variant='contained'
                                    sx={{ backgroundColor: designParams?.primaryColorHex || '' }}
                                    onClick={handleAdvanceStep}
                                >
                                    Avançar
                                </Button>
                        }
                        <Button
                            variant='contained'
                            sx={{ backgroundColor: designParams?.secondaryColorHex || 'red' }}
                            onClick={handleCloseModal}
                        > Cancelar </Button>
                    </div>

                </Content>
            </Container>
        </Modal>
    );
}

export default AddQuestionModal;