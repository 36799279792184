import React, { useRef } from "react"; 
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; 

const PaginationQuestionsExam = ({ totalItems, itemsPerPage, currentPage, setCurrentPage, otherQuestionsIsDisabled }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage); 
    const paginationRef = useRef<HTMLDivElement>(null);

    const handlePageChange = (page) => {
        setCurrentPage(page); 
    };
    const scrollRight = () => {
        if (paginationRef.current) {
            paginationRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    const scrollLeft = () => {
        if (paginationRef.current) {
            paginationRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    return (
        <nav style={{ margin: '1rem', width: "70rem" }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button className="page-link"
                    style={{
                        padding: '5px 10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        backgroundColor: '#fff',
                        color: '#007bff',
                        cursor: 'pointer',
                    }}
                    onClick={scrollLeft} >
                    <FaChevronLeft />
                </button>
                <div
                    ref={paginationRef}
                    style={{
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        margin: '0 10px',
                        WebkitOverflowScrolling: 'touch',
                        scrollbarWidth: 'none'
                    }}
                >
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => ( 
                        <button
                            key={page} 
                            className={`page-link ${currentPage === (page -1) ? 'active' : ''}`}
                            onClick={() => handlePageChange((page -1))}
                            disabled={otherQuestionsIsDisabled}
                            style={{
                                margin: '0 2px',
                                padding: '5px 10px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                backgroundColor: (currentPage === (page -1)) ? '#007bff' : (otherQuestionsIsDisabled ? '#eee' : '#fff'),
                                color: currentPage === (page -1) ? '#fff' : '#A9A9A9',
                                pointerEvents: (currentPage === (page - 1)) ? 'auto' : (!otherQuestionsIsDisabled ? 'auto' : 'none'),
                                cursor: 'pointer',
                            }} 
                        >
                            {page}
                        </button>
                    ))}
                </div>
                <button
                    className="page-link"
                    style={{
                        padding: '5px 10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        backgroundColor: '#fff',
                        color: '#007bff',
                        cursor: 'pointer',
                    }}
                    onClick={scrollRight} disabled={currentPage === totalPages}>
                    <FaChevronRight />
                </button>
            </div>
        </nav>
    );
};

export default PaginationQuestionsExam;